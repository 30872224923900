/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-loop-func */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import './MasteryAssessment.css';
import {
   Button, Input, Form, Select, Tag, Typography, Row, Col, Descriptions, Space,
   Modal, Spin, Tooltip, Breadcrumb
} from 'antd';
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { EyeOutlined, ExclamationCircleFilled, PlayCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';
import axios from 'axios';
import platform from 'platform';
// import { MasteryTerms, MasteryCompetency, MasteryGradeLevels, MasterySubjects } from '../../constants'
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import moment from 'moment';
import ConfettiExplosion from 'react-confetti-explosion';
import ViewInstructions from './Components/ViewInstructions';
import ViewActivities from './Components/ViewActivities';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import { set_mastery_assignment, set_mastery } from '../../actions';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';

var g_modal_title = "";
var g_modal_study_plan = "";
var g_mastery_result = [];
var g_join_url = "";

let g_recommendation_data = {
   rec_id: null,
   rec_desc: null,
   rec_docs: null,
   rec_doc_names: null,
   rec_doc_status: null,
}

let g_instruction_data = {
   instruction_id: null,
   instruction_desc: null,
   instruction_docs: null,
   instruction_doc_names: null,
};

let g_activity_data = {
   act_docs: null,
   act_doc_names: null,
   act_remarks: null,
   act_study_plan_type: null,
}

var assign_info = null;
let bg_image = null;

export function MasteryStudentPreview() {
   const navigate = useNavigate();
   const dispatch = useDispatch();

   const [form] = Form.useForm();

   const { Paragraph } = Typography;
   const { Option } = Select;
   const { TextArea } = Input;

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const origin = query.get('origin');
   const assign_id = query.get('assign_id');
   const auto_proceed = query.get('auto_proceed') === undefined ? false : query.get('auto_proceed');
   const qt = query.get('qt');

   const student_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const student_name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const masteryrec = useSelector(state => state.masteryrec);

   const [modalVisible, setModalVisible] = useState(false);
   const [masteryResult, setMasteryResult] = useState([]);
   const [viewInstructionModalVisible, setViewInstructionModalVisible] = useState(false);
   const [viewStudyPlanActivityModalVisible, setViewStudyPlanActivityModalVisible] = useState(false);
   const [showConfetti, setShowConfetti] = useState(false);
   const [reloadDetails, setReloadDetails] = useState(false);

   const [MasteryTerms, setMasteryTerms] = useState([]);
   const [MasteryCompetency, setMasteryCompetency] = useState([]);
   const [MasteryGradeLevels, setMasteryGradeLevels] = useState([]);
   const [MasterySubjects, setMasterySubjects] = useState([]);

   const subdomain = localStorage.getItem("lms-subdomain");

   assign_info = useSelector(state => state.masteryassignment);

   useEffect(() => {
      Utils.verifyJwt();

      // if (masteryrec.id === null)

      getMasteryLookUps();

      // else 
      //    getSubjectList(masteryrec.level)
   }, []);

   const getMasteryLookUps = () => {
      var _masteryTerms = [];
      var _masteryCompetency = [];
      var _masteryGradeLevels = [];
      var _masterySubjects = [];

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               _masteryTerms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     _masterySubjects.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           _masteryGradeLevels.push(obj);
                        });

                        axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              data.map((item) => {
                                 let obj = { 'value': item.value, 'name': item.name }
                                 _masteryCompetency.push(obj);
                              });
                           }

                           setMasteryTerms(_masteryTerms);
                           setMasteryCompetency(_masteryCompetency);
                           setMasteryGradeLevels(_masteryGradeLevels);
                           setMasterySubjects(_masterySubjects);

                           if (assign_info.mastery_assessment_id === null)
                              getAssignInfo(assign_id, _masterySubjects, _masteryGradeLevels);
                           else
                              getMasteryInfo(assign_info.mastery_assessment_id, _masterySubjects, _masteryGradeLevels);

                        }, (error) => {
                           console.log(error.message);
                        });
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getMasteryInfo = (id, subjectlist, levellist) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;

            var sn = subjectlist.find(x => x.value === data.subject);
            var ln = levellist.find(x => x.value === data.level);

            var mastery_data = {
               id: data.id,
               title: data.title,
               term: data.term,
               level: data.level,
               subject: data.subject,
               formative_1: data.formative_1 !== null ? prepareAnswerSheet(data.formative_1) : null,
               formative_1_file_url: data.formative_1_file_url,
               formative_2: data.formative_2 !== null ? prepareAnswerSheet(data.formative_2) : null,
               formative_2_file_url: data.formative_2_file_url,
               formative_3: data.formative_3 !== null ? prepareAnswerSheet(data.formative_3) : null,
               formative_3_file_url: data.formative_3_file_url,
               summative: data.summative !== null ? prepareAnswerSheet(data.summative) : null,
               summative_file_url: data.summative_file_url,
               study_plan_1: data.study_plan_1,
               study_plan_2: data.study_plan_2,
               mastery_level: data.mastery_level,
               mastery_competency: data.competency,
               created_by: data.created_by,
               mode: qt,
               subjectname: sn.name,
               levelname: ln.name,
               formative_1_instruction: data.formative_1_instruction,
               formative_2_instruction: data.formative_2_instruction,
               formative_3_instruction: data.formative_3_instruction,
            }
            g_join_url = data.join_url;

            dispatch(set_mastery(mastery_data));
            getMasteryResult(assign_info.mastery_assessment_id, assign_info.id, student_id);

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAssignInfo = (assign_id, subjectlist, levellist) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assigninfo?id=${assign_id}`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            assign_info = data;
            dispatch(set_mastery_assignment(data));
            getMasteryInfo(data.mastery_assessment_id, subjectlist, levellist);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getMasteryResult = (mastery_id, assign_id, student_id) => {
      setReloadDetails(true);

      axios.get(`${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/masteryresult?assessment_id=${mastery_id}&assignment_id=${assign_id}&student_id=${student_id}`).then((response) => {
         if (response.data) {
            const mastery_result_data = response.data;

            g_mastery_result = mastery_result_data;
            setMasteryResult(mastery_result_data);

            g_instruction_data = {
               instruction_id: mastery_result_data[0].instruction_id,
               instruction_desc: mastery_result_data[0].instruction_desc,
               instruction_docs: mastery_result_data[0].instruction_docs !== null ? mastery_result_data[0].instruction_docs.split(',') : null,
               instruction_doc_names: mastery_result_data[0].instruction_doc_names !== null ? mastery_result_data[0].instruction_doc_names.split(',') : null,
            };

            if (auto_proceed || (mastery_result_data[2].submit_status === 1 && Utils.GetProficiency(mastery_result_data[2].percentage) === "COMPETENT"))
               handleStartMastery(mastery_result_data);

            if (mastery_result_data[0].mastery_answer_id === null && mastery_result_data[3].mastery_answer_id === null)
               handleViewInstruction();

            setReloadDetails(false);
         }
      }, (error) => {
         setReloadDetails(false);
         console.log(error.message);
      });
   }

   function prepareAnswerSheet(questionSheet) {
      var qs = JSON.parse(questionSheet);

      for (let i = 0; i < qs.length; i++) {
         if (qs[i].type !== 'section') {
            delete qs[i].correct;
         }
      }

      return qs;
   }

   function RenderMessageContent(message) {
      var retVal = (
         <>
            <Row>
               <Col span={16} style={{ textAlign: "left" }}>
                  {message}
               </Col>
               <Col span={8} style={{ paddingLeft: "10px" }}>
                  <img src="../images/brainee_mascot.png" width="100%" style={{ paddingBottom: "30px" }}></img>
               </Col>
            </Row>
         </>
      );

      return retVal;
   }

   const handleStartMastery = (mastery_result) => {
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

      let timestampz = new Date().getTime();

      var ansInfo = {
         // Id: "ans_" + o2o_mode + "_" + uuid(),
         Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + student_id,
         Mastery_assessment_id: masteryrec.id,
         Mastery_assignment_id: null,
         Student_id: student_id,
         User_agent: platform.ua,
         Browser: platform.name,
         Browser_version: platform.version,
         OS_platform: platform.os.family,
         Device: platform.product,
         Assessment_type: null,
         Status: -1,
         Submit_status: 0,
         Allow_to_proceed: 0,
      }

      var assInfo = {
         Id: masteryrec.id,
         Title: masteryrec.title,
         Term: masteryrec.term,
         File_url: null,
         Created_by: masteryrec.created_by,
         Question_sheet: null,
      };

      //-- Check what assessment to answer
      if (mastery_result.length > 0 && mastery_result !== null) {

         //-- Check if formative or summative
         var result_idx = -1;
         var result_length = 0;
         if (masteryrec.summative !== null) {
            result_idx = 3;
            result_length = mastery_result.length - 2;
         }
         else {
            result_idx = 0;
            result_length = mastery_result.length - 3;
         }

         var message_content = null;

         for (var i = result_idx; i < result_length; i++) {

            if (mastery_result[i].submit_status === 0) {

               //-- Check if essay is already evaluated
               if (i > 0 && mastery_result[i - 1].essay_score === 0 && mastery_result[i - 1].essay_score_multi === "[]" && mastery_result[i - 1].essay_total_points > 0) {
                  message_content = RenderMessageContent("You have completed " + Utils.GetTypeLabel(mastery_result[i - 1].assessment_type) + ". Open-Ended/Essays will be evaluated by the teacher before you can proceed to the next level.");

                  Modal.confirm({
                     title: 'Mastery Assessment',
                     icon: <ExclamationCircleFilled />,
                     content: message_content,
                     centered: true,
                     okButtonProps: { shape: "round" },
                     cancelButtonProps: { style: { display: 'none' } },
                     okText: 'Ok',
                     cancelText: 'Cancel',
                     onOk() {
                        console.log('Ok');
                     },
                     onCancel() {
                        console.log('Cancel');
                     }
                  });

                  break;
               }

               ansInfo.Mastery_assignment_id = mastery_result[i].mastery_assignment_id;

               //-- Check if the student will take the study plan based on the score 
               if (masteryrec.summative === null && i > 0 && mastery_result[i].mastery_answer_id === null &&
                  mastery_result[i - 1].percentage <= 59.49 && mastery_result[i - 1].status !== 2) {

                  message_content = RenderMessageContent(<><Paragraph>
                     You got <b>{mastery_result[i - 1].score} out of {mastery_result[i - 1].total_points}</b> item(s) in {Utils.GetTypeLabel(mastery_result[i - 1].assessment_type)}.
                  </Paragraph>
                     <Paragraph>
                        You are in the <b>{Utils.GetProficiency(mastery_result[i - 1].percentage)}</b> Level.
                     </Paragraph>
                     <Paragraph>
                        To see your answers, click View Result. To proceed to the Study Plan, click Next.
                     </Paragraph></>);

                  var sp_index = 0;

                  if (mastery_result[i - 1].assessment_type === 'formative_1') {
                     ansInfo.Assessment_type = 'study_plan_1';
                     sp_index = 4;
                  }
                  else if (mastery_result[i - 1].assessment_type === 'formative_2') {
                     ansInfo.Assessment_type = 'study_plan_2';
                     sp_index = 5;
                  }

                  Modal.confirm({
                     title: 'Mastery Assessment',
                     icon: <ExclamationCircleFilled />,
                     content: message_content,
                     centered: true,
                     okButtonProps: { shape: "round" },
                     cancelButtonProps: { shape: "round" },
                     okText: 'Next',

                     cancelText: 'View Result',
                     // eslint-disable-next-line no-loop-func
                     onOk() {
                        handleAddStudyPlan(ansInfo, assign_info, ansInfo.Assessment_type, sp_index);
                     },
                     // eslint-disable-next-line no-loop-func
                     onCancel() {
                        console.log('Cancel');
                        handleViewAnswer(student_name, student_id, mastery_result[i - 1].assessment_type);
                     }
                  });

                  break;
               }

               ansInfo.Assessment_type = mastery_result[i].assessment_type;
               assInfo.File_url = eval("masteryrec." + mastery_result[i].assessment_type + "_file_url");
               assInfo.Question_sheet = eval("masteryrec." + mastery_result[i].assessment_type);

               //-- Check if student is exempted from taking formative level 2 (proficient)
               if (i === 1 && mastery_result[i].mastery_answer_id === null) {
                  if (mastery_result[i - 1].percentage >= 79.5) {
                     ansInfo.Submit_status = 1;
                     ansInfo.Status = 2; //-- This means that the student is exempted from taking this assessment

                     handleAddExemption(ansInfo);

                     mastery_result[i].mastery_answer_id = ansInfo.Id;
                     mastery_result[i].submit_status = 1;
                     mastery_result[i].status = 2;

                     ansInfo.Id = "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + student_id;
                     ansInfo.Submit_status = 0;
                     ansInfo.Status = -1;

                     continue;
                  }
               }

               //-- Otherwise continue with the normal routine
               message_content = RenderMessageContent('You will now proceed in answering ' + Utils.GetTypeLabel(mastery_result[i].assessment_type) + '. Click Ok to continue.');

               if (mastery_result[i].mastery_answer_id !== null)
                  message_content = RenderMessageContent('You will now continue answering ' + Utils.GetTypeLabel(mastery_result[i].assessment_type) + '. Click next to proceed.');

               if (masteryrec.summative === null && i > 0) {
                  if (mastery_result[i - 1].status !== 2)
                     message_content = RenderMessageContent(<>
                        <Paragraph>
                           You got <b>{mastery_result[i - 1].score} out of {mastery_result[i - 1].total_points}</b> item(s) in {Utils.GetTypeLabel(mastery_result[i - 1].assessment_type)}.
                        </Paragraph>
                        <Paragraph>
                           You are in the <b>{Utils.GetProficiency(mastery_result[i - 1].percentage)}</b> Level.
                        </Paragraph>
                        <Paragraph>
                           To see your answers, click View Result. To proceed to the next Formative Assessment, click Next.
                        </Paragraph></>);

                  else if (mastery_result[i - 1].status === 2 && mastery_result[i].mastery_answer_id === null)
                     message_content = RenderMessageContent(<>
                        <Paragraph>
                           You got <b>{mastery_result[i - 2].score} out of {mastery_result[i - 2].total_points}</b> item(s) in {Utils.GetTypeLabel(mastery_result[i - 2].assessment_type)}.
                        </Paragraph>
                        <Paragraph>
                           You are in the <b>{Utils.GetProficiency(mastery_result[i - 2].percentage)}</b> Level and got EXEMPTED from taking {Utils.GetTypeLabel(mastery_result[i - 1].assessment_type)}.
                        </Paragraph>
                        <Paragraph>
                           To see your answers, click View Result. To proceed to the next Formative Assessment, click Next.
                        </Paragraph></>);

                  Modal.confirm({
                     title: 'Mastery Assessment',
                     icon: <ExclamationCircleFilled />,
                     content: message_content,
                     okButtonProps: { shape: "round" },
                     cancelButtonProps: { shape: "round" },
                     okText: 'Next',
                     cancelText: 'View Result',
                     centered: true,
                     onOk() {
                        handleAddAnswer(ansInfo, assInfo, mastery_result[i].assessment_type);
                     },
                     onCancel() {
                        console.log('Cancel');
                        if (mastery_result[i - 1].status === 2) //-- Exempted in formative 2
                           handleViewAnswer(student_name, student_id, mastery_result[i - 2].assessment_type); //-- View formative 1 result
                        else
                           handleViewAnswer(student_name, student_id, mastery_result[i - 1].assessment_type); //-- View previous formative
                     },
                  });

                  break;
               }

               Modal.confirm({
                  title: 'Mastery Assessment',
                  icon: <ExclamationCircleFilled />,
                  content: message_content,
                  okButtonProps: { shape: "round" },
                  cancelButtonProps: { shape: "round" },
                  okText: 'Ok',
                  cancelText: "I'm not ready",
                  centered: true,
                  onOk() {
                     handleAddAnswer(ansInfo, assInfo, mastery_result[i].assessment_type);
                  },
                  onCancel() {
                     console.log('Cancel');
                     //   handleViewAnswer(student_name, student_id, mastery_result[i-1].assessment_type);
                  },
               });

               break;
            }
         }

         //-- Greet if mastery is completed
         if (mastery_result[3].submit_status === 1) { //-- Summative
            if (mastery_result[3].essay_score === 0 && mastery_result[3].essay_score_multi === "[]" && mastery_result[3].essay_total_points > 0) {
               message_content = RenderMessageContent("You have completed " + Utils.GetTypeLabel(mastery_result[3].assessment_type) + ". Open-ended/Essay will be evaluated by the teacher before you can proceed to the next level.");

               Modal.confirm({
                  title: 'Mastery Assessment',
                  icon: <ExclamationCircleFilled />,
                  content: message_content,
                  centered: true,
                  okButtonProps: { shape: "round" },
                  cancelButtonProps: { style: { display: 'none' } },
                  okText: 'Ok',
                  cancelText: 'Cancel',
                  onOk() {
                     console.log('Cancel');
                  },
                  onCancel() {
                     console.log('Cancel');
                  }
               });
            } else {
               // var message_content = RenderMessageContent("You got " + mastery_result[3].percentage + "% correct answers from " + GetTypeLabel(mastery_result[3].assessment_type) + ". You have completed the Summative Mastery Assesment!");
               message_content = RenderMessageContent(<>
                  <Paragraph>
                     You got <b>{mastery_result[3].score} out of {mastery_result[3].total_points}</b> item(s) in {Utils.GetTypeLabel(mastery_result[3].assessment_type)}.
                  </Paragraph>
                  {/* <Paragraph>
                     You are in the <b>{Utils.GetProficiency(mastery_result[i - 1].percentage)}</b> Level.
                  </Paragraph> */}
                  <Paragraph>
                     You have completed the Mastery Summative Assesment.
                  </Paragraph></>);

               Modal.info({
                  title: 'Mastery Assessment',
                  content: message_content,
                  okButtonProps: { shape: "round" },
                  centered: true,
               });
            }
         } else if (mastery_result[0].submit_status === 1 && mastery_result[1].submit_status === 1 && mastery_result[2].submit_status === 1) { //-- Formative Essay/Open-ended

            if (mastery_result[2].essay_score === 0 && mastery_result[2].essay_score_multi === "[]" && mastery_result[2].essay_total_points > 0) {
               message_content = RenderMessageContent("You have completed " + Utils.GetTypeLabel(mastery_result[2].assessment_type) + ". Open-ended/Essay will be evaluated by the teacher before you can see the final result.");

               Modal.confirm({
                  title: 'Mastery Assessment',
                  icon: <ExclamationCircleFilled />,
                  content: message_content,
                  centered: true,
                  okButtonProps: { shape: "round" },
                  cancelButtonProps: { style: { display: 'none' } },
                  okText: 'Ok',
                  cancelText: 'Cancel',
                  onOk() {
                     // navigate('/masteryassessment/study-plan', {state: {study_plan: 'study_plan_1', assign_info: assign_info}});
                     console.log('Cancel');
                  },
                  onCancel() {
                     console.log('Cancel');
                  }
               });
            } else { //-- Formative
               // var message_content = RenderMessageContent("You got " + mastery_result[2].percentage + "% correct answers from " + GetTypeLabel(mastery_result[2].assessment_type) + ". You have completed the Formative Mastery Assesment!");

               var proficiency = Utils.GetProficiency(mastery_result[i - 1].percentage);

               message_content = RenderMessageContent(<>
                  <Paragraph>
                     Nice try!
                  </Paragraph>
                  <Paragraph>
                     You have completed the Mastery Assessment.
                  </Paragraph>
                  <Paragraph>
                     To see your answers, click View Result. To proceed to the teacher's recommendation, click Next.
                  </Paragraph></>);

               if (proficiency === "COMPETENT") {
                  message_content = RenderMessageContent(<>
                     <Paragraph>
                        Congratulations!
                     </Paragraph>
                     <Paragraph>
                        You have successfully completed the Mastery Assessment.
                     </Paragraph>
                     <Paragraph>
                        Wait for the upcoming lesson or assessment to be assigned.
                     </Paragraph></>);

                  setShowConfetti(true);

                  Modal.info({
                     title: 'Mastery Assessment',
                     content: message_content,
                     okButtonProps: { shape: "round" },
                     centered: true,
                     onOk() { setShowConfetti(false); }
                  });
               } else {
                  Modal.confirm({
                     title: 'Mastery Assessment',
                     icon: <ExclamationCircleFilled />,
                     content: message_content,
                     okButtonProps: { shape: "round" },
                     cancelButtonProps: { shape: "round" },
                     okText: 'Next',
                     cancelText: 'View Result',
                     centered: true,
                     onOk() {
                        handleViewRecommendation();
                     },
                     onCancel() {
                        console.log('Cancel');
                        handleViewAnswer(student_name, student_id, mastery_result[i - 1].assessment_type);
                     }
                  });
               }
            }
         }
      }
      // }
   }

   const handleAddAnswer = (answer_info, assessment_info, assessment_type) => {

      //-- Create numeric sequece

      const addAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addanswer`;
      axios.post(addAnsUrl, answer_info).then(response => {
         if (response.data) {
            let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
            let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

            //-- Get card background based on subject and level
            // bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
            let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), assessment_type);

            if (qt === '1')
               navigate('/masteryassessment/answer-pdf', { state: { id: answer_info.Id, assInfo: assessment_info, assignInfo: assign_info, type: assessment_type, origin, card_bg: bg_image, subject: subject.name, level: level.name } });

            if (qt === '2')
               navigate('/masteryassessment/answer-manual', { state: { id: answer_info.Id, assInfo: assessment_info, assignInfo: assign_info, type: assessment_type, origin, card_bg: bg_image, subject: subject.name, level: level.name } });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Attempt to answer mastery assessment failed.',
            centered: true
         });
      });
   }

   const handleAddExemption = (answer_info) => {
      const addAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addanswer`;
      axios.post(addAnsUrl, answer_info).then(response => {
         if (response.data) {
            // Do nothing
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Attempt to answer mastery assessment failed.',
            centered: true
         });
      });
   }

   const handleAddStudyPlan = (answer_info, assign_info, study_plan, result_idx) => {
      var allow_to_proceed = g_mastery_result[result_idx].allow_to_proceed;
      var status = g_mastery_result[result_idx].study_plan_status;
      answer_info.Allow_to_proceed = allow_to_proceed;

      if (status !== 0)
         answer_info.Status = status;

      const addAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addanswer`;

      if (status === 0) {
         axios.post(addAnsUrl, answer_info).then(response => {
            if (response.data) {
               navigate('/masteryassessment/study-plan', { state: { answer_id: answer_info.Id, study_plan, assign_info, allow_to_proceed, origin, join_url: g_join_url, status, card_bg: bg_image, qt } });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Attempt to view Study Plan failed.',
               centered: true
            });
         });
      } else {
         navigate('/masteryassessment/study-plan', { state: { answer_id: answer_info.Id, study_plan, assign_info, allow_to_proceed, origin, join_url: g_join_url, status, card_bg: bg_image, qt } });
      }

   }

   const handleViewAnswer = (student_name, student_id, assessment_type) => {
      var assessment_id = assign_info.mastery_assessment_id; //mastery_id === null ? assign_info.mastery_assessment_id : mastery_id;

      // let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
      // let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

      //-- Get card background based on subject and level
      // bg_image = Utils.GetCardBackground(masteryrec.subjectname.toLowerCase(), masteryrec.levelname.toLowerCase());
      let bg_image = Utils.GetCardBackground(masteryrec.subjectname.toLowerCase(), assessment_type);

      if (qt === '1')
         navigate('/masteryassessment/student-view-ans-pdf', { state: { student_name, assessment_id, assignInfo: assign_info, student_id, assessment_type, activetab: origin, card_bg: bg_image, subject: masteryrec.subjectname, level: masteryrec.levelname } });

      if (qt === '2')
         navigate('/masteryassessment/student-view-ans-manual', { state: { student_name, assessment_id, assignInfo: assign_info, student_id, assessment_type, origin, card_bg: bg_image, subject: masteryrec.subjectname, level: masteryrec.levelname } });


   }

   const handleViewRecommendation = () => {
      if (g_mastery_result[0].class_recommendation_id !== null && g_mastery_result[0].class_recommendation_id !== '')
         g_recommendation_data = {
            rec_id: g_mastery_result[0].class_recommendation_id,
            rec_desc: g_mastery_result[0].class_recommendation_desc,
            rec_docs: g_mastery_result[0].class_recommendation_docs !== null ? g_mastery_result[0].class_recommendation_docs.split(',') : null,
            rec_doc_names: g_mastery_result[0].class_recommendation_doc_names !== null ? g_mastery_result[0].class_recommendation_doc_names.split(',') : null,
            rec_doc_status: g_mastery_result[0].class_recommendation_status,
         }
      else
         g_recommendation_data = {
            rec_id: g_mastery_result[0].recommendation_id,
            rec_desc: g_mastery_result[0].recommendation_desc,
            rec_docs: g_mastery_result[0].recommendation_docs !== null ? g_mastery_result[0].recommendation_docs.split(',') : null,
            rec_doc_names: g_mastery_result[0].recommendation_doc_names !== null ? g_mastery_result[0].recommendation_doc_names.split(',') : null,
            rec_doc_status: g_mastery_result[0].recommendation_status,
         }

      //-- Do this for individual recommendation only
      if (g_mastery_result[0].recommendation_id !== null && g_mastery_result[0].recommendation_id !== '') {
         if (g_recommendation_data.rec_id !== null) {
            //-- Set recommendation status to viewing
            var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/setrecommendationstatus?student_id=${student_id}&assignment_id=${assign_info.id}&status=1`;

            if (g_mastery_result[0].recommendation_status === 0) {
               axios.post(url).then(response => {
                  if (response.data) {
                     g_recommendation_data.rec_doc_status = 1;
                     navigate('/masteryassessment/view-recommendation', { state: { recommendation_data: g_recommendation_data, assign_id: assign_info.id, mastery_id: assign_info.mastery_assessment_id, assignInfo: assign_info, activetab: origin, join_url: g_join_url, student_id, qt } });
                  }
               }, (error) => {
                  console.log(error.message);
                  Modal.error({
                     title: 'Mastery Assessment',
                     content: 'Attempt to view Recommendation failed.',
                     centered: true
                  });
               });
            } else {
               navigate('/masteryassessment/view-recommendation', { state: { recommendation_data: g_recommendation_data, assign_id: assign_info.id, mastery_id: assign_info.mastery_assessment_id, assignInfo: assign_info, activetab: origin, join_url: g_join_url, student_id, qt } });
            }

         } else {
            Modal.error({
               title: 'Mastery Assessment',
               content: 'No recommendation available for this competency!',
               centered: true
            });
         }
      } else {
         navigate('/masteryassessment/view-recommendation', { state: { recommendation_data: g_recommendation_data, assign_id: assign_info.id, mastery_id: assign_info.mastery_assessment_id, assignInfo: assign_info, activetab: origin, join_url: g_join_url, student_id, qt } });
      }
   }

   const handleViewStudyPlanActivity = (data, study_plan_type) => {
      g_activity_data = {
         act_docs: data.study_plan_activity_docs.split(','),
         act_doc_names: data.study_plan_activity_doc_names.split(','),
         act_remarks: data.study_plan_activity_remarks,
         act_study_plan_type: study_plan_type
      }

      setViewStudyPlanActivityModalVisible(true);
   }

   const handleCloseViewStudyPlanActivity = () => {
      setViewStudyPlanActivityModalVisible(false);
   }

   // const Congratulations = () => {
   //    if (masteryrec.id !== null && masteryResult.length > 0 && masteryResult[2].submit_status === 1 && Utils.GetProficiency(masteryResult[2].percentage) === "COMPETENT") {
   //       var message_content = RenderMessageContent(<>
   //          <Paragraph>
   //             Congratulations!
   //          </Paragraph>
   //          <Paragraph>
   //             You have successfully completed the Mastery Assessment.
   //          </Paragraph>
   //          <Paragraph>
   //             Wait for the upcoming lesson or assessment to be assigned.
   //          </Paragraph></>);

   //       Modal.info({
   //          title: 'Mastery Assessment',
   //          icon: <ExclamationCircleFilled />,
   //          content: message_content,
   //          okButtonProps: { shape: "round" },
   //          centered: true,
   //       });
   //    }
   //    // setShowConfetti(true);
   // }

   const handleViewInstruction = () => {
      setViewInstructionModalVisible(true);
   }

   // const handleViewInstructionDoc = (document) => {
   //    setCurrentInstructionDoc(document);
   // }

   const handleCloseViewInstruction = () => {
      setViewInstructionModalVisible(false);
   }

   const handleReload = () => {
      getMasteryResult(assign_info.mastery_assessment_id, assign_info.id, student_id);
   }

   // const handleViewStudyPlan = (answer_info, assign_info, study_plan, result_idx) => {
   //    var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

   //    let timestampz = new Date().getTime();

   //    var ansInfo = {
   //       // Id: "ans_" + o2o_mode + "_" + uuid(),
   //       Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + student_id,
   //       Mastery_assessment_id: masteryrec.id,
   //       Mastery_assignment_id: null,
   //       Student_id: student_id,
   //       User_agent: platform.ua,
   //       Browser: platform.name,
   //       Browser_version: platform.version,
   //       OS_platform: platform.os.family,
   //       Device: platform.product,
   //       Assessment_type: null,
   //       Status: -1,
   //       Submit_status: 0,
   //       Allow_to_proceed: 0,
   //    }

   //    var assInfo = {
   //       Id: masteryrec.id,
   //       Title: masteryrec.title,
   //       Term: masteryrec.term,
   //       File_url: null,
   //       Created_by: masteryrec.created_by,
   //       Question_sheet: null,
   //    };

   //    var allow_to_proceed = g_mastery_result[result_idx].allow_to_proceed;
   //    var status = g_mastery_result[result_idx].study_plan_status;
   //    answer_info.Allow_to_proceed = allow_to_proceed;

   //    navigate('/masteryassessment/study-plan', { state: { answer_id: answer_info.Id, study_plan, assign_info, allow_to_proceed, origin, join_url: g_join_url, status } });
   // }

   const handlePreviewStudyPlan = (title, type) => {
      g_modal_title = title;

      switch (type) {
         case "study_plan_1":
            g_modal_study_plan = masteryrec.study_plan_1.includes('base64') ? masteryrec.study_plan_1 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_1}`;
            break;
         case "study_plan_2":
            g_modal_study_plan = masteryrec.study_plan_2.includes('base64') ? masteryrec.study_plan_2 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_2}`;
            break;
         default:
            g_modal_study_plan = "";
      }

      setModalVisible(true);
   }

   const littleExplodeProps = {
      force: 0.4,
      duration: 5000,
      particleCount: 200,
      height: 1000,
      width: 1000
   };

   const source = {
      position: 'absolute',
      right: '50%',
      left: '50%',
      bottom: '100vh',
      // bottom: 150,
   };

   const container = {
      position: 'absolute',
      overflow: 'hidden',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
   };

   // const handleEndConfetti = () => {
   //    setTimeout(() => {
   //       setShowConfetti(false);
   //    }, 3000);
   // }

   return (
      <>
         {masteryrec.id !== null && masteryResult.length > 0
            ?
            <>
               {/* {Congratulations()} */}
               {showConfetti && (
                  <>
                     <div style={container}>
                        <div style={source}>
                           <ConfettiExplosion {...littleExplodeProps} />
                        </div>
                     </div>
                  </>
               )}
               <NoticeBoardSlide />
               <div className="dashboard-area-all">
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a className="active">Start</a></li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Start</Breadcrumb.Item>
                        </Breadcrumb>

                        <div className='mastery_details_div'>
                           <Form
                              form={form}
                              layout="vertical"
                              className='DetailsForm'
                              colon={false}
                              autoComplete="off"
                           >
                              <Row gutter={12}>
                                 <Col xs={24} xl={18}>
                                    <Form.Item label="Competency Title" name="mastery_title" initialValue={masteryrec.title}>
                                       <TextArea
                                          allowClear
                                          rows={5}
                                          style={{ color: '#000000', borderRadius: 7 }}
                                          disabled
                                       />
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} xl={6}>
                                    <Row gutter={12}>
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Competency Code" name="competency" initialValue={masteryrec.mastery_competency}>
                                             <Select
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Competency"
                                                disabled
                                                value={masteryrec.mastery_competency}
                                             >
                                                {MasteryCompetency.map(({ value, name }, idx) => {
                                                   return <Option value={value}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                       <Col xs={24} xl={24}>
                                          <Form.Item label="Term" name="term" initialValue={masteryrec.term}>
                                             <Select
                                                disabled
                                                showArrow
                                                size='default'
                                                style={{ width: '100%', textAlign: "left" }}
                                                allowClear
                                                placeholder="Select Term"
                                                value={masteryrec.term}
                                             >
                                                {MasteryTerms.map(({ value, name }, idx) => {
                                                   return <Option value={value}>{name}</Option>
                                                })}
                                             </Select>
                                          </Form.Item>
                                       </Col>
                                    </Row>

                                 </Col>
                              </Row>

                              <Row gutter={12}>
                                 <Col xs={24} xl={10}>
                                    <Form.Item label="Grade Level" name="gradelevel" initialValue={masteryrec.level}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Level"
                                          value={masteryrec.level}
                                       >
                                          {MasteryGradeLevels.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>

                                 <Col xs={24} xl={14}>
                                    <Form.Item label="Subject" name="subject" initialValue={masteryrec.subject}>
                                       <Select
                                          disabled
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Subject"
                                          value={masteryrec.subject}
                                       >
                                          {MasterySubjects.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>
                              </Row>

                              <Row gutter={12}>
                                 <Col sm={24} md={24} lg={12} style={{ marginBottom: "20px" }}>
                                    <Descriptions
                                       bordered
                                       size='small'
                                       column={2}
                                    >
                                       <Descriptions.Item label="Assessment Type" span={2} style={{ fontSize: 14, width: "25%", textAlign: "center" }}>
                                          Status
                                       </Descriptions.Item>

                                       <Descriptions.Item label='Formative Level 1' span={2} style={{ fontSize: 14, width: "25%" }}>
                                          <Space direction='horizontal' size={"small"}>
                                             {masteryrec.formative_1 !== null
                                                ?
                                                <>
                                                   {
                                                      masteryResult !== null && masteryResult[0].mastery_answer_id !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult[0].submit_status === 1
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[0].essay_score === 0 && masteryResult[0].essay_score_multi === "[]" && masteryResult[0].essay_total_points > 0
                                                                           ?
                                                                           <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                           :
                                                                           <>
                                                                              <Tag
                                                                                 color={"green"}
                                                                                 style={{ fontSize: 11 }}>Score: {masteryResult[0].score + "/" + masteryResult[0].total_points}</Tag>
                                                                              <Tag
                                                                                 color={Utils.GetProficiency(masteryResult[0].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(masteryResult[0].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                                 style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[0].percentage)}</Tag>
                                                                              <Tooltip mouseLeaveDelay="0.001" placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[0].assessment_type) + " Answer"}>
                                                                                 <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[0].assessment_type)}>
                                                                                    <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                 </Button>
                                                                              </Tooltip>
                                                                           </>
                                                                     }
                                                                  </>
                                                                  :
                                                                  <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag style={{ fontSize: 13 }}>Not Started</Tag>
                                                   }
                                                </>
                                                :
                                                <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                             }
                                          </Space>
                                       </Descriptions.Item>

                                       <Descriptions.Item label='Formative Level 2' span={2} style={{ fontSize: 14, width: "25%" }}>
                                          <Space direction='horizontal' size={"small"}>
                                             {masteryrec.formative_2 !== null
                                                ?
                                                <>
                                                   {
                                                      masteryResult !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult[1].mastery_answer_id !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[1].submit_status === 1
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[1].status === 2
                                                                                    ?
                                                                                    <Tag color={"#44b1ff"} style={{ fontSize: 13 }}>EXEMPTED</Tag>
                                                                                    :
                                                                                    <>
                                                                                       {
                                                                                          masteryResult[1].essay_score === 0 && masteryResult[1].essay_score_multi === "[]" && masteryResult[1].essay_total_points > 0
                                                                                             ?
                                                                                             <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                                             :
                                                                                             <>
                                                                                                <Tag
                                                                                                   color={"green"}
                                                                                                   style={{ fontSize: 11 }}>Score: {masteryResult[1].score + "/" + masteryResult[1].total_points}</Tag>
                                                                                                <Tag
                                                                                                   color={Utils.GetProficiency(masteryResult[1].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(masteryResult[1].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                                                   style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[1].percentage)}</Tag>
                                                                                                <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[1].assessment_type) + " Answer"}>
                                                                                                   <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[1].assessment_type)}>
                                                                                                      <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                                   </Button>
                                                                                                </Tooltip>
                                                                                             </>
                                                                                       }
                                                                                    </>
                                                                              }
                                                                           </>
                                                                           :
                                                                           <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                     }
                                                                  </>

                                                                  :
                                                                  <Tag style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag style={{ fontSize: 13 }}>Not Started</Tag>
                                                   }
                                                </>
                                                :
                                                <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                             }
                                          </Space>
                                       </Descriptions.Item>

                                       <Descriptions.Item label='Formative Level 3' span={4} style={{ fontSize: 14, width: "25%" }}>
                                          <Space direction='horizontal' size={"small"}>
                                             {masteryrec.formative_3 !== null
                                                ?
                                                <>
                                                   {
                                                      masteryResult !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult[2].mastery_answer_id !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[2].submit_status === 1
                                                                           ?
                                                                           <>
                                                                              {
                                                                                 masteryResult[2].essay_score === 0 && masteryResult[2].essay_score_multi === "[]" && masteryResult[2].essay_total_points > 0
                                                                                    ?
                                                                                    <Tag color={"gold"} style={{ fontSize: 13 }}>For teacher evaluation</Tag>
                                                                                    :
                                                                                    <>
                                                                                       <Tag
                                                                                          color={"green"}
                                                                                          style={{ fontSize: 11 }}>Score: {masteryResult[2].score + "/" + masteryResult[2].total_points}</Tag>
                                                                                       <Tag
                                                                                          color={Utils.GetProficiency(masteryResult[2].percentage) === "BEGINNER" ? '#6395F9' : (Utils.GetProficiency(masteryResult[2].percentage) === 'PROFICIENT' ? '#5AD8A6' : '#8593AE')}
                                                                                          style={{ fontSize: 11 }}>{Utils.GetProficiency(masteryResult[2].percentage)}</Tag>
                                                                                       <Tooltip placement="top" title={'View ' + Utils.GetTypeLabel(masteryResult[2].assessment_type) + " Answer"}>
                                                                                          <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handleViewAnswer(student_name, student_id, masteryResult[2].assessment_type)}>
                                                                                             <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                          </Button>
                                                                                       </Tooltip>
                                                                                    </>
                                                                              }
                                                                           </>
                                                                           :
                                                                           <Tag color={"gold"} style={{ fontSize: 13 }}>Answering</Tag>
                                                                     }
                                                                  </>

                                                                  :
                                                                  <Tag style={{ fontSize: 13 }}>Not Started</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag style={{ fontSize: 13 }}>Not Started</Tag>
                                                   }
                                                </>
                                                :
                                                <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                             }
                                          </Space>
                                       </Descriptions.Item>
                                    </Descriptions>
                                 </Col>
                                 <Col sm={24} md={24} lg={12} style={{ marginBottom: "20px" }}>
                                    <Descriptions
                                       bordered
                                       size='small'
                                       column={2}
                                    >
                                       <Descriptions.Item label="Study Plan" span={2} style={{ fontSize: 14, width: "25%", textAlign: "center" }}>
                                          Status
                                       </Descriptions.Item>
                                       <Descriptions.Item label='Study Plan 1' span={2} style={{ fontSize: 14, width: "25%" }}>
                                          <Space direction='horizontal' size={"small"}>
                                             {masteryrec.study_plan_1 !== null
                                                ?
                                                <>
                                                   {
                                                      masteryResult !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult[4].mastery_answer_id !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[4].submit_status === 1
                                                                           ?
                                                                           <>
                                                                              <Tag color={"green"} style={{ fontSize: 13 }}>Completed</Tag>
                                                                              <Tooltip placement="top" title={moment(masteryResult[4].start_date).format("MMM. DD, YYYY hh:mm:ss A") + ' To ' + moment(masteryResult[4].end_date).format("MMM. DD, YYYY hh:mm:ss A")}>
                                                                                 <Tag color={"green"} style={{ fontSize: 11 }}>
                                                                                    Duration {Math.floor((Math.abs(new Date(masteryResult[4].end_date) - new Date(masteryResult[4].start_date)) / 1000 / 60))} min(s)
                                                                                 </Tag>
                                                                              </Tooltip>
                                                                              <Tooltip placement="top" title='View Study Plan 1'>
                                                                                 <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handlePreviewStudyPlan('Study Plan 1', 'study_plan_1')}>
                                                                                    <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                 </Button>
                                                                              </Tooltip>
                                                                           </>
                                                                           :
                                                                           <>
                                                                              {
                                                                                 masteryResult[4].study_plan_status !== 1
                                                                                    ?
                                                                                    <Tag color={"gold"} style={{ fontSize: 11 }}>Studying</Tag>
                                                                                    :
                                                                                    <Space size={'small'}>
                                                                                       <Tag color={"gold"} style={{ fontSize: 11 }}>Done Studying</Tag>

                                                                                       {assign_info.teacher_intervene === 1 &&
                                                                                          <>
                                                                                             {masteryResult[4].allow_to_proceed === 0 || masteryResult[4].allow_to_proceed === null
                                                                                                ?
                                                                                                <Tag color={"red"} style={{ fontSize: 11 }}>Waiting for promotion</Tag>
                                                                                                :
                                                                                                <Tag color={"green"} style={{ fontSize: 11 }}>Promoted to Level 2</Tag>
                                                                                             }
                                                                                          </>
                                                                                       }
                                                                                    </Space>
                                                                              }
                                                                           </>
                                                                     }

                                                                     {
                                                                        masteryResult[4].study_plan_activity_docs !== null &&
                                                                        <Tooltip placement="top" title="View Teacher Remarks">
                                                                           <Button className='button-shadow' shape='round' type='primary' size='small' onClick={() => handleViewStudyPlanActivity(masteryResult[4], "study_plan_1")}>
                                                                              View Remarks
                                                                           </Button>
                                                                        </Tooltip>
                                                                     }
                                                                  </>

                                                                  :
                                                                  <Tag style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                   }
                                                </>
                                                :
                                                <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                             }
                                          </Space>
                                       </Descriptions.Item>
                                       <Descriptions.Item label='Study Plan 2' span={2} style={{ fontSize: 14, width: "25%" }}>
                                          <Space direction='horizontal' size={"small"}>
                                             {masteryrec.study_plan_2 !== null
                                                ?
                                                <>
                                                   {
                                                      masteryResult !== null
                                                         ?
                                                         <>
                                                            {
                                                               masteryResult[5].mastery_answer_id !== null
                                                                  ?
                                                                  <>
                                                                     {
                                                                        masteryResult[5].submit_status === 1
                                                                           ?
                                                                           <>
                                                                              <Tag color={"green"} style={{ fontSize: 13 }}>Completed</Tag>
                                                                              <Tooltip placement="top" title={moment(masteryResult[5].start_date).format("MMM. DD, YYYY hh:mm:ss A") + ' To ' + moment(masteryResult[5].end_date).format("MMM. DD, YYYY hh:mm:ss A")}>
                                                                                 <Tag color={"green"} style={{ fontSize: 11 }}>
                                                                                    Duration {Math.floor((Math.abs(new Date(masteryResult[5].end_date) - new Date(masteryResult[5].start_date)) / 1000 / 60))} min(s)
                                                                                 </Tag>
                                                                              </Tooltip>
                                                                              <Tooltip placement="top" title='View Study Plan 2'>
                                                                                 <Button className='button-shadow' size='small' shape='round' type='primary' onClick={() => handlePreviewStudyPlan('Study Plan 2', 'study_plan_2')}>
                                                                                    <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                                                                                 </Button>
                                                                              </Tooltip>
                                                                           </>
                                                                           :
                                                                           <>
                                                                              {
                                                                                 masteryResult[5].study_plan_status !== 1
                                                                                    ?
                                                                                    <Tag color={"gold"} style={{ fontSize: 11 }}>Studying</Tag>
                                                                                    :
                                                                                    <Space size={'small'}>
                                                                                       <Tag color={"gold"} style={{ fontSize: 11 }}>Done Studying</Tag>

                                                                                       {assign_info.teacher_intervene === 1 &&
                                                                                          <>
                                                                                             {masteryResult[5].allow_to_proceed === 0 || masteryResult[5].allow_to_proceed === null
                                                                                                ?
                                                                                                <Tag color={"red"} style={{ fontSize: 11 }}>Waiting for promotion</Tag>
                                                                                                :
                                                                                                <Tag color={"green"} style={{ fontSize: 11 }}>Promoted to Level 3</Tag>
                                                                                             }
                                                                                          </>
                                                                                       }
                                                                                    </Space>
                                                                              }
                                                                           </>
                                                                     }

                                                                     {
                                                                        masteryResult[5].study_plan_activity_docs !== null &&
                                                                        <Tooltip placement="top" title="View Teacher Remarks">
                                                                           <Button className='button-shadow' shape='round' type='primary' size='small' onClick={() => handleViewStudyPlanActivity(masteryResult[5], "study_plan_2")}>
                                                                              View Remarks
                                                                           </Button>
                                                                        </Tooltip>
                                                                     }
                                                                  </>

                                                                  :
                                                                  <Tag style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                            }
                                                         </>
                                                         :
                                                         <Tag style={{ fontSize: 13 }}>Not Viewed</Tag>
                                                   }
                                                </>
                                                :
                                                <Tag color={"gray"} style={{ fontSize: 13 }}>Not Available</Tag>
                                             }
                                          </Space>
                                       </Descriptions.Item>
                                    </Descriptions>
                                 </Col>
                              </Row>

                              <Row gutter={12}>
                                 <Col xs={24} xl={24}>
                                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                       <Form.Item >
                                          <Space size={"small"}>
                                             <Tooltip placement="top" title={'Reload my progress'}>
                                                <Button className='button-shadow'
                                                   loading={reloadDetails}
                                                   shape='round'
                                                   icon={<ReloadOutlined />}
                                                   style={{ marginLeft: "auto", marginRight: 10, marginTop: 10 }}
                                                   type="primary"
                                                   onClick={() => handleReload()}
                                                >
                                                   Reload My Progress
                                                </Button>
                                             </Tooltip>
                                             <Button className='button-shadow'
                                                shape='round'
                                                icon={<EyeOutlined />}
                                                style={{ marginLeft: "auto", marginRight: 10, marginTop: 10 }}
                                                type="primary"
                                                onClick={() => handleViewInstruction()}
                                             >
                                                View Instructions
                                             </Button>
                                             <Button className='button-shadow'
                                                shape='round'
                                                icon={<PlayCircleOutlined />}
                                                // icon={masteryResult[3].submit_status === 1 ? <CheckCircleOutlined /> : <PlayCircleOutlined />}
                                                style={{ marginLeft: "auto", marginRight: 10, marginTop: 10 }}
                                                type="primary"
                                                onClick={() => handleStartMastery(masteryResult)}
                                                disabled={(masteryResult[2].submit_status === 1 && Utils.GetProficiency(masteryResult[2].percentage) === "COMPETENT") || (masteryResult[3].submit_status === 1 && Utils.GetProficiency(masteryResult[3].percentage) === "COMPETENT") ? true : false}
                                             >
                                                {
                                                   masteryrec.summative === null
                                                      ?
                                                      <>
                                                         {
                                                            masteryResult[0].mastery_answer_id === null
                                                               ? "Start Mastery"
                                                               :
                                                               <>
                                                                  {
                                                                     masteryResult[2].submit_status === 1
                                                                        ?
                                                                        <>
                                                                           {
                                                                              // Utils.GetProficiency(masteryResult[2].percentage) === "COMPETENT"
                                                                              //    ?
                                                                              //    'Mastery Completed'
                                                                              //    :
                                                                              //    <>
                                                                              //       {
                                                                              //          masteryResult[2].essay_score === 0 && masteryResult[2].essay_score_multi === "[]" && masteryResult[2].essay_total_points > 0
                                                                              //             ? "Continue Mastery"
                                                                              //             : "View Recommendation"
                                                                              //       }
                                                                              //    </>
                                                                              masteryResult[2].essay_score === 0 && masteryResult[2].essay_score_multi === "[]" && masteryResult[2].essay_total_points > 0
                                                                                 ? "Continue Mastery"
                                                                                 : "View Recommendation"
                                                                           }

                                                                        </>
                                                                        : 'Continue Mastery'
                                                                  }
                                                               </>
                                                         }
                                                      </>
                                                      :
                                                      <>
                                                         {
                                                            masteryResult[3].mastery_answer_id === null
                                                               ?
                                                               'Start Summative'
                                                               :
                                                               <>
                                                                  {
                                                                     masteryResult[3].submit_status === 1
                                                                        ?
                                                                        'Summative Completed'
                                                                        :
                                                                        'Continue Summative'
                                                                  }
                                                               </>
                                                         }
                                                      </>
                                                }
                                             </Button>
                                          </Space>
                                       </Form.Item>
                                    </div>
                                 </Col>
                              </Row>
                           </Form>
                        </div>
                     </div>
                  </div>
               </div>
               {/* <div className="notice-area">
                  <div className="notice-item">
                     <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
                     <NoticeBoard />
                  </div>
               </div> */}
            </>
            :
            <>
               <div className="dashboard-area">
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment')}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a className="active">Start</a></li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment')}>Mastery Assessments</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Start</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </div>
               </div>
               {/* <div className="notice-area">
                  <div className="notice-item">
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </div>
               </div> */}
            </>
         }

         {/* Modals */}
         <Modal
            title={"View " + g_modal_title}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            // width={window.innerWidth - 300}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div className="pdf-all-page-container" style={{ height: "calc(100vh-200px" }}>
               <RenderPDFViewer url={g_modal_study_plan} height={"calc(100vh - 208px)"} showdownload={true} showprint={true} showpagecontrols={true} showfullscreen={true} />
            </div>
         </Modal>

         <Modal
            title={"View Activity"}
            centered
            destroyOnClose={true}
            open={viewStudyPlanActivityModalVisible}
            onOk={() => handleCloseViewStudyPlanActivity()}
            onCancel={() => handleCloseViewStudyPlanActivity()}
            width='90%'
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
         // style={{ minWidth: "350px" }}
         >
            <ViewActivities
               data={g_activity_data}
            />
         </Modal>

         <Modal
            title={"Teacher's Instructions"}
            centered
            destroyOnClose={true}
            open={viewInstructionModalVisible}
            onOk={() => handleCloseViewInstruction()}
            onCancel={() => handleCloseViewInstruction()}
            width='90%'
            okButtonProps={{ shape: "round" }}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
         // style={{ minWidth: "350px" }}
         >
            <ViewInstructions
               data={g_instruction_data}
            />
         </Modal>
      </>
   );
}