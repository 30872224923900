
import React, { useState } from "react";
import SimpleBarReact from "simplebar-react";
import { PDFReader } from 'reactjs-pdf-reader';
import { Spin } from 'antd';

export function RenderPDFReader(props) {

   const [pdfLoaded, setPdfLoaded] = useState(false);

   return (
      <>
         <div className="pdf-all-page-container" style={{ height: props.height }}>
            <div className='pdf_container' style={{ height: props.height }}>
               <SimpleBarReact style={{ height: '100%' }}>
                  <PDFReader
                     url={props.url}
                     showAllPage={true}
                     scale={1.3}
                     onDocumentComplete={() => setPdfLoaded(true)}
                  />
               </SimpleBarReact>
            </div>

            {/* <div className='pdf_container pdf_overlay' style={{ display: pdfLoaded ? 'none' : '', height: props.height }}> */}
            <div className='container_loading pdf_overlay' style={{ display: pdfLoaded ? 'none' : '', height: props.height }}>
               <Spin size='large' tip="Loading, please wait..." />
            </div>
            {/* </div> */}
         </div>
      </>
   );
};