/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { FormOutlined, CloudUploadOutlined, FileAddOutlined, VideoCameraOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {
   Button, Descriptions, message, Popconfirm, Typography, Modal, Row, Col,
   Upload, Divider, Space, Breadcrumb
} from 'antd';
import uuid from 'react-uuid';
import platform from 'platform';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Split from 'react-split';
import { useSelector } from 'react-redux';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
// import {BrowserView, MobileView} from 'react-device-detect'
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph, Title } = Typography;

let g_activity_for_upload = [];

export function MasteryStudyPlan(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const masteryrec = useSelector(state => state.masteryrec);

   const origin = location.state.origin;
   const assign_info = location.state.assign_info;
   const study_plan = location.state.study_plan;
   const study_plan_url = eval('masteryrec.' + location.state.study_plan);
   const join_url = location.state.join_url;
   // const status = location.state.status;
   const [status, setStatus] = useState(location.state.status);

   const subdomain = localStorage.getItem("lms-subdomain");

   const [uploadFileList, setUploadFileList] = useState([]);
   const [showUploadButton, setShowUploadButton] = useState(false);

   const [startTime, setStartTime] = useState(() => {
      var timeStarted = JSON.parse(localStorage.getItem(user_id + "_" + assign_info.id + "_timestarted"));
      if (timeStarted !== null) {
         var val = timeStarted;
         return val;
      } else {
         return null;
      }
   });

   useEffect(() => {
      Utils.verifyJwt();

      var val = Date.now();
      localStorage.setItem(user_id + "_" + assign_info.id + "_timestarted", JSON.stringify(val));
      setStartTime(val);
   }, []);

   const handleProceedToMastery = () => {
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

      let timestampz = new Date().getTime();

      var ansInfo = {
         // Id: "ans_" + o2o_mode + "_" + uuid(),
         Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
         Mastery_assessment_id: masteryrec.id,
         Mastery_assignment_id: assign_info.id,
         Student_id: user_id,
         User_agent: platform.ua,
         Browser: platform.name,
         Browser_version: platform.version,
         OS_platform: platform.os.family,
         Device: platform.product,
         Assessment_type: null,
      }

      var assInfo = {
         Id: masteryrec.id,
         Title: masteryrec.title,
         Term: masteryrec.term,
         File_url: null,
         Created_by: masteryrec.created_by,
         Question_sheet: null,
      };

      if (study_plan === 'study_plan_1') {
         ansInfo.Assessment_type = 'formative_2';
         assInfo.File_url = masteryrec.formative_2_file_url;
         assInfo.Question_sheet = masteryrec.formative_2;

         const addAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addanswer`;
         axios.post(addAnsUrl, ansInfo).then(response => {
            if (response.data) {
               if (location.state.qt === '1')
                  navigate('/masteryassessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_2', origin, card_bg: location.state.card_bg } });

               if (location.state.qt === '2')
                  navigate('/masteryassessment/answer-manual', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_2', origin, card_bg: location.state.card_bg } });
               // navigate('/masteryassessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_2' } });
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Attempt to answer mastery assessment failed.',
               centered: true
            });
         });
      }
      else if (study_plan === 'study_plan_2') {
         ansInfo.Assessment_type = 'formative_3';
         assInfo.File_url = masteryrec.formative_3_file_url;
         assInfo.Question_sheet = masteryrec.formative_3;

         const addAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/addanswer`;
         axios.post(addAnsUrl, ansInfo).then(response => {
            if (response.data) {
               if (location.state.qt === '1')
                  navigate('/masteryassessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_3', origin, card_bg: location.state.card_bg } });

               if (location.state.qt === '2')
                  navigate('/masteryassessment/answer-manual', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_3', origin, card_bg: location.state.card_bg } });
               // navigate('/masteryassessment/answer-pdf', { state: { id: ansInfo.Id, assInfo, assignInfo: assign_info, type: 'formative_3' } });
            }
         }, (error) => {
            console.log(error.message);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Attempt to answer mastery assessment failed.',
               centered: true
            });
         });
      }
   }

   const handleCompleteStudyPlan = () => {
      var url = `${process.env.REACT_APP_API_STUDENT_MASTERY_ASSESSMENT}/isallowedtoproceed?student_id=${user_id}&assessment_type=${study_plan}`;

      axios.get(url).then(response => {
         var allow = response.data;

         if (assign_info.teacher_intervene === 0)
            allow = 1;

         if (allow === 1) {

            const updateAnsUrl = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/completestudyplan?student_id=${user_id}&assessment_type=${study_plan}&assignment_id=${assign_info.id}`;
            axios.post(updateAnsUrl).then(response => {
               if (response.data) {
                  // const data = response.data;
                  handleProceedToMastery();
               }
            }, (error) => {
               Modal.error({
                  title: 'Mastery Assessment',
                  content: 'Completion of Study Plan failed.',
                  centered: true
               });
            });
         }
         else {
            var message_content = RenderMessageContent("Please wait for the teacher's approval before proceeding to next level.");

            Modal.info({
               title: 'Mastery Assessment',
               content: message_content,
               centered: true,
               onOk: goBackToPreview,
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const goBackToPreview = () => {
      navigate(`/studentmasteryassessment/preview?assign_id=${assign_info.id}&mastery_id=${assign_info.mastery_assessment_id}&origin=${origin}`);
   }

   function GetStudyPlanLabel(study_plan) {
      switch (study_plan) {
         case 'study_plan_1':
            return 'Study Plan 1';
         case 'study_plan_2':
            return 'Study Plan 2'
      }
   }

   function RenderMessageContent(message) {
      var retVal = (
         <>
            <Row>
               <Col span={16} style={{ textAlign: "left" }}>
                  {message}
               </Col>
               <Col span={8} style={{ paddingLeft: "10px" }}>
                  <img src="../images/brainee_mascot.png" width="100%" style={{ paddingBottom: "30px" }}></img>
               </Col>
            </Row>
         </>
      );

      return retVal;
   }

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);

            g_activity_for_upload = newFileList;

            return newFileList;
         })
      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX;

         if (fileValid) {
            const isLt100M = file.size / 1024 / 1024 <= 100;

            if (isLt100M) {
               var list = uploadFileList;
               list.push(file);
               g_activity_for_upload = list;
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Mastery Assessment',
                  content: 'File should be less than 100 MB',
                  centered: true
               });
            }
         } else {
            Modal.error({
               title: 'Mastery Assessment',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      uploadFileList,
   };

   const handleUploadDocuments = file => {
      if (uploadFileList.length > 0) {
         var formData = new FormData();
         var fileList = [];

         uploadFileList.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
            fileList.push(file.name);
         });

         const key = 'updatable';
         message.loading({ content: 'Uploading your files(s)...', key, duration: 0 });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               var activity_data = {
                  student_id: user_id,
                  assessment_id: assign_info.mastery_assessment_id,
                  assignment_id: assign_info.id,
                  documents: uploadedfiles,
                  document_names: fileList.join(','),
                  study_plan_type: study_plan,
               }

               var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertstudyplanactivity`;
               axios.post(url, activity_data).then(response => {
                  setTimeout(() => {
                     message.success({ content: 'File upload complete!', key, duration: 2 });
                  }, 1000);

                  g_activity_for_upload = [];
                  setUploadFileList([]);
               }, (error) => {
                  setTimeout(() => {
                     message.success({ content: 'File upload failed!', key, duration: 2 });
                  }, 1000);
               });
            }
         }, (error) => {
            setTimeout(() => {
               message.success({ content: 'File upload failed!', key, duration: 2 });
            }, 1000);
         });
      }
   };

   const handleUploadOnChange = () => {
      if (g_activity_for_upload.length > 0)
         setShowUploadButton(true);
      else
         setShowUploadButton(false);
   }

   const handleJoinZoom = () => {
      window.open(join_url, "_blank").focus();
   }

   const handleDoneStudying = (student, study_plan, assignment) => {
      var dtNow = Date.now();
      var timeDiff = ((Math.abs(dtNow - startTime)) / 1000 / 60);

      const key = 'updatable';

      if (timeDiff > 1) { //-- Make sure the student study the study plan for more than a minute

         localStorage.removeItem(user_id + "_" + assign_info.Id + "_timestarted");

         message.loading({ content: "Updating study plan status...", key });
         // setTrackerLoading(true);

         axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/setdonestudying?student_id=${student}&study_plan_type=${study_plan}&assignment_id=${assignment}`).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               message.success({ content: "Update successful!", key, duration: 1 });

               if (assign_info.teacher_intervene === 1)
                  goBackToPreview();
               else
                  setStatus(1);
            }
         }, (error) => {
            message.success({ content: "Update failed!", key, duration: 1 });
            console.log(error.message);
         });
      }
      else {
         Modal.info({
            title: 'Mastery Assessment',
            content: 'We have noticed that you opened the study plan in less than a minute. Please go back to the study plan and read it thoroughly before proceeding.',
            centered: true
         });
      }
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div>
                  <div className="dashboard-item">
                     <div className="dashboard-item-inner">
                        {/* <ul>
                           <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                           <li><span>/</span></li>
                           <li className="active">Brainee LMS</li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: "1" } })}>Mastery Assessments</a></li>
                           <li><span>/</span></li>
                           <li><a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_info.id}&mastery_id=${masteryrec.id}&origin=${origin}&qt=${assign_info.mode}`)}>Start</a></li>
                           <li><span>/</span></li>
                           <li className="active">{GetStudyPlanLabel(study_plan)}</li>
                        </ul> */}
                        <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                           <Breadcrumb.Item>
                              <Link to="/home">Home</Link>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: '1' } })}>Mastery Assessments</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>
                              <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assign_info.id}&mastery_id=${masteryrec.id}&origin=${origin}&qt=${assign_info.mode}`)}>Start</a>
                           </Breadcrumb.Item>
                           <Breadcrumb.Item>{GetStudyPlanLabel(study_plan)}</Breadcrumb.Item>
                        </Breadcrumb>
                     </div>

                     <div className="pdf-all-page-container">
                        {
                           eval('masteryrec.' + location.state.study_plan) &&
                           <>
                              <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
                                 <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                    <Viewer 
                                       pageLayout={{transformSize: ({ size }) => ({
                                          height: size.height + 30,
                                          width: size.width + 30,
                                       }),}}  
                                       fileUrl={study_plan_url.includes('base64') ? study_plan_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${study_plan_url}`}  
                                       defaultScale={SpecialZoomLevel.PageWidth}   
                                       initialPage={0} 
                                    />
                                 </Worker>
                              </div>
                           </>
                           // <object data={study_plan_url.includes('base64') ? study_plan_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${study_plan_url}`} width="100%" type="application/pdf" style={{ height: 'calc(100vh - 58px)' }}>
                           //    <p>Brainee LMS PDF - View<a href={study_plan_url.includes('base64') ? study_plan_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${study_plan_url}`}> PDF!</a></p>
                           // </object>
                           // <RenderPDFViewer url={study_plan_url.includes('base64') ? study_plan_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${study_plan_url}`} height={"calc(100vh - 58px)"} />
                        }
                     </div>
                     {/* {
                        eval('masteryrec.' + location.state.study_plan) &&
                        <RenderPDFReader url={study_plan_url.includes('base64') ? study_plan_url : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${study_plan_url}`} height={"calc(100vh - 195px)"} />
                     } */}
                  </div>
               </div>

               <div>
                  <div className="notice-item">
                     <>
                        {  //assInfo.Question_sheet && 
                           <div className='answer-sheet-content'>
                              <SimpleBarReact>
                                 <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                    <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%", fontWeight: "500" }}>{assign_info.title}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Competency"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%", fontWeight: "500" }}>{assign_info.competency}</Paragraph>
                                    </Descriptions.Item>
                                    <Descriptions.Item label={"Subject"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                       <Paragraph style={{ textAlign: "center", width: "100%", fontWeight: "500" }}>{assign_info.subject_desc ?? assign_info.subject_name}</Paragraph>
                                    </Descriptions.Item>
                                    {
                                       join_url !== "" && join_url !== null &&
                                       <Descriptions.Item label={"Video Conference Session"} span={4} style={{ textAlign: "center" }} >
                                          <Button className='button-shadow' block type='primary' shape='round' style={{ marginBottom: "15px" }} onClick={() => handleJoinZoom()}>
                                             <Text ellipsis style={{ color: "#fff" }}><VideoCameraOutlined />&nbsp;Join</Text>
                                          </Button>
                                       </Descriptions.Item>
                                    }
                                 </Descriptions>

                                 {/* <StickyBox style={{ zIndex: "1000", width: "100%" }}> */}
                                 <Row gutter={12}>
                                    <Col xs={24} md={12} lg={12}>
                                       {
                                          status === 1
                                             ?
                                             <Button disabled={true} block shape='round' style={{ marginBottom: "15px" }}>
                                                <Text ellipsis style={{ color: "#ccc" }}><CheckCircleOutlined />&nbsp;Done Studying</Text>
                                             </Button>
                                             :
                                             <Popconfirm
                                                title={"Are you done studying?"}
                                                onConfirm={() => handleDoneStudying(user_id, study_plan, assign_info.id)}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }}>
                                                   <Text ellipsis style={{ color: "#fff" }}><CheckCircleOutlined />&nbsp;Done Studying</Text>
                                                </Button>
                                             </Popconfirm>
                                       }

                                    </Col>
                                    <Col xs={24} md={12} lg={12}>
                                       {
                                          status === 1
                                             ?
                                             <Popconfirm
                                                title={"Are you ready to proceed to the next Formative Assessment?"}
                                                onConfirm={() => handleCompleteStudyPlan()}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }}>
                                                   <Text ellipsis style={{ color: "#fff" }}><FormOutlined />&nbsp;Continue Mastery</Text>
                                                </Button>
                                             </Popconfirm>

                                             :
                                             <Button disabled={true} block shape='round' style={{ marginBottom: "15px" }}>
                                                <Text ellipsis style={{ color: "#ccc" }}><FormOutlined />&nbsp;Continue Mastery</Text>
                                             </Button>
                                       }

                                    </Col>
                                 </Row>

                                 <Divider orientation="left">
                                    <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Upload</Title>
                                 </Divider>

                                 <Space size={"small"} direction={"vertical"} style={{ marginBottom: "15px", width: "100%" }}>
                                    <Upload
                                       // disabled={status === 1 ? true : false}
                                       onChange={() => handleUploadOnChange()}
                                       maxCount={5}
                                       multiple
                                       {...upload_props}
                                       listType="picture"
                                       defaultFileList={[...g_activity_for_upload]}
                                       className="upload-list-inline"
                                       accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.xls,.xlsx,.png,.jpg,.pdf,.ppsx"
                                    >
                                       <Button shape='round' icon={<FileAddOutlined />} block>Select Activities to Upload</Button>
                                    </Upload>
                                    {
                                       g_activity_for_upload.length > 0
                                          ?
                                          <Button className='button-shadow' type='primary' block shape='round' onClick={() => handleUploadDocuments()}>
                                             <Text ellipsis style={{ color: "#fff" }}><CloudUploadOutlined />&nbsp;Upload Selected File(s)</Text>
                                          </Button>
                                          :
                                          <></>
                                    }
                                 </Space>
                              </SimpleBarReact>
                           </div>
                        }
                     </>
                  </div>
               </div>
            </Split>
         </div>
      </>
   )
}
