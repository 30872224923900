import React, { useEffect, useState, useReducer, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
   Divider, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col, message, Popconfirm,
   Radio, Tag, Drawer, notification, Modal, DatePicker, Upload, Form, Tree
} from 'antd';
import * as AntDIcons from '@ant-design/icons';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
// import { SchoolTerms, Levels } from '../../../constants'
import Utils from '../../../misc/Utils';
import Enums from '../../../misc/Enums';
// import { Editor } from '@tinymce/tinymce-react';
import StickyBox from "react-sticky-box";
import moment from 'moment';
import Wysiwyg from '../../../components/wysiwyg/wysiwyg';

export default function Create(props) {
   const [form] = Form.useForm();
   const editorRef = useRef(null);
   const navigate = useNavigate();

   const [subjectList, setSubjectList] = useState([]);
   const [sectionList, setSectionList] = useState([]);
   const [levelList, setLevelList] = useState([]);
   const [secsubCreateLoading, setSecSubCreateLoading] = useState(false);
   const [homeworkTitle, setHomeworkTitle] = useState("");
   const [levelCreate, setLevelCreate] = useState(null);
   const [sectionCreate, setSectionCreate] = useState([]);
   const [subjectCreate, setSubjectCreate] = useState(null);
   const [descriptionCreate, setDescriptionCreate] = useState(null);
   const [uploadFileList, setUploadFileList] = useState([]);
   const [subjectCreateList, setSubjectCreateList] = useState([]);
   const [sectionCreateList, setSectionCreateList] = useState([]);
   const [homeworkDate, setHomeworkDate] = useState('');
   const [submissionDate, setSubmissionDate] = useState('');

   const [checkedKeys, setCheckedKeys] = useState([]);
   const [selectedKeys, setSelectedKeys] = useState([]);
   const [treeData, setTreeData] = useState([]);
   const [expandedKeys, setExpandedKeys] = useState([]);
   const [assignedStudents, setAssignedStudents] = useState(null);
   const [assignedStudentsWithSection, setAssignedStudentsWithSection] = useState(null);

   const { Option } = Select;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   // const dateFormat = 'MMM D, YYYY';
   // const customFormat = value => `${value.format(dateFormat)}`;

   const dateFormat = 'MMM D, YYYY h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      getLevels();
   }, []);

   const getLevels = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/levellist`).then((response) => {
         if (response.data) {
            const data = response.data;
            var tmp_levels = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               tmp_levels.push(obj);
            });

            setLevelList(tmp_levels);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const upload_props = {
      onRemove: file => {
         setUploadFileList(() => {
            const index = uploadFileList.indexOf(file.originFileObj);
            const newFileList = uploadFileList.slice();
            newFileList.splice(index, 1);
            return newFileList;
         })
      },

      beforeUpload: file => {
         const isPNG = file.type === 'image/png';
         const isJPG = file.type === 'image/jpeg';
         const isBMP = file.type === 'image/bmp';
         const isDOC = file.type === 'application/msword' || file.type === 'application/vnd.ms-word';
         const isDOCX = file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
         const isXLS = file.type === 'application/msexcel' || file.type === 'application/vnd.ms-excel';
         const isXLSX = file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
         const isPPT = file.type === 'application/vnd.ms-powerpoint';
         const isPPTX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
         const isPPSX = file.type === 'application/vnd.openxmlformats-officedocument.presentationml.slideshow';
         const isPDF = file.type === 'application/pdf';

         const fileValid = isPNG || isJPG || isBMP || isDOC || isDOCX || isXLS || isXLSX || isPPT || isPPTX || isPDF || isPPSX;

         const allowedFileSize = 50;

         if (fileValid) {
            const isLtMB = file.size / 1024 / 1024 <= allowedFileSize;

            if (isLtMB) {
               var list = uploadFileList;
               list.push(file);
               setUploadFileList(list);
            } else {
               Modal.error({
                  title: 'Homework',
                  content: `File should be less than ${allowedFileSize} MB`,
                  centered: true
               });
            }

            // console.log(uploadFileList);
         } else {
            Modal.error({
               title: 'Homework',
               content: 'You are not allowed to upload that file type',
               centered: true
            });
         }

         return false;
      },

      onChange(info) {
         if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
         }
         if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
         } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
         }
      },

      progress: {
         strokeColor: {
            '0%': '#108ee9',
            '100%': '#87d068',
         },
         strokeWidth: 3,
         format: percent => percent && `${parseFloat(percent.toFixed(2))}%`,
      },

      uploadFileList,
   };

   const handleLevelCreateChange = (val) => {
      setSectionCreate([]);
      setSubjectCreate(null);
      setLevelCreate(val);
      setSecSubCreateLoading(true);

      var subjectlist = [];
      var sectionlist = [];
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${val}&school_year=-1`).then((response) => { //-- school_year param will be ignored in api
         if (response.data) {
            const data = response.data;

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               subjectlist.push(obj);
            });

            setSubjectCreateList(subjectlist);

            axios.get(`${process.env.REACT_APP_API_ASSESSMENT}/studentlistperleveljson?level=${val}`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  setTreeData(data);
                  setCheckedKeys([]);
                  setExpandedKeys([]);

                  setSecSubCreateLoading(false);
               }
            }, (error) => {
               console.log(error.message);
               setTreeData([]);
               setCheckedKeys([]);
               setExpandedKeys([]);

               setSecSubCreateLoading(false);
            });

            // axios.get(`${process.env.REACT_APP_API_LOOKUP}/sectionlistbylevel?level=${val}`).then((response) => {
            //    if (response.data) {
            //       const data = response.data;

            //       data.map((item) => {
            //          let obj = { 'value': item.value, 'name': item.name }
            //          sectionlist.push(obj);
            //       });

            //       setSectionCreateList(sectionlist);
            //       setSecSubCreateLoading(false);
            //    }
            // }, (error) => {
            //    console.log(error.message);
            // });

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleDescriptionChange = (val) => {
      setDescriptionCreate(val);
   }

   const handleFormValuesChange = (changedValues) => {
      const formFieldName = Object.keys(changedValues)[0];

      if (formFieldName === "level") {
         setLevelCreate(changedValues[formFieldName]);
         form.setFieldsValue({ section: undefined });
         form.setFieldsValue({ subject: undefined });
         setSubjectList([]);
         setSectionList([]);
         setAssignedStudents([]);
         setAssignedStudentsWithSection([]);
      }
   };

   const handleCancelCreate = () => {
      setUploadFileList([]);
      setSectionCreate([]);
      setSubjectCreate(null);
      setLevelCreate(null);
      form.resetFields();
      props.onCancelCallback();
   }

   const onFinish = (values) => {
      let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      var homework_id = "homework_" + o2o_mode + "_" + uuid();
      let homeWorkData = {
         id: homework_id,
         class_id: levelCreate,
         session_id: 0,
         title: homeworkTitle,
         homework_date: moment(homeworkDate).format('YYYY-MM-DD HH:mm:ss'),
         submit_date: moment(submissionDate).format('YYYY-MM-DD HH:mm:ss'),
         subject_id: subjectCreate,
         description: descriptionCreate,
         created_by: user,
         document: '',
         sections: '', //sectionCreate.join(','),
         assigned_students: assignedStudentsWithSection,
         students: assignedStudents,
      }

      props.onFinishCallback(homeWorkData, uploadFileList)
   }

   const onExpand = (expandedKeysValue) => {
      setExpandedKeys(expandedKeysValue);
   };

   const onCheck = (checkedKeysValue) => {
      setCheckedKeys(checkedKeysValue);

      //-- (start) with section ------------------------------
      const selectedStudsWithSection = checkedKeysValue.filter((e) => {
         return e.includes('_');
         //return e.includes('|');
      });

      setAssignedStudentsWithSection(selectedStudsWithSection.length > 0 ? selectedStudsWithSection.join(',') : '');
      //-- (end) with section ------------------------------

      if (selectedStudsWithSection.length > 0) {
         //-- Remove the section part
         for (var i = 0; i < selectedStudsWithSection.length; i++) {
            selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('_') + 1, selectedStudsWithSection[i].length);
            //selectedStudsWithSection[i] = selectedStudsWithSection[i].substring(selectedStudsWithSection[i].indexOf('|') + 1, selectedStudsWithSection[i].length);
         }

         var selected = [...new Set(selectedStudsWithSection)]; //-- Remove duplicate students
         setAssignedStudents(selected.join(','));
      } else {
         setAssignedStudents('');
      }
   };

   const onSelect = (selectedKeysValue, info) => {
      setSelectedKeys(selectedKeysValue);
   };

   return (
      <>
         <Form
            form={form}
            layout={"vertical"}
            colon={false}
            onFinish={onFinish}
            onValuesChange={handleFormValuesChange}
         >
            <Row gutter={12}>
               <Col xs={24} md={16} lg={16}>
                  <Form.Item name="title" label="Title"
                     rules={[{
                        required: true,
                        message: 'Title should not be blank'
                     }]}
                  >
                     <Input
                        allowClear
                        size='middle'
                        style={{ borderRadius: 7 }}
                        placeholder="Enter homework title"
                        // onChange={(e) => setHomeworkTitle(e.target.value)}
                        onBlur={(event) => setHomeworkTitle(event.target.value)}
                     />
                  </Form.Item>
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Form.Item name="homework_date" label="Homework Date"
                     rules={[{
                        required: true,
                        message: 'Please enter homework date'
                     }]}
                  >
                     <DatePicker
                        showTime={{ format: 'hh:mm A', use12Hours: true }}
                        format={customFormat}
                        style={{ width: "100%" }}
                        onBlur={(event) => setHomeworkDate(event.target.value)}
                     />
                  </Form.Item>
               </Col>
            </Row>

            <Row gutter={12}>
               <Col xs={24} md={8} lg={8}>
                  <Form.Item name="level" label="Level"
                     rules={[{
                        required: true,
                        message: 'Please select level'
                     }]}
                  >
                     <Select
                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Level"
                        onChange={(val) => handleLevelCreateChange(val)}
                     >
                        {levelList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Form.Item>
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Form.Item name="subject" label="Subject"
                     rules={[{
                        required: true,
                        message: 'Please select subject'
                     }]}
                  >
                     <Select
                        loading={secsubCreateLoading}

                        showArrow
                        size='middle'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select Subject"
                        value={subjectCreate}
                        onChange={(val) => setSubjectCreate(val)}
                     >
                        {subjectCreateList.map(({ value, name }, idx) => {
                           return <Option value={value}>{name}</Option>
                        })}
                     </Select>
                  </Form.Item>
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <Form.Item name="submission_date" label="Submission Date"
                     rules={[{
                        required: true,
                        message: 'Please enter submission date'
                     }]}
                  >
                     <DatePicker
                        showTime={{ format: 'hh:mm A', use12Hours: true }}
                        style={{ width: "100%" }}
                        onBlur={(event) => setSubmissionDate(event.target.value)}
                        format={customFormat}
                     />
                  </Form.Item>
               </Col>
            </Row>

            <Row gutter={12} >
               <Col sm={24} md={8} lg={8}>
                  <Form.Item name="select_students" label="Select Students" style={{ marginBottom: '5px' }}
                  // rules={[{
                  //    required: true,
                  //    message: 'Please select students'
                  // }]}
                  >
                     <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                        <div className="student-div" style={{ height: '250px' }}>
                           <Tree
                              checkable
                              onExpand={onExpand}
                              autoExpandParent={true}
                              onCheck={onCheck}
                              checkedKeys={checkedKeys}
                              onSelect={onSelect}
                              selectedKeys={selectedKeys}
                              treeData={treeData}
                              style={{ paddingBottom: "15px", paddingTop: "15px" }}
                              defaultExpandParent={true}
                           />
                        </div>
                     </SimpleBarReact>
                  </Form.Item>
               </Col>
               <Col xs={24} md={8} lg={8}>
                  <label>Description</label>
                  <Wysiwyg
                     changeContentCallback={(e) => handleDescriptionChange(e)}
                     content={''}
                     height={250}
                     contentHeight={135}
                  />
                  {/* <Editor
                     apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                     onInit={(evt, editor) => editorRef.current = editor}
                     onFocusOut={(e) => handleDescriptionChange(e.target.innerHTML)}
                     initialValue=""
                     init={{
                        menubar: false,
                        height: 250,
                        statusbar: true,
                        plugins: [
                           'advlist autolink lists link image charmap print preview anchor',
                           'searchreplace visualblocks code',
                           'insertdatetime media table paste code help wordcount emoticons'
                        ],
                        toolbar: 'undo redo | formatselect | ' +
                           'bold italic backcolor | alignleft aligncenter ' +
                           'alignright alignjustify | bullist numlist outdent indent | ' +
                           'removeformat | emoticons | help',
                        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                     }}
                  /> */}
               </Col>
               <Col sm={24} md={8} lg={8}>
                  <Form.Item name="attached_documents" label="Attach Document(s)" style={{ marginBottom: '5px' }}>
                     <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                        <StickyBox style={{ zIndex: 1000 }}>
                           <Upload
                              style={{ display: "block" }}
                              maxCount={3}
                              multiple
                              {...upload_props}
                              listType="picture"
                              defaultFileList={[...uploadFileList]}
                              className="upload-list-inline"
                              accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                .xls,.xlsx,.png,.jpg,.pdf,.ppsx"
                           >
                              <Button shape='round' icon={<AntDIcons.CloudUploadOutlined />} block>Select File</Button>
                           </Upload>
                        </StickyBox>
                     </SimpleBarReact>
                  </Form.Item>
               </Col>
            </Row>

            {/* <Row gutter={24} >
               <Col span={24}>
                  <div className='input-columns'>
                     <label>Description</label>
                     <Editor
                        apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
                        onInit={(evt, editor) => editorRef.current = editor}
                        onFocusOut={(e) => handleDescriptionChange(e.target.innerHTML)}
                        initialValue=""
                        init={{
                           menubar: true,
                           height: 250,
                           statusbar: true,
                           plugins: [
                              'advlist autolink lists link image charmap print preview anchor',
                              'searchreplace visualblocks code',
                              'insertdatetime media table paste code help wordcount emoticons'
                           ],
                           toolbar: 'undo redo | formatselect | ' +
                              'bold italic backcolor | alignleft aligncenter ' +
                              'alignright alignjustify | bullist numlist outdent indent | ' +
                              'removeformat | emoticons | help',
                           content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                        }}
                     />

                     <Form.Item name="attached_documents" label="Attach Document(s)">
                        <SimpleBarReact style={{ maxHeight: "250px", overflow: "auto" }}>
                           <StickyBox style={{ zIndex: 1000 }}>
                              <Upload
                                 style={{ display: "block" }}
                                 maxCount={3}
                                 multiple
                                 {...upload_props}
                                 listType="picture"
                                 defaultFileList={[...uploadFileList]}
                                 className="upload-list-inline"
                                 accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,
                                .xls,.xlsx,.png,.jpg,.pdf,.ppsx"
                              >
                                 <Button shape='round' icon={<AntDIcons.UploadOutlined />} block>Select File</Button>
                              </Upload>
                           </StickyBox>
                        </SimpleBarReact>
                     </Form.Item>
                  </div>
               </Col>
            </Row> */}

            <Row gutter={12}>
               <Col span={24} style={{ textAlign: "right" }}>
                  <Space direction='horizontal'>
                     <Button shape='round' onClick={() => { handleCancelCreate(); }}>Cancel</Button>
                     <Button type='primary' shape='round' htmlType='submit'>Save</Button>
                  </Space>
               </Col>
            </Row>
         </Form>
      </>
   );
}