/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import './MasteryAssessment.css';
import {
   Button, Input, Form, Select, Tag, Row, Col, Descriptions, Space, Tooltip, message,
   Upload, Modal, Popconfirm, Breadcrumb, Progress, Image
} from 'antd';
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { CloudUploadOutlined, PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined, SaveOutlined } from '@ant-design/icons';
import uuid from 'react-uuid';
import axios from 'axios';
// import { MasteryTerms, Levels, MasteryCompetency, MasteryGradeLevels, MasterySubjects } from '../../constants'
// import NoticeBoardSlide from '../../components/notice-board/NoticeBoard';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import SimpleBarReact from "simplebar-react";
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { update_mastery_data, reset_mastery_data } from '../../actions';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import {BrowserView, MobileView} from 'react-device-detect';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

// import CreateRecommendations from './Components/CreateRecommendations';

var g_mastery_id = "";
var g_modal_title = "";
var g_modal_study_plan = "";

export function MasteryCreate() {
   const navigate = useNavigate();
   const location = useLocation();
   const dispatch = useDispatch();

   const [form] = Form.useForm();

   // const { Text, Paragraph } = Typography;
   const { Option } = Select;
   const { TextArea } = Input;

   const search = window.location.search;
   const query = new URLSearchParams(search);

   const questionType = query.get('qt');

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);
   const masteryrec = useSelector(state => state.masteryrec);

   // const [subjectList, setSubjectList] = useState([]);
   const [modalVisible, setModalVisible] = useState(false);

   // const [createRecommendationModalVisible, setCreateRecommendationModalVisible] = useState(false);
   // const [editRecommendationModalVisible, setEditRecommendationModalVisible] = useState(false);

   // const [creating, setCreating] = useState(false);
   // const [updating, setUpdating] = useState(false);

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   const [MasteryTerms, setMasteryTerms] = useState([]);
   const [MasteryCompetency, setMasteryCompetency] = useState([]);
   const [MasteryGradeLevels, setMasteryGradeLevels] = useState([]);
   const [MasterySubjects, setMasterySubjects] = useState([]);

   const [pdfLoaded, setPdfLoaded] = useState(false);

   const dateFormat = 'MMM D YYYY, h:mm a';
   const customFormat = value => `${value.format(dateFormat)}`;

   const subdomain = localStorage.getItem("lms-subdomain");

   useEffect(() => {
      Utils.verifyJwt();
      if (user_role.toLowerCase() !== 'admin')
         navigate('/home');

      var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
      g_mastery_id = "mastery_" + o2o_mode + "_" + uuid();

      var params = { key: `id`, value: g_mastery_id };
      dispatch(update_mastery_data(params));

      getMasteryLookUps();
   }, []);

   const getMasteryLookUps = () => {
      var _masteryTerms = [];
      var _masteryCompetency = [];
      var _masteryGradeLevels = [];
      var _masterySubjects = [];

      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               _masteryTerms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     _masterySubjects.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           _masteryGradeLevels.push(obj);
                        });

                        axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterycompetency`).then((response) => {
                           if (response.data) {
                              const data = response.data;
                              data.map((item) => {
                                 let obj = { 'value': item.value, 'name': item.name }
                                 _masteryCompetency.push(obj);
                              });
                           }

                           setMasteryTerms(_masteryTerms);
                           setMasteryCompetency(_masteryCompetency);
                           setMasteryGradeLevels(_masteryGradeLevels);
                           setMasterySubjects(_masterySubjects);
                        }, (error) => {
                           console.log(error.message);
                        });
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleGradeLevelChange = (val) => {
      handleGradeLevel(val);
      // setMasterySubject(null);

      // var slist = [];
      // axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${val}&school_year=16`).then((response) => {
      //    if (response.data) {
      //       const data = response.data;

      //       data.map((item) => {
      //          let obj = { 'value': item.value, 'name': item.name }
      //          slist.push(obj);
      //       });

      //       setSubjectList(slist);
      //    }
      // }, (error) => {
      //    console.log(error.message);
      // });
   }

   const handleTitle = (val) => {
      var params = { key: `title`, value: val === '' ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleCompetency = (val) => {
      var params = { key: `mastery_competency`, value: val === undefined ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleLevel = (val) => {
      var params = { key: `mastery_level`, value: val === undefined ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleTerm = (val) => {
      var params = { key: `term`, value: val === undefined ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleGradeLevel = (val) => {
      var params = { key: `level`, value: val === undefined ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleSubject = (val) => {
      var params = { key: `subject`, value: val === undefined ? null : val };
      dispatch(update_mastery_data(params));
   }

   const handleCreateAssessment = (title, type) => {
      var isValid = masteryrec.title !== null && masteryrec.mastery_competency !== null && masteryrec.term !== null && masteryrec.level !== null && masteryrec.subject !== null;

      if (isValid) {
         let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
         let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

         //-- Get card background based on subject and level
         // let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
         let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), type);

         if (questionType === '1')
            navigate('/masteryassessment/create-pdf', { state: { title, type, mode: 'create', card_bg: bg_image, subjectid: subject.value, subjectname: subject.name, level: level.name } });
         else
            navigate('/masteryassessment/create-manual', { state: { title, type, mode: 'create', card_bg: bg_image, subjectid: subject.value, subjectname: subject.name, level: level.name } });
      }

      else
         Modal.info({
            title: 'Mastery Assessment',
            content: 'Please select all criteria.',
            centered: true,
         });
   }

   const handleEditAssessment = (title, type) => {
      var isValid = masteryrec.title !== null && masteryrec.mastery_competency !== null && masteryrec.term !== null && masteryrec.level !== null && masteryrec.subject !== null;

      if (isValid) {
         let subject = MasterySubjects.find(x => x.value === masteryrec.subject);
         let level = MasteryGradeLevels.find(x => x.value === masteryrec.level);

         //-- Get card background based on subject and level
         // let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), level.name.toLowerCase());
         let bg_image = Utils.GetCardBackground(subject.name.toLowerCase(), type);

         if (questionType === '1')
            navigate('/masteryassessment/edit-pdf', { state: { title, type, mode: 'create', card_bg: bg_image, subjectid: subject.value, subjectname: subject.name, level: level.name } });
         else
            navigate('/masteryassessment/edit-manual', { state: { title, type, mode: 'create', card_bg: bg_image, subjectid: subject.value, subjectname: subject.name, level: level.name } });
      }

   }

   const handleRemoveAssessment = (type) => {
      var params = { key: type, value: null };
      dispatch(update_mastery_data(params));

      var params = { key: type + `_file_url`, value: null };
      dispatch(update_mastery_data(params));
   }

   const UploadStudyPlan = (title, type) => {
      const props = {
         beforeUpload: file => {
            const isPDF = file.type === 'application/pdf';
            if (!isPDF) {
               message.error(`Only PDF files can be uploaded`);
            }
            else
               handleUpload(file, type);
         },
      };

      var isValid = masteryrec.title !== null && masteryrec.mastery_competency !== null && masteryrec.term !== null && masteryrec.level !== null && masteryrec.subject !== null;

      if (isValid) {
         return (
            <Upload {...props} showUploadList={false} accept=".pdf">
               <Tooltip placement="top" title={title}>
                  <Button className='button-shadow'
                     size='small'
                     shape='round'
                     icon={<CloudUploadOutlined />}
                     type="primary"
                  >
                  </Button>
               </Tooltip>
            </Upload>
         );
      }
      else {
         return (
            <Tooltip placement="top" title={title}>
               <Button className='button-shadow'
                  size='small'
                  shape='round'
                  icon={<CloudUploadOutlined />}
                  type="primary"
               >
               </Button>
            </Tooltip>
         );
      }
   };

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file, type) => {
      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            // var params = {key: type, value : `${process.env.REACT_APP_API_S3_URL}/${data}`};
            var params = { key: type, value: `${data}` };
            dispatch(update_mastery_data(params));

            Modal.success({
               title: 'Mastery Assessment',
               content: 'File uploaded.',
               centered: true
            });

            setIsUploading(false);
         }

         // setTimeout(() => {
         //    message.success({ content: 'File upload complete!', key, duration: 2 });
         // }, 1000);
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'File upload failed.',
            centered: true
         });
         setIsUploading(false);
         // setTimeout(key, 100);
         // setTimeout(() => {
         //    message.error({ content: 'File upload failed!', key, duration: 2 });
         // }, 1000);
      });

      // const formData = new FormData();
      // formData.append('file', file);

      // Utils.getBase64(file, pdfb64 => {
      //    var params = { key: type, value: `${pdfb64}` };
      //    dispatch(update_mastery_data(params));
      // });
   }

   const handleRemoveStudyPlan = (type) => {
      var params = { key: type, value: null };
      dispatch(update_mastery_data(params));
   }

   const handlePreviewStudyPlan = (title, type) => {
      g_modal_title = title;

      switch (type) {
         case "study_plan_1":
            g_modal_study_plan = masteryrec.study_plan_1.includes('base64') ? masteryrec.study_plan_1 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_1}`;
            break;
         case "study_plan_2":
            g_modal_study_plan = masteryrec.study_plan_2.includes('base64') ? masteryrec.study_plan_2 : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_2}`;
            break;
         default:
            g_modal_study_plan = "";
      }

      setModalVisible(true);
   }

   const handleFormValuesChange = (changedValues) => {
      const formFieldName = Object.keys(changedValues)[0];

      if (formFieldName === "gradelevel") {
         // setMasteryGradeLevel(changedValues[formFieldName]);
         form.setFieldsValue({ subject: undefined });
      }
   };

   const handleOnFinish = () => {
      var mastery_data = {
         Id: masteryrec.id,
         Title: masteryrec.title,
         Term: masteryrec.term,
         Level: masteryrec.level,
         Subject: masteryrec.subject,
         Formative_1: masteryrec.formative_1,
         Formative_1_file_url: masteryrec.formative_1_file_url,
         Formative_2: masteryrec.formative_2,
         Formative_2_file_url: masteryrec.formative_2_file_url,
         Formative_3: masteryrec.formative_3,
         Formative_3_file_url: masteryrec.formative_3_file_url,
         Summative: masteryrec.summative,
         Summative_file_url: masteryrec.summative_file_url,
         Study_plan_1: masteryrec.study_plan_1,
         Study_plan_2: masteryrec.study_plan_2,
         Mastery_level: masteryrec.mastery_level,
         Mastery_competency: masteryrec.mastery_competency,
         Created_by: user_id,
         Mode: questionType,
         Formative_1_instruction: masteryrec.formative_1_instruction,
         Formative_2_instruction: masteryrec.formative_2_instruction,
         Formative_3_instruction: masteryrec.formative_3_instruction,
         // Recommendation_id: masteryrec.recommendation_id,
         // Recommendation_desc: masteryrec.recommendation_desc,
         // Recommendation_docs: masteryrec.recommendation_docs,
         // Recommendation_doc_names: masteryrec.recommendation_doc_names,
      }

      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/add`;
      axios.post(url, mastery_data).then((response) => {
         if (response.data) {
            const data = response.data;

            Modal.success({
               title: 'Mastery Assessment',
               content: 'Mastery assessment created successfully.',
               centered: true,
            });

            var params = { key: ``, value: '' };
            dispatch(reset_mastery_data(params));

            var searchKeys = {
               term: 0,
               level: 0,
               subject: masteryrec.subject,
               activetab: '1',
            }

            localStorage.setItem("mastery-assessment-search-keys", JSON.stringify(searchKeys));

            //--Get subject name
            let subject = MasterySubjects.find(x => x.value === masteryrec.subject);

            // navigate('/masteryassessment');
            // navigate('/masterysubjects');
            navigate(`/masteryassessmentsubject?id=${masteryrec.subject}&name=${subject.name}`);
         }
      }, (error) => {
         // setListLoading(false);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Mastery assessment creation failed.',
            centered: true
         });
         console.log(error.message);
      });
   }

   const handleOnFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Create</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>
                        <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Create</Breadcrumb.Item>
                  </Breadcrumb>

                  <div className='mastery_details_div'>
                     <Form
                        form={form}
                        layout="vertical"
                        className='DetailsForm'
                        colon={false}
                        onFinish={handleOnFinish}
                        onFinishFailed={handleOnFinishFailed}
                        onValuesChange={handleFormValuesChange}
                        autoComplete="off"
                     >
                        <Row gutter={12}>
                           <Col xs={24} xl={18}>
                              <Form.Item label="Competency Title" name="mastery_title"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Required'
                                    },
                                    { whitespace: true },
                                 ]}
                                 initialValue={masteryrec.title}
                              >
                                 <TextArea
                                    allowClear
                                    rows={5}
                                    style={{ color: '#000000', borderRadius: 7 }}
                                    onBlur={(event) => handleTitle(event.target.value)}
                                 />
                              </Form.Item>
                           </Col>
                           <Col xs={24} xl={6}>
                              <Row gutter={12}>
                                 <Col xs={24} xl={24}>
                                    <Form.Item label="Competency Code" name="competency"
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Required'
                                          },
                                       ]}
                                       initialValue={masteryrec.mastery_competency}
                                    >
                                       <Select
                                          showArrow
                                          showSearch
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Competency"
                                          onChange={(val) => handleCompetency(val)}
                                          value={masteryrec.mastery_competency}
                                       >
                                          {MasteryCompetency.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>
                                 <Col xs={24} xl={24}>
                                    <Form.Item label="Term" name="term"
                                       rules={[
                                          {
                                             required: true,
                                             message: 'Required'
                                          },
                                       ]}
                                       initialValue={masteryrec.term}
                                    >
                                       <Select
                                          showArrow
                                          size='default'
                                          style={{ width: '100%', textAlign: "left" }}
                                          allowClear
                                          placeholder="Select Term"
                                          onChange={(val) => handleTerm(val)}
                                          value={masteryrec.term}
                                       >
                                          {MasteryTerms.map(({ value, name }, idx) => {
                                             return <Option value={value}>{name}</Option>
                                          })}
                                       </Select>
                                    </Form.Item>
                                 </Col>
                              </Row>

                           </Col>
                        </Row>

                        <Row gutter={12}>
                           <Col xs={24} xl={10}>
                              <Form.Item label="Grade Level" name="gradelevel"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Required'
                                    },
                                 ]}
                                 initialValue={masteryrec.level}
                              >
                                 <Select
                                    showArrow
                                    size='default'
                                    style={{ width: '100%', textAlign: "left" }}
                                    allowClear
                                    placeholder="Select Level"
                                    onChange={(val) => handleGradeLevelChange(val)}
                                    value={masteryrec.level}
                                 >
                                    {MasteryGradeLevels.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Form.Item>
                           </Col>

                           <Col xs={24} xl={14}>
                              <Form.Item label="Subject" name="subject"
                                 rules={[
                                    {
                                       required: true,
                                       message: 'Required'
                                    },
                                 ]}
                                 initialValue={masteryrec.subject}
                              >
                                 <Select
                                    showArrow
                                    size='default'
                                    style={{ width: '100%', textAlign: "left" }}
                                    allowClear
                                    placeholder="Select Subject"
                                    onChange={(val) => handleSubject(val)}
                                    value={masteryrec.subject}
                                 >
                                    {MasterySubjects.map(({ value, name }, idx) => {
                                       return <Option value={value}>{name}</Option>
                                    })}
                                 </Select>
                              </Form.Item>
                           </Col>
                        </Row>

                        <Row gutter={12}>
                           <Col xs={24} xl={24}>
                              <Descriptions bordered size='small' column={4}>
                                 <Descriptions.Item label="Assessment Type" span={2} style={{ fontSize: 14, width: "25%", textAlign: "center" }}>
                                    Action
                                 </Descriptions.Item>
                                 <Descriptions.Item label="Study Plan" span={2} style={{ fontSize: 14, width: "25%", textAlign: "center" }}>
                                    Action
                                 </Descriptions.Item>

                                 <Descriptions.Item label={<Tag color={masteryrec.formative_1 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 1</Tag>} span={2} style={{ fontSize: 16, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_1 === null
                                          ?
                                          <Tooltip placement="top" title='Create Formative Level 1'>
                                             <Button className='button-shadow' shape='round' type='primary' size='small' icon={<PlusOutlined />}
                                                onClick={() => handleCreateAssessment('Formative Level 1', 'formative_1')}
                                             ></Button>
                                          </Tooltip>
                                          :
                                          <>
                                             <Tooltip placement="top" title='Edit Formative Level 1'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EditOutlined />}
                                                   onClick={() => handleEditAssessment('Formative Level 1', 'formative_1')}
                                                ></Button>
                                             </Tooltip>
                                             <Tooltip placement="top" title='Remove Formative Level 1'>
                                                <Popconfirm
                                                   title="Are you sure you want to clear this assessment?"
                                                   onConfirm={() => handleRemoveAssessment('formative_1')}
                                                   okText="Yes"
                                                   cancelText="No"
                                                >
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveAssessment('formative_1')}
                                                   ></Button>
                                                </Popconfirm>
                                             </Tooltip>
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<Tag color={masteryrec.study_plan_1 === null || masteryrec.study_plan_1 === '' ? "red" : "green"} style={{ fontSize: 13 }}>Study Plan 1</Tag>} span={2} style={{ fontSize: 13, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.study_plan_1 !== null && masteryrec.study_plan_1 !== ''
                                          ?
                                          <>
                                             <Popconfirm
                                                title="Are you sure you want to clear this study plan?"
                                                onConfirm={() => handleRemoveStudyPlan('study_plan_1')}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Tooltip placement="top" title='Remove Study Plan 1'>
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveStudyPlan('study_plan_1')}
                                                   ></Button>
                                                </Tooltip>
                                             </Popconfirm>
                                             <Tooltip placement="top" title='Preview Study Plan 1'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                   onClick={() => handlePreviewStudyPlan('Study Plan 1', 'study_plan_1')}
                                                ></Button>
                                             </Tooltip>
                                          </>
                                          :
                                          <>
                                             {UploadStudyPlan('Upload Study Plan 1', 'study_plan_1')}
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<Tag color={masteryrec.formative_2 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 2</Tag>} span={2} style={{ fontSize: 16, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_2 === null
                                          ?
                                          <Tooltip placement="top" title='Create Formative Level 2'>
                                             <Button className='button-shadow' shape='round' type='primary' size='small' icon={<PlusOutlined />}
                                                onClick={() => handleCreateAssessment('Formative Level 2', 'formative_2')}
                                             ></Button>
                                          </Tooltip>
                                          :
                                          <>
                                             <Tooltip placement="top" title='Edit Formative Level 2'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EditOutlined />}
                                                   onClick={() => handleEditAssessment('Formative Level 2', 'formative_2')}
                                                ></Button>
                                             </Tooltip>
                                             <Tooltip placement="top" title='Remove Formative Level 2'>
                                                <Popconfirm
                                                   title="Are you sure you want to clear this assessment?"
                                                   onConfirm={() => handleRemoveAssessment('formative_2')}
                                                   okText="Yes"
                                                   cancelText="No"
                                                >
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveAssessment('formative_2')}
                                                   ></Button>
                                                </Popconfirm>
                                             </Tooltip>
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<Tag color={masteryrec.study_plan_2 === null || masteryrec.study_plan_2 === '' ? "red" : "green"} style={{ fontSize: 13 }}>Study Plan 2</Tag>} span={2} style={{ fontSize: 13, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.study_plan_2 !== null && masteryrec.study_plan_2 !== ''
                                          ?
                                          <>
                                             <Popconfirm
                                                title="Are you sure you want to clear this study plan?"
                                                onConfirm={() => handleRemoveStudyPlan('study_plan_2')}
                                                okText="Yes"
                                                cancelText="No"
                                             >
                                                <Tooltip placement="top" title='Remove Study Plan 2'>
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveStudyPlan('study_plan_2')}
                                                   ></Button>
                                                </Tooltip>
                                             </Popconfirm>
                                             <Tooltip placement="top" title='Preview Study Plan 2'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EyeOutlined />}
                                                   onClick={() => handlePreviewStudyPlan('Study Plan 2', 'study_plan_2')}
                                                ></Button>
                                             </Tooltip>
                                          </>
                                          :
                                          <>
                                             {UploadStudyPlan('Upload Study Plan 2', 'study_plan_2')}
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<Tag color={masteryrec.formative_3 === null ? "red" : "green"} style={{ fontSize: 13 }}>Formative Level 3</Tag>} span={2} style={{ fontSize: 16, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.formative_3 === null
                                          ?
                                          <Tooltip placement="top" title='Create Formative Level 3'>
                                             <Button className='button-shadow' shape='round' type='primary' size='small' icon={<PlusOutlined />}
                                                onClick={() => handleCreateAssessment('Formative Level 3', 'formative_3')}
                                             ></Button>
                                          </Tooltip>
                                          :
                                          <>
                                             <Tooltip placement="top" title='Edit Formative Level 3'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EditOutlined />}
                                                   onClick={() => handleEditAssessment('Formative Level 3', 'formative_3')}
                                                ></Button>
                                             </Tooltip>
                                             <Tooltip placement="top" title='Remove Formative Level 3'>
                                                <Popconfirm
                                                   title="Are you sure you want to clear this assessment?"
                                                   onConfirm={() => handleRemoveAssessment('formative_3')}
                                                   okText="Yes"
                                                   cancelText="No"
                                                >
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveAssessment('formative_3')}
                                                   ></Button>
                                                </Popconfirm>
                                             </Tooltip>
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={<Tag color={masteryrec.summative === null ? "red" : "green"} style={{ fontSize: 13 }}>Summative</Tag>} span={2} style={{ fontSize: 16, width: "25%" }}>
                                    <Space direction='horizontal' size={"small"}>
                                       {masteryrec.summative === null
                                          ?
                                          <Tooltip placement="top" title='Create Summative'>
                                             <Button className='button-shadow' shape='round' type='primary' size='small' icon={<PlusOutlined />}
                                                onClick={() => handleCreateAssessment('Summative', 'summative')}
                                             ></Button>
                                          </Tooltip>
                                          :
                                          <>
                                             <Tooltip placement="top" title='Edit Formative Level 3'>
                                                <Button className='button-shadow' shape='round' type='primary' size='small' icon={<EditOutlined />}
                                                   onClick={() => handleEditAssessment('Summative', 'summative')}
                                                ></Button>
                                             </Tooltip>
                                             <Tooltip placement="top" title='Remove Summative'>
                                                <Popconfirm
                                                   title="Are you sure you want to clear this assessment?"
                                                   onConfirm={() => handleRemoveAssessment('summative')}
                                                   okText="Yes"
                                                   cancelText="No"
                                                >
                                                   <Button className='button-shadow' shape='round' type='primary' size='small' icon={<DeleteOutlined />}
                                                   // onClick={() => handleRemoveAssessment('summative')}
                                                   ></Button>
                                                </Popconfirm>
                                             </Tooltip>
                                          </>
                                       }
                                    </Space>
                                 </Descriptions.Item>
                              </Descriptions>

                           </Col>
                        </Row>

                        <Row gutter={12}>
                           <Col xs={24} xl={24}>
                              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                 <Form.Item >
                                    <Button className='button-shadow'
                                       shape='round'
                                       icon={<SaveOutlined />}
                                       style={{ marginLeft: "auto", marginRight: 10, marginTop: 10 }}
                                       type="primary"
                                       htmlType="submit"
                                    >
                                       Save
                                    </Button>
                                 </Form.Item>
                              </div>
                           </Col>
                        </Row>
                     </Form>
                  </div>
               </div>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}


         {/* Modals */}
         <Modal
            key='preivew'
            title={"Preview " + g_modal_title}
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            // width={window.innerWidth - 300}
            width='75%'

            onCancel={() => { setModalVisible(false); }}
            onOk={() => { setModalVisible(false); }}
         >
            <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
               <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                  <Viewer 
                     pageLayout={{transformSize: ({ size }) => ({
                        height: size.height + 30,
                        width: size.width + 30,
                     }),}}  
                     fileUrl={g_modal_study_plan}  
                     defaultScale={SpecialZoomLevel.PageWidth}   
                     initialPage={0} 
                  />
               </Worker>
            </div>
            {/* <BrowserView>
               <object data={g_modal_study_plan} width="100%" style={{ height: 'calc(100vh - 90px)' }}>
                  <p>Brainee LMS PDF - View<a href={g_modal_study_plan}> PDF!</a></p>
               </object>
            </BrowserView>
            <MobileView>
               <div style={{ width : "100%", height:"calc(100vh - 90px)" }}>
                  <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                     <Viewer 
                        pageLayout={{transformSize: ({ size }) => ({
                           height: size.height + 30,
                           width: size.width + 30,
                        }),}}  
                        fileUrl={g_modal_study_plan}  
                        defaultScale={SpecialZoomLevel.PageWidth}   
                        initialPage={0} 
                     />
                  </Worker>
               </div>
            </MobileView> */}
            {/* <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.study_plan_1}`} height={"calc(100vh - 208px)"} /> */}
         </Modal>

         {/* <Modal
         title={"Create Recommendation"}
         centered
         destroyOnClose={true}
         onCancel={() => { handleCancelCreateRecommendation(); }}
         open={createRecommendationModalVisible}
         // width={window.innerWidth - 400}
         width='70%'
         footer={null}
      >
         <Spin spinning={creating} tip="Creating recommendation, please wait...">
            <CreateRecommendations 
               onCancelCallback={handleCancelCreateRecommendation}
               onFinishCallback={handleOnFinishCreateRecommendation}
            />
         </Spin>
      </Modal> */}

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" style={{ minWidth: '30px', maxHeight: '100px' }} /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>


   );

}