import React, { useEffect } from 'react'
import TextArea from 'antd/lib/input/TextArea';
import axios from 'axios';
import { Button, Space, Modal, message, Upload, Tooltip, Typography, Image, Progress } from 'antd';
import { FontSizeOutlined, CloudUploadOutlined } from '@ant-design/icons';
// import { auto } from '@popperjs/core';
// import Utils from '../../../misc/Utils';

const { Text } = Typography;

export default function EssayItem(props) {
   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);
   const [answer, SetAnswer] = React.useState('');
   // const [value, setValue] = useState('');

   var subdomain = localStorage.getItem("lms-subdomain");

   useEffect(() => {
      if (Array.isArray(props.value)) {
         if (props.value[0].includes('base64')) {
            SetAnswer(props.value[0]);
         } else {
            if (props.value[0].includes("/questionaires/")) {
               SetAnswer(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.value[0]}`);
            } else {
               SetAnswer(props.value[0]);
            }
         }
      } else {
         if (props.value.includes("/questionaires/")) {
            SetAnswer(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.value}`);
         } else {
            SetAnswer(props.value);
         }
      }
   }, [props.value]);


   const handleChange = (value) => {
      SetAnswer(value);
   }

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total);
         setPercentage(p);
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file) => {
      // const key = 'updatable';
      // message.loading({ content: 'Uploading your answer...', key, duration: 0 });

      const formData = new FormData();
      formData.append('file', file);

      setPercentage(0);
      setIsUploading(true);

      // var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
      var url = "";
      if (props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;

      if (props.assessment_type === 'summative' || props.assessment_type === 'formative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_QUESTIONAIRE_PATH}`;

      axios.post(url, formData, upload_option).then(response => {
         console.log(response);
         if (response.data) {
            const data = response.data;

            handleChange(data);

            Modal.success({
               title: 'Assessment',
               content: 'File uploaded.',
               centered: true
            });
            setIsUploading(false);
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Assessment',
            content: 'File upload failed.',
            centered: true
         });
         setIsUploading(false);
      });

      // Utils.getBase64(file, imageb64 =>
      //    handleChange(imageb64),
      // );
   }

   const UploadFile = () => {
      const props = {
         beforeUpload: file => {
            const isPNG = file.type === 'image/png';
            const isJPG = file.type === 'image/jpeg';
            const isBMP = file.type === 'image/bmp';

            const fileValid = isPNG || isJPG || isBMP;

            if (!fileValid) {
               Modal.error({
                  // icon: <ExclamationCircleOutlined />,
                  title: 'Upload File',
                  content: 'You are not allowed to upload that file type.',
                  centered: true
               });
            } else {
               const allowedFileSize = 2;
               const isLtMB = file.size / 1024 / 1024 <= allowedFileSize;

               if (isLtMB) {
                  handleUpload(file);
               } else {
                  Modal.error({
                     title: 'Assessment',
                     content: `File should be less than ${allowedFileSize} MB`,
                     centered: true
                  });
               }
            }
         },
      };

      return (
         <Space direction='horizontal'>
            <Upload {...props} showUploadList={false} accept=".png,.jpg,.bmp" style={{ width: "100%", display: 'block !important' }}>
               <Tooltip placement="left" title='Upload answer in image format'>
                  <Button className='button-shadow' type="primary" size="middle" shape='round'>
                     <Text ellipsis style={{ color: "#fff" }}><CloudUploadOutlined />&nbsp;Upload My Answer</Text>
                  </Button>
               </Tooltip>
            </Upload>
            {
               (answer.includes("questionaires/") || answer.includes('base64')) &&
               <Button className='button-shadow' type="primary" size="middle" shape='round' onClick={() => SetAnswer('')}>
                  <Text ellipsis style={{ color: "#fff" }}><FontSizeOutlined />&nbsp;Type My Answer</Text>
               </Button>
            }
         </Space>
      );
   };

   const handleUpdateInput = () => {
      var trimmedAns = answer.substring(answer.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), answer.length);
      props.parentCallback("update-input", trimmedAns, props.id);
   }

   return (
      <div onBlur={handleUpdateInput}>
         <Space size={"small"} direction={"vertical"} style={{ width: "100%" }}>
            {
               answer.includes("questionaires/") || answer.includes('base64')
                  ?
                  <div className='essay_image_container' style={{borderRadius: '7px', border: '1px solid #ccc'}}>
                     <Image
                        style={{ maxHeight: '190px' }}
                        src={answer}
                        preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     />
                  </div>
                  :
                  <TextArea
                     allowClear={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     id={props.id}
                     name={props.id}
                     rows={8}
                     disabled={props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     // onBlur={(event) => handleChange(event.target.value)}
                     onChange={(event) => handleChange(event.target.value)}
                     value={props.mode.includes("view") || props.mode === "answer" ? answer : ''}
                     style={{ borderRadius: '7px' }}
                  />
            }
            {props.mode === "answer" ? UploadFile() : <></>}
         </Space>

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" width="28px" /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </div>
   )
}