/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import {
   Button, Select, Row, Col, Typography, Image, Space, Input, Tooltip, Table,
   Modal, Breadcrumb
} from 'antd';
import { useNavigate, Link } from 'react-router-dom';
import { SearchOutlined, EyeOutlined, ReloadOutlined } from '@ant-design/icons';
import axios from 'axios';
// import SimpleBarReact from "simplebar-react";
// import SideCalendar from '../../components/side-calendar/SideCalendar';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { SchoolTerms } from '../../constants';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Highlighter from 'react-highlight-words';
import './ReportCards.css';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

// var g_subdomain = "";
// var g_report_card_url = "";
var pdfFile = '';

var g_term_name = "";
var g_modal_title = "";
var g_report_card_url = "";
var g_subdomain = "";

const { Option } = Select;
const { Text } = Typography;

export function StudentReportCards() {
   const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [term, setTerm] = useState(null);
   const [fileURL, setFileURL] = useState(null);
   const [reportCard, setReportCard] = useState({
      allow: false,
      file_url: null,
   })
   const [rcaList, setRCAList] = useState([]);
   const [reportCardListLoading, setReportCardListLoading] = useState(false);
   const [modalVisible, setModalVisible] = useState(false);

   const [downloadStatus, setDownloadStatus] = React.useState(false);

   g_subdomain = localStorage.getItem("lms-subdomain");
   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();

      pdfFile = '';
      getReportCardsAwardsList();
   }, []);

   const getReportCardsAwardsList = () => {
      var url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/rcalist?student_id=${user_id}&type=reportcard`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            setRCAList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearchReportCards = () => {
      pdfFile = '';

      setReportCard({
         allow: 0,
         file_url: null,
         subdomain: null,
      });

      var url = `${process.env.REACT_APP_API_REPORT_CARD_UPLOAD}/reportcardinfo?student_id=${user_id}&term=${term}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;
            // setFileURL(data.file_url);
            setReportCard({
               allow: data.allow_viewing,
               file_url: data.file_url,
               subdomain: localStorage.getItem("lms-subdomain"),
            });
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const ReportCardsListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'report_card_upload_id',
         key: 'report_card_upload_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'section_id',
         key: 'section_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'level_id',
         key: 'level_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'student_id',
         key: 'student_id',
         hidden: true,
      },
      // {
      //    title: 'Last Name',
      //    key: 'last_name',
      //    dataIndex: 'last_name',
      //    sorter: (a, b) => { return a.title.localeCompare(b.last_name)},
      //    ...getColumnSearchProps('title'),
      //    ellipsis: {
      //       showTitle: false,
      //    },
      //    render: last_name => (
      //       <Tooltip placement="right" title={last_name}>
      //          <span style={{cursor:"default"}}>
      //             { last_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }
      //          </span>
      //       </Tooltip>
      //    ),
      //    width:'20%'
      // },
      // {
      //    title: 'First Name',
      //    key: 'first_name',
      //    dataIndex: 'first_name',
      //    sorter: (a, b) => { return a.title.localeCompare(b.first_name)},
      //    ...getColumnSearchProps('title'),
      //    ellipsis: {
      //       showTitle: false,
      //    },
      //    render: first_name => (
      //       <Tooltip placement="right" title={first_name}>
      //          <span style={{cursor:"default"}}>
      //             {/* {first_name} */}
      //             { first_name.toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) }
      //          </span>
      //       </Tooltip>
      //    ),
      //    width:'20%'
      // },
      {
         title: 'Type',
         key: 'term_name',
         dataIndex: 'term_name',
         sorter: (a, b) => { return a.term_name.localeCompare(b.term_name) },
         width: '50%'
      },
      {
         title: 'Viewing Date',
         key: 'viewing_schedule',
         dataIndex: 'viewing_schedule',
         sorter: (a, b) => { return a.viewing_schedule.localeCompare(b.viewing_schedule) },
         width: '25%'
      },
      // {
      //    title: 'Viewing',
      //    key: 'allow_viewing_desc',
      //    sorter: (a, b) => { return a.allow_viewing_desc.localeCompare(b.allow_viewing_desc)},
      //    render: payload => {
      //       return(
      //       <>
      //       {payload.allow_viewing_desc !== null 
      //       ?
      //       <Tag style={{ fontSize: 12  }} color={payload.allow_viewing === 0 ? 'red' : 'green'}>
      //             { payload.allow_viewing_desc }
      //       </Tag>
      //       :
      //       <></>
      //       }
      //       </>
      //       );
      //    },
      //    width:'17%'
      // },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return (
               <>
                  <Space size="small">

                     {payload.file_url !== null && payload.file_url !== "" &&
                        <>
                           <Tooltip placement="top" title={'View ' + payload.term_name}>
                              <EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                                 onClick={() => handlePreview((payload.first_name + ' ' + payload.last_name), payload.file_url, payload.term_name, payload.allow_viewing, payload.is_on_schedule)}
                              />
                           </Tooltip>
                        </>
                     }
                  </Space>
               </>
            );
         },
         width: '25%'
      },
   ].filter(item => !item.hidden);

   function sPDFFile() {
      pdfFile = g_report_card_url;
      return true;
   }

   const renderPreviewer = (document) => {
      if (document !== null && document !== '' && modalVisible) {
         var re = /(?:\.([^.]+))?$/;
         var ext = re.exec(document.toLowerCase());

         var subdomain = localStorage.getItem("lms-subdomain");
         var url = `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${document}`;

         switch (ext[1]) {
            case "pdf":
               return <>
                  <div style={{ width : "100%", height:"calc(100vh - 185px)" }}>
                     <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                        <Viewer 
                           pageLayout={{transformSize: ({ size }) => ({
                              height: size.height + 30,
                              width: size.width + 30,
                           }),}}  
                           fileUrl={url}  
                           defaultScale={SpecialZoomLevel.PageWidth}   
                           initialPage={0} 
                        />
                     </Worker>
                  </div>
                  {/* <RenderPDFReader url={url} height={"calc(100vh - 185px)"} /> */}
                  {/* <RenderPDFViewer url={url} height={"calc(100vh - 185px)"} load={pdfFile !== document ? sPDFFile() : false} showdownload={false} showprint={false} showpagecontrols={true} showfullscreen={true} /> */}
               </>

            case "png": case "jpg": case "jpeg":
               return <>
                  <div
                     className={'modal-document-viewer'}
                     style={{ height: "calc(100vh - 195px)" }}
                  >
                     <Image
                        src={url}
                        style={{ minHeight: "calc(100vh - 195px)", maxHeight: "calc(100vh - 195px)" }}
                     />
                  </div>
               </>
         }
      }
   }

   const handlePreview = (student_name, report_card_url, term_name, allow_viewing, is_on_schedule) => {
      if (allow_viewing && is_on_schedule) {
         g_term_name = term_name;
         g_modal_title = student_name;
         g_report_card_url = report_card_url;
         g_subdomain = localStorage.getItem("lms-subdomain");
         setModalVisible(true);
      }
      else {
         Modal.error({
            title: 'Report Card',
            content: 'Viewing of ' + term_name + ' is not yet allowed',
            centered: true
         });
      }
   }

   const _downloadFile = (url, name) => {
      setDownloadStatus(true);

      var re = new RegExp(' ', 'g');
      var file_name = name.replace(re, '_');
      var pos = url.lastIndexOf('.');
      var ext = url.slice(pos);

      fetch(url)
         .then(response => {
            response.blob().then(blob => {
               let url = window.URL.createObjectURL(blob);
               let a = document.createElement('a');
               a.href = url;
               a.download = file_name + ext;
               a.click();
               setDownloadStatus(false)
            });
         });
      /*setDownloadStatus(true)
         FileSaver.saveAs(currentS3ContentLink, downloadOfficeFileName)
      setDownloadStatus(false) */
   };

   const handleModalClose = () => {
      pdfFile = '';
      setModalVisible(false);
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Report Card & Certificates</a></li>
                     <li><span>/</span></li>
                     <li><a className="active">Report Card</a></li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        {/* <HomeOutlined /> */}
                        <Link to=   "/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Report Card & Certificates</Breadcrumb.Item>
                     <Breadcrumb.Item>Report Card</Breadcrumb.Item>
                  </Breadcrumb>
               </div>
            </div>
            {/* <Row gutter={24} style={{paddingBottom: "10px"}}>
               <Col>
               <Text italic style={{fontSize: "12px"}}>Instructions: To view students Report Card, Step 1 - Click Select Term, Step 2 - Click View Button</Text>
               </Col>
            </Row>
            <Row gutter={24} style={{paddingBottom: "20px"}}>
               <Col span={6}>
                  <Select 
                     showArrow 
                     size='middle' 
                     style={{width: '100%'}} 
                     allowClear 
                     placeholder="Select Term" 
                     onChange={(val) => setTerm(val)} 
                     value={term}
                  >
                     {SchoolTerms.map(({value, name}, idx) => { 
                        return <Option value={value}>{name}</Option>
                     })}
                  </Select>
               </Col>
               <Col span={5}>
                  <Button className='button-shadow' type='primary' shape='round' block onClick={() => handleSearchReportCards()} icon={<EyeOutlined />}>View</Button>
                  
               </Col>
            </Row> */}
            <Row justify='center'>
               <Col span={24} style={{ textAlign: "right" }}>
                  <Button className='button-shadow' type='primary' shape='round' onClick={() => getReportCardsAwardsList()} icon={<ReloadOutlined />}>Refresh List</Button>
               </Col>
            </Row>
            <Row gutter={12} style={{ overflow: "auto" }} >
               <Col span={24}>
                  <div className="dashboard-item-inner3">
                     <Table
                        rowKey={'uid'}
                        loading={reportCardListLoading}
                        size='small'
                        columns={ReportCardsListColumns}
                        dataSource={rcaList}
                        pagination={{ pageSize: 10, position: ['topRight', 'bottomRight'] }}
                        style={{ minWidth: '650px' }}
                     />
                  </div>
               </Col>
            </Row>

            {/* {
               reportCard.file_url !== null && reportCard.file_url !== ''
               ?
               <>
               {
                  reportCard.allow === 1
                  ?
                  <div className="report-card-container">
                     { renderPreviewer(reportCard.file_url) }
                  </div>
                  :
                  <div className='report-card-container_empty'>
                     <Empty description="To view Report Card please contact school business office or class adviser." style={{position:"relative", fontSize:"16px"}} />
                  </div>
               }
               </>
               :
               <div className='report-card-container_empty'>
                  <Empty description="Report Card not available." style={{position:"relative", fontSize:"16px"}} />
               </div>
            } */}

         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         <Modal
            title={"View " + g_term_name + " for " + g_modal_title}
            // okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            okButtonProps={{ shape: "round" }}
            centered
            destroyOnClose={true}
            open={modalVisible}
            // width={window.innerWidth - 300}
            width='75%'

            onCancel={() => { handleModalClose(); }}
            onOk={() => { handleModalClose(); }}
            footer={[
               <Row>
                  <Col span={12} style={{ textAlign: "left" }}>
                     {!g_term_name.includes("Report Card") &&
                        <Button shape='round'
                           type="primary"
                           loading={downloadStatus}
                           onClick={() => _downloadFile(`${process.env.REACT_APP_API_S3_URL}/${g_subdomain}/${g_report_card_url}`, g_term_name)}
                        >
                           Dowload
                        </Button>
                     }
                  </Col>
                  <Col span={12} style={{ textAlign: "right" }}>
                     <Button shape='round' type='primary' onClick={() => { handleModalClose(); }}>
                        Close
                     </Button>
                  </Col>
               </Row>,
            ]}
         >
            <div className='essay_list_container' style={{ background: "#fff" }}>
               {renderPreviewer(g_report_card_url)}
            </div>
         </Modal>
      </>
   );
}