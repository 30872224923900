import React, { useState } from 'react'
import {
   Space, Button, Card, InputNumber, Avatar, Popconfirm, Menu, Dropdown, Modal, Progress,
   Typography, Tooltip, Row, Col, message, Badge, Image, Upload, Divider, Descriptions
} from 'antd';
import axios from 'axios';
import { CloseCircleTwoTone, AlertTwoTone, InboxOutlined, PlusOutlined, RetweetOutlined, SoundOutlined, VideoCameraOutlined, PictureOutlined } from '@ant-design/icons';

// import { EditorState, convertToRaw } from 'draft-js';
// import { Editor } from 'react-draft-wysiwyg';
// import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import EssayItem from './question-items/Essay';
import MultipleAnswerItem from './question-items/MultipleAnswer';
import MultipleChoiceItem from './question-items/MultipleChoice';
import ShortAnswerItem from './question-items/ShortAnswer';
import MultipleChoiceImageItem from './question-items/MultipleChoiceImage';
import MultipleAnswerImageItem from './question-items/MultipleAnswerImage';
import MatchingType from './question-items/MatchingType';
import MatchingTypeImage from './question-items/MatchingTypeImage';

// import { Editor } from '@tinymce/tinymce-react';
import Utils from '../../misc/Utils';
import TextArea from 'antd/lib/input/TextArea';

import ReactPlayer from 'react-player';

import Wysiwyg from '../wysiwyg/wysiwyg';
import { isMobile } from 'react-device-detect';

const { Text, Title } = Typography;
const { Dragger } = Upload;
const alphabet = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
// "A1", "B1", "C1", "D1", "E1", "F1", "G1", "H1", "I1", "J1", "K1", "L1", "M1", "N1", "O1", "P1", "Q1", "R1", "S1", "T1", "U1", "V1", "W1", "X1", "Y1", "Z1"];

export default function BraineeQuestionCard(props) {
   const [optionValues, setOptionValues] = useState(props.option_labels !== undefined && props.option_labels !== '' ? props.option_labels : []);

   //--correctAnswers (this will be used to hold data from create/edit/answer)
   const [correctAnswers, setCorrectAnswers] = useState(props.correct !== undefined && props.correct !== '' ? props.correct : []);
   const [points, setPoints] = useState(props.points !== undefined && props.points !== '' ? props.points : '1');
   const [question, setQuestion] = useState(props.question !== undefined && props.question !== '' ? props.question : '');
   const [editorContent, setEditorContent] = useState(props.question !== undefined && props.question !== '' ? props.question : '');
   const [imageURL, setImageURL] = useState(props.image_url !== undefined && props.image_url !== '' ? props.image_url : []);
   const [studentAnswers, setStudenttAnswers] = useState(props.answers !== undefined && props.answers !== '' ? props.answers : []);

   // const [template, setTemplate] = useState(props.template !== undefined && props.template.length > 0 ? props.template : '1');
   // const [template, setTemplate] = useState(props.template);
   // const [questionImageURL, setQuestionImageURL] = useState(props.question_image !== undefined && props.question_image !== '' ? props.question_image : '');

   var subdomain = localStorage.getItem("lms-subdomain");

   const [questionImage, setQuestionImage] = useState(() => {
      // props.question_image !== '' && props.question_image !== undefined ? (props.question_image.includes('base64') ? props.question_image : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_image.substring(props.question_image.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_image.length)}`) : '')
      if (props.question_image !== undefined) {
         if (props.question_image.length > 0) {
            return `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_image.substring(props.question_image.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_image.length)}`;
         }
         else
            return '';
      }
      else
         return '';
   });
   const [questionAudio, setQuestionAudio] = useState(() => {
      if (props.question_audio !== undefined) {
         if (props.question_audio.length > 0) {
            return `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_audio.substring(props.question_audio.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_audio.length)}`;
         }
         else
            return '';
      }
      else
         return '';
   }
      // props.question_audio !== '' && props.question_audio !== undefined ? (props.question_audio.includes('base64') ? props.question_audio : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_audio.substring(props.question_audio.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_audio.length)}`) : '')
   );
   const [questionVideo, setQuestionVideo] = useState(() => {
      if (props.question_video !== undefined) {
         if (props.question_video.length > 0) {
            return `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_video.substring(props.question_video.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_video.length)}`;
         }
         else
            return '';
      }
      else
         return '';
   }
      // props.question_video !== '' && props.question_video !== undefined ? (props.question_video.includes('base64') ? props.question_video : `${process.env.REACT_APP_API_S3_URL}/${subdomain}/${props.question_video.substring(props.question_video.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), props.question_video.length)}`) : ''
   );

   // const cardBackgroundColor = props.matched !== undefined ? (props.matched === true ? "#DFFFDC" : "#FFDCDC") : "#F4FBFC";
   const enable_consider = props.enable_consider !== undefined ? props.enable_consider : true;

   const [percentage, setPercentage] = React.useState(0);
   const [isUploading, setIsUploading] = React.useState(false);

   // const [editorState, setEditorState] = useState(EditorState.createEmpty());

   // const subject = props.subject_name;
   // const cardbg = props.card_bg;

   const handleRequest = (method, value, idx) => {
      var ov = [...optionValues];
      var ca = [...correctAnswers];
      var img = [...imageURL];
      var pts = points;
      var qstn = question;
      // var qstn_img = questionImageURL;
      var qstn_img = questionImage !== '' ? questionImage.substring(questionImage.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), questionImage.length) : '';
      var qstn_aud = questionAudio !== '' ? questionAudio.substring(questionAudio.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), questionAudio.length) : '';
      var qstn_vid = questionVideo !== '' ? questionVideo.substring(questionVideo.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), questionVideo.length) : '';

      switch (method) {
         case "remove":
            ov.splice(idx, 1);
            ca.splice(idx, 1);
            if (img.length > 0) img.splice(idx, 1)
            break;
         case "update":
            ov[idx] = value;
            ca[idx] = '0';
            break;
         case "add":
            if (ov.length <= alphabet.length - 1) {
               ov.push(alphabet[ov.length]);
               ca.push('0');
               if (img.length > 0) img.push('');
            } else {
               message.info('Maximum number of answer keys reached.')
            }

            break;
         case "update-check":
            ca[idx] = value === true ? '1' : '0';
            break;
         case "update-check-radio":
            for (var i = 0; i < ca.length; i++) {
               if (i === idx)
                  ca[i] = '1';
               else
                  ca[i] = '0';
            };
            break;
         case "update-input":
            if (props.mode === "answer") {
               // ca = [];
               // ca.push(value);
               ca = value;
            } else {
               ca[idx] = value;
            }

            // console.log(value);
            break;
         case "update-points":
            pts = value === null ? 1 : value;
            break;
         case "update-question":
            setEditorContent(value);
            qstn = value;
            // console.log(value);
            break;
         case "update-image-url":
            img[idx] = value;
            break;
         case "add-correct":
            ca.push(value)
            break;
         case "update-question-image":
            qstn_img = value;
            break;
         case "update-question-audio":
            qstn_aud = value;
            break;
         case "update-question-video":
            qstn_vid = value;
            break;
         case "add-mt":
            if (ov.length <= alphabet.length - 1) {
               ov.push(['', '']);
               ca.push('');
               if (img.length > 0) img.push(['', '']);
            } else {
               message.info('Maximum number of answer keys reached.')
            }

            break;
         case "update-input-multi":
            ca[idx] = value;

            // console.log(value);
            break;
         case "update-col-a": //--Matching type
            //-- work around for matching type
            // if (idx === 0 && ov[idx] === '') {
            //    ov = [];
            //    ov.push(['', '']);
            // }

            ov[idx][0] = value;
            break;
         case "update-col-b": //--Matching type
            //-- work around for matching type image
            // if (idx === 0 && ov[idx] === '') {
            //    ov = [];
            //    ov.push(['', '']);
            // }

            ov[idx][1] = value;
            break;
         case "update-image-col-a": //--Matching type image
            img[idx][0] = value;
            break;
         case "update-image-col-b": //--Matching type image
            img[idx][1] = value;
            break;
         default:
            break;
      }

      if (props.mode === "answer") {
         props.answerCallback(ca, props.id); //-- Update student answers
      } else {
         var data = {};

         if (img.length > 0) {
            data = {
               correct: ca,
               option_labels: ov,
               points: pts,
               question: qstn,
               image_url: img,
               question_image: qstn_img,
            }
         } else {
            data = {
               correct: ca,
               option_labels: ov,
               points: pts,
               question: qstn,
               question_image: qstn_img,
               question_audio: qstn_aud,
               question_video: qstn_vid,
            }
         }

         props.parentCallback("update", "", data, props.id); //-- Update parent state answer keys data
      }

      setOptionValues(ov);
      setCorrectAnswers(ca);
      setPoints(pts);
      setQuestion(qstn);

      if (qstn_img !== null && qstn_img.length > 0) setQuestionImage(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${qstn_img}`);
      if (img.length > 0) setImageURL(img);
      if (qstn_aud !== null && qstn_aud.length > 0) setQuestionAudio(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${qstn_aud}`);
      if (qstn_vid !== null && qstn_vid.length > 0) setQuestionVideo(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${qstn_vid}`);
   }

   const handleCardRequest = (method, idx) => {
      props.parentCallback(method, "", "", idx);
   }

   const handleConsiderAnswer = (questionKeyId, answer_to_consider) => {
      props.considerAnswerCallback(questionKeyId, answer_to_consider);
   }

   const generateQuestionOptions = () => {
      // if (props.type !== "short_answer" && props.type !== "long_answer") {
      if (props.type !== "long_answer") {
         if (props.type === "short_answer") {
            if (props.mode === "answer" || props.mode.includes("-view")) {
               return (
                  <Row gutter={12} style={{ textAlign: 'center' }}>
                     <Col sm={24} md={24} lg={24} key={props.id} className="gutter-row">
                        <ShortAnswerItem
                           key={props.id}
                           id={props.id}
                           correct={props.mode.includes("-view") ? props.answers : props.correct}
                           mode={props.mode}
                           parentCallback={handleRequest}
                        />
                     </Col>
                  </Row>
               )
            } else {
               return correctAnswers.map((el, i) => (
                  <Row gutter={12} style={{ textAlign: 'center' }}>
                     <Col sm={24} md={24} lg={24} key={props.id + "_" + i} className="gutter-row">
                        <ShortAnswerItem
                           key={props.id + "_" + i}
                           id={i}
                           correct={correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                        />
                     </Col>
                  </Row>
               ))
            }
         } else if (props.type === "multiple_answer") {
            return optionValues.map((el, i) => (
               <MultipleAnswerItem
                  key={props.id + "_" + i}
                  id={i}
                  value={el}
                  correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                  mode={props.mode}
                  parentCallback={handleRequest}
               />
            ))
         } else if (props.type === "multiple_answer_image") {
            return (<>
               <Row gutter={12} style={{ textAlign: 'center' }}>
                  {optionValues.map((el, i) => (
                     <Col sm={24} md={props.question_type === "2" ? 6 : 12} lg={props.question_type === "2" ? 6 : 12} key={props.id + "_" + i} className="gutter-row">
                        <MultipleAnswerImageItem
                           key={props.id + "_" + i}
                           id={i}
                           value={el}
                           correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           image_url={props.image_url[i]}
                           assessment_type={props.assessment_type}
                        />
                     </Col>
                  ))}
               </Row>
            </>)
         } else if (props.type === "multiple_choice") {
            return optionValues.map((el, i) => (
               <MultipleChoiceItem
                  key={props.id + "_" + i}
                  cardid={props.id}
                  id={i}
                  value={el}
                  correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                  mode={props.mode}
                  parentCallback={handleRequest}
               />
            ))
         } else if (props.type === "multiple_choice_image") {
            return (<>
               <Row gutter={12} style={{ textAlign: 'center' }}>
                  {optionValues.map((el, i) => (
                     <Col sm={24} md={props.question_type === "2" ? 6 : 12} lg={props.question_type === "2" ? 6 : 12} key={props.id + "_" + i} className="gutter-row">
                        <MultipleChoiceImageItem
                           key={props.id + "_" + i}
                           cardid={props.id} id={i}
                           value={el}
                           correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           image_url={props.image_url[i]}
                           assessment_type={props.assessment_type}
                        />
                     </Col>
                  ))}
               </Row>
            </>)
         } else if (props.type === "matching_type") {
            return (
               <>
                  <Row width={'100%'} gutter={['12', '12']} style={{ display: 'flex', padding: '3px', alignItems: 'center', textAlign: 'center' }}>
                     <Col span={12} >Column A</Col>
                     <Col span={12} >Column B</Col>
                  </Row>
                  {
                     optionValues.map((el, i) => (
                        <MatchingType
                           key={props.id + "_" + i}
                           cardid={props.id}
                           id={i}
                           value={el}
                           correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           label={alphabet[i]}
                           matched={props.mode.includes("-view") ? studentAnswers[i].toLowerCase() === correctAnswers[i].toLowerCase() : false}
                        />
                     ))}
               </>
            )
         } else if (props.type === "matching_type_image") {
            return (
               <>
                  <Row width={'100%'} gutter={['12', '12']} style={{ display: 'flex', padding: '3px', alignItems: 'center', textAlign: 'center' }}>
                     <Col span={12} >Column A</Col>
                     <Col span={12} >Column B</Col>
                  </Row>
                  {
                     optionValues.map((el, i) => (
                        <MatchingTypeImage
                           key={props.id + "_" + i}
                           cardid={props.id}
                           id={i}
                           value={el}
                           correct={props.mode.includes("-view") ? studentAnswers[i] : correctAnswers[i]}
                           mode={props.mode}
                           parentCallback={handleRequest}
                           label={alphabet[i]}
                           image_url={props.image_url[i]}
                           matched={props.mode.includes("-view") ? studentAnswers[i].toLowerCase() === correctAnswers[i].toLowerCase() : false}
                           assessment_type={props.assessment_type}
                        />
                     ))
                  }
               </>
            )
         }

      } else {
         return <EssayItem
            key={props.id}
            id={props.id}
            mode={props.mode}
            value={props.mode.includes("view") && props.mode !== "preview" ? props.answers[0] : (props.mode === 'answer' ? props.correct[0] : '')}
            parentCallback={handleRequest}
            assessment_type={props.assessment_type}
         />
      }
   }

   function getCorrectAnswer(type, correct_answer_seq, option_labels) {
      var retVal = "";

      if (type !== "long_answer") {
         if (type === "short_answer")
            retVal = correct_answer_seq.join(' or ');
         else if (type.includes("multiple_answer")) {
            for (var i = 0; i < correct_answer_seq.length; i++) {
               if (correct_answer_seq[i] === "1") {
                  if (retVal !== "") retVal += " and ";
                  retVal += option_labels[i];
               }
            }
         } else if (type.includes('matching_type')) {
            retVal = correct_answer_seq.join(', ');
         } else {
            for (var i = 0; i < correct_answer_seq.length; i++) {
               if (correct_answer_seq[i] === "1")
                  retVal += option_labels[i];
            }
         }
      }

      return retVal === '' ? <span><AlertTwoTone twoToneColor={'red'} /><Text style={{ color: 'red', fontStyle: 'italic' }}>Correct answer was not set.</Text></span> : retVal;
   }

   // const handleChangeToBase64 = (file, command) => {
   //    setQuestionImage('');
   //    setQuestionAudio('');
   //    setQuestionVideo('');

   //    const formData = new FormData();
   //    formData.append('file', file);

   //    Utils.getBase64(file, base64 => {
   //       handleRequest(command, base64, props.id);

   //       if (command === 'update-question-image')
   //          setQuestionImage(base64);

   //       if (command === 'update-question-audio')
   //          setQuestionAudio(base64);

   //       if (command === 'update-question-video')
   //          setQuestionVideo(base64);
   //    });
   // };

   const beforeImageUpload = (file) => {
      var isValid = file.type === 'image/jpeg' || file.type === 'image/png';

      if (!isValid) {
         message.error('You can only upload JPG and PNG files!');
      }

      var isLt = file.size / 1024 / 1024 < 2;
      if (!isLt) {
         message.error('Image must smaller than 2MB!');
      }

      // handleChangeToBase64(file, 'update-question-image');
      if (isValid && isLt)
         handleUpload(file, 'update-question-image');
   }

   const beforeAudioUpload = (file) => {
      var isValid = file.type === 'audio/mpeg' || file.type === 'audio/wav';

      if (!isValid) {
         message.error('You can only upload MP3 and WAV files!');
      }

      var isLt = file.size / 1024 / 1024 < 5;
      if (!isLt) {
         message.error('Audio file must be smaller than 4MB!');
      }

      // handleChangeToBase64(file, 'update-question-audio');
      if (isValid && isLt)
         handleUpload(file, 'update-question-audio');
   }

   const beforeVideoUpload = (file) => {
      var isValid = file.type === 'video/mp4' || file.type === 'video/webm' || file.type === 'video/ogg' || file.type === 'video/x-ms-wmv';

      if (!isValid) {
         message.error('You can only upload MP4 and WEBM files!');
      }

      var isLt = file.size / 1024 / 1024 < 5;
      if (!isLt) {
         message.error('Video file must be smaller than 5MB!');
      }

      // handleChangeToBase64(file, 'update-question-video');
      if (isValid && isLt)
         handleUpload(file, 'update-question-video');
   }

   const upload_option = {
      onUploadProgress: (progressEvent) => {
         const { loaded, total } = progressEvent;
         let p = Math.floor((loaded * 100) / total)
         setPercentage(p)
         //console.log(`${percentage} %`)
      }
   }

   const handleUpload = (file, command) => {
      setIsUploading(true);

      const formData = new FormData();
      formData.append('file', file);

      // const url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadASSFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
      let url = '';

      if (props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;

      if (props.assessment_type === 'summative' || props.assessment_type === 'formative')
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMasteryFile?s3path=${process.env.REACT_APP_S3_ASSESSMENT_QUESTIONAIRE_PATH}`;


      axios.post(url, formData, upload_option).then(response => {
         console.log(response);

         if (response.data) {
            const data = response.data;

            handleRequest(command, data, props.id);

            if (command === 'update-question-image')
               setQuestionImage(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.substring(data.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), data.length)}`);
            // Utils.getBase64(file, imageb64 =>
            //    setQuestionImage(imageb64),
            // );


            if (command === 'update-question-audio')
               setQuestionAudio(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.substring(data.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), data.length)}`);
            // Utils.getBase64(file, audiob64 =>
            //    setQuestionAudio(audiob64),
            // );


            if (command === 'update-question-video')
               setQuestionVideo(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.substring(data.indexOf(props.assessment_type === 'mastery' || props.assessment_type === 'diagnostic' ? 'mastery-assessment' : 'assessment'), data.length)}`);
            // Utils.getBase64(file, videob64 =>
            //    setQuestionVideo(videob64),
            // );

            // handleRequest("update-question-image", data, props.id);
            setIsUploading(false);
         }
      }, (error) => {
         console.log(error.message);
         setIsUploading(false);
      });
   };

   const onEditorStateChange = () => {
      //
      setIsUploading(false);
   }

   const renderQuestionSection = (template) => {
      if (template === 1 || template === '1') {
         return (
            <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={6}>
               <Col span={24}>
                  {
                     <Wysiwyg
                        id={props.id}
                        changeContentCallback={(e) => handleRequest("update-question", e, props.id)}
                        content={editorContent}
                        height={300}
                        contentHeight={247}
                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     />
                  }
               </Col>
            </Row>
         )
      }

      if (template === 2 || template === '2') {
         return (
            <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={6}>
               <Col span={24}>
                  <Dragger
                     showUploadList={false}
                     style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                     beforeUpload={beforeImageUpload}
                     maxCount={1}
                     accept=".png,.jpg,.gif,.jpeg"
                     height={'300px'}
                     disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                  >
                     {
                        questionImage !== ''
                           ?
                           <div style={{
                              display: "flex",
                              justifyContent: "center",
                              // paddingTop: "10px",
                              // paddingBottom: "10px",
                              backgroundColor: '#FFF',
                              // border: '1px solid #ccc',
                              borderRadius: '7px',
                              // overflowY: 'auto',
                              height: '265px',
                           }}>
                              {/* <Image src={questionImage} preview={false} width={'100%'} /> */}
                              <Image src={questionImage} preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false} height={'100%'} />
                           </div>
                           :
                           <div style={{ padding: '20px' }}>
                              <p className="ant-upload-drag-icon">
                                 <PictureOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag image file to this area to add</p>
                           </div>
                     }
                  </Dragger>
               </Col>
            </Row>
         )
      }

      if (template === 3 || template === '3') {
         return (
            <>
               <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={[10, 10]}>
                  <Col sm={24} md={12} lg={12}>
                     {
                        <Wysiwyg
                           id={props.id}
                           changeContentCallback={(e) => handleRequest("update-question", e, props.id)}
                           content={editorContent}
                           height={300}
                           contentHeight={185}
                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        />
                     }

                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Dragger
                        showUploadList={false}
                        style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                        beforeUpload={beforeImageUpload}
                        maxCount={1}
                        accept=".png,.jpg,.gif,.jpeg"
                        height={'300px'}
                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     >
                        {
                           questionImage !== ''
                              ?
                              <div style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 // paddingTop: "10px",
                                 // paddingBottom: "10px",
                                 backgroundColor: '#FFF',
                                 // border: '1px solid #ccc',
                                 borderRadius: '7px',
                                 // overflowY: 'auto',
                                 height: '265px',
                              }}>
                                 {/* <Image src={questionImage} preview={false} width={'100%'} /> */}
                                 <Image src={questionImage} preview={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false} height={'100%'} />
                              </div>
                              :
                              <div style={{ padding: '20px' }}>
                                 <p className="ant-upload-drag-icon">
                                    <PictureOutlined />
                                 </p>
                                 <p className="ant-upload-text">Click or drag image file to this area to add</p>
                              </div>
                        }
                     </Dragger>
                  </Col>
               </Row>
            </>
         )
      }

      if (template === 4 || template === '4') {
         return (
            <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={6}>
               <Col span={24}>
                  <Dragger
                     showUploadList={false}
                     style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                     beforeUpload={beforeAudioUpload}
                     maxCount={1}
                     accept=".mp3,.wav"
                     height={'300px'}
                     disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                  >
                     {
                        questionAudio !== ''
                           ?
                           <div style={{
                              display: "flex",
                              justifyContent: "center",
                              // paddingTop: "10px",
                              // paddingBottom: "10px",
                              backgroundColor: '#FFF',
                              // border: '1px solid #ccc',
                              borderRadius: '7px',
                              // overflowY: 'auto',
                              height: '265px',
                           }}>
                              <ReactPlayer
                                 controls={true}
                                 width='100%'
                                 height={"100%"}
                                 url={questionAudio}
                              />
                           </div>
                           :
                           <div style={{ padding: '20px' }}>
                              <p className="ant-upload-drag-icon">
                                 <SoundOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag audio file to this area to add</p>
                           </div>
                     }
                  </Dragger>
               </Col>
            </Row>
         )
      }

      if (template === 5 || template === '5') {
         return (
            <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={6}>
               <Col span={24}>
                  <Dragger
                     showUploadList={false}
                     style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                     beforeUpload={beforeVideoUpload}
                     maxCount={1}
                     accept=".mp4,.webm,.ogv,.wmv"
                     height={'300px'}
                     disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                  >
                     {
                        questionVideo !== ''
                           ?
                           <div style={{
                              display: "flex",
                              justifyContent: "center",
                              // paddingTop: "10px",
                              // paddingBottom: "10px",
                              backgroundColor: '#FFF',
                              // border: '1px solid #ccc',
                              borderRadius: '7px',
                              // overflowY: 'auto',
                              height: '265px',
                           }}>
                              <ReactPlayer
                                 controls={true}
                                 width='100%'
                                 height={"100%"}
                                 url={questionVideo}
                              />
                           </div>
                           :
                           <div style={{ padding: '20px' }}>
                              <p className="ant-upload-drag-icon">
                                 <VideoCameraOutlined />
                              </p>
                              <p className="ant-upload-text">Click or drag video file to this area to add</p>
                           </div>
                     }
                  </Dragger>
               </Col>
            </Row>
         )
      }

      if (template === 6 || template === '6') {
         return (
            <>
               <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={[10, 10]}>
                  <Col sm={24} md={12} lg={12}>
                     {
                        <Wysiwyg
                           id={props.id}
                           changeContentCallback={(e) => handleRequest("update-question", e, props.id)}
                           content={editorContent}
                           height={300}
                           contentHeight={185}
                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        />
                     }

                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Dragger
                        showUploadList={false}
                        style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                        beforeUpload={beforeAudioUpload}
                        maxCount={1}
                        accept=".mp3,.wav"
                        height={'300px'}
                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     >
                        {
                           questionAudio !== ''
                              ?
                              <div style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 // paddingTop: "10px",
                                 // paddingBottom: "10px",
                                 backgroundColor: '#FFF',
                                 // border: '1px solid #ccc',
                                 borderRadius: '7px',
                                 // overflowY: 'auto',
                                 height: '265px',
                              }}>
                                 <ReactPlayer
                                    controls={true}
                                    width='100%'
                                    height={"100%"}
                                    url={questionAudio}
                                 />
                              </div>
                              :
                              <div style={{ padding: '20px' }}>
                                 <p className="ant-upload-drag-icon">
                                    <SoundOutlined />
                                 </p>
                                 <p className="ant-upload-text">Click or drag audio file to this area to add</p>
                              </div>
                        }
                     </Dragger>
                  </Col>
               </Row>
            </>
         )
      }

      if (template === 7 || template === '7') {
         return (
            <>
               <Row style={{ paddingBottom: 10, opacity: '90%' }} gutter={[10, 10]}>
                  <Col sm={24} md={12} lg={12}>
                     {
                        <Wysiwyg
                           id={props.id}
                           changeContentCallback={(e) => handleRequest("update-question", e, props.id)}
                           content={editorContent}
                           height={300}
                           contentHeight={185}
                           disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                        />
                     }

                  </Col>
                  <Col sm={24} md={12} lg={12}>
                     <Dragger
                        showUploadList={false}
                        style={{ backgroundColor: '#fff', borderRadius: '7px', padding: '0px', border: '1px solid #ccc' }}
                        beforeUpload={beforeVideoUpload}
                        maxCount={1}
                        accept=".mp4,.webm,.ogv,.wmv"
                        height={'300px'}
                        disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                     >
                        {
                           questionVideo !== ''
                              ?
                              <div style={{
                                 display: "flex",
                                 justifyContent: "center",
                                 // paddingTop: "10px",
                                 // paddingBottom: "10px",
                                 backgroundColor: '#FFF',
                                 // border: '1px solid #ccc',
                                 borderRadius: '7px',
                                 // overflowY: 'auto',
                                 height: '265px',
                              }}>
                                 <ReactPlayer
                                    controls={true}
                                    width='100%'
                                    height={"100%"}
                                    url={questionVideo}
                                 />
                              </div>
                              :
                              <div style={{ padding: '20px' }}>
                                 <p className="ant-upload-drag-icon">
                                    <VideoCameraOutlined />
                                 </p>
                                 <p className="ant-upload-text">Click or drag video file to this area to add</p>
                              </div>
                        }
                     </Dragger>
                  </Col>
               </Row>
            </>
         )
      }
   }

   const cardLabel = (card_type) => {
      var label = '';
      switch (card_type) {
         case 'short_answer':
            label = 'Short Answer';
            break;
         case 'long_answer':
            label = 'Open-ended/Essay';
            break;
         case 'multiple_choice_image':
         case 'multiple_choice':
            label = 'Multiple Choice';
            break;
         case 'multiple_answer_image':
         case 'multiple_answer':
            label = 'Multiple Answer';
            break;
         case 'matching_type_image':
         case 'matching_type':
            label = 'Matching Type';
            break;
         default:
            label = '';

      }

      return (
         <Text style={{ color: "#494949", fontWeight: "bold" }}>{label}</Text>
      )
   }

   const handleMenuClick = (e) => {
      props.templateReplaceCallback('replace', "", "", props.id, e.key);
   }

   const menu_question_template = (
      <Menu
         onClick={handleMenuClick}
         items={[
            {
               label: 'TEXT',
               key: '1',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'IMAGE',
               key: '2',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'TEXT & IMAGE',
               key: '3',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'AUDIO',
               key: '4',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'TEXT & AUDIO',
               key: '6',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'VIDEO',
               key: '5',
               // icon: <CheckCircleOutlined />,
            },
            {
               label: 'TEXT & VIDEO',
               key: '7',
               // icon: <CheckCircleOutlined />,
            },
         ]}
      />
   );

   const generateQuestionCard = () => {
      if (props.type === "section" || props.type === "instruction") {
         return (
            <Card
               className='card-shadow'
               key={props.id}
               title={<Text type="secondary" style={{ fontSize: 14, fontWeight: "bold" }}>{props.type.toUpperCase() + ' ' + props.index}</Text>}
               style={{ width: "95%", backgroundColor: "#F4FBFC", marginRight: "15px", marginBottom: '25px', marginLeft: '15px', minWidth: '536px', }}
               hoverable={true}
               extra={props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                  ?
                  <Space>
                     <Popconfirm
                        title="Remove this section?"
                        onConfirm={() => handleCardRequest("remove", props.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <CloseCircleTwoTone twoToneColor="#aaa" style={{ cursor: "pointer", fontSize: "24px" }} />
                     </Popconfirm>
                  </Space>
                  :
                  <></>
               }>

               {
                  <Wysiwyg
                     id={props.id}
                     changeContentCallback={(e) => handleRequest("update-question", e, props.id)}
                     content={props.question}
                     height={200}
                     contentHeight={125}
                     disabled={props.mode === "answer" || props.mode.includes("assign") || props.mode.includes("view") ? true : false}
                  />
               }
            </Card>
         )
      } else {
         return (
            <Card
               className='card-shadow'
               key={props.id}
               title={
                  <span>
                     <Space direction='horizontal' size="small">
                        {props.mode === "create" || props.mode === "edit"
                           ?
                           <>
                              <Tooltip placement="right" title='Click to duplicate this item'>
                                 <Avatar
                                    style={{ backgroundColor: '#4abec7', verticalAlign: 'middle', cursor: "pointer" }}
                                    size="middle"
                                    gap={2}
                                    onClick={() => handleCardRequest("duplicate", props.id)}
                                 >
                                    {props.index}
                                 </Avatar>
                              </Tooltip>
                              {
                                 cardLabel(props.type)
                              }
                              {
                                 !props.type.includes('matching_type') &&
                                 <InputNumber
                                    addonBefore="Points"
                                    min={1}
                                    max={100}
                                    style={{ width: "130px" }}
                                    value={props.points}
                                    onChange={(val) => handleRequest("update-points", val, props.id)}
                                    disabled={(props.mode === "answer" || props.mode.includes("assign")) && props.mode.includes("view") ? true : false}
                                 />
                              }
                           </>
                           :
                           <>
                              <Avatar
                                 style={{ backgroundColor: '#4abec7', verticalAlign: 'middle' }}
                                 size="middle"
                                 gap={2}
                                 onClick={() => handleCardRequest("duplicate", props.id)}
                              >
                                 {props.index}
                              </Avatar>
                              {
                                 cardLabel(props.type)
                              }
                           </>
                        }
                     </Space>
                  </span>
               }
               style={{
                  width: "96%",
                  marginBottom: '25px',
                  marginLeft: '15px',
                  marginRight: '15px',
                  minWidth: '536px',
                  backgroundImage: (`url(../images/${props.card_bg})`),
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: '100% 100%',
                  backgroundBlendMode: 'overlay',
                  backgroundColor: props.type !== 'long_answer' && props.type !== 'matching_type' ? (props.matched !== undefined ? (props.matched ? 'rgba(1, 170, 7, 0.5)' : 'rgba(170, 1, 7, 0.5)') : 'rgba(255, 255, 255, 0.3)') : 'rgba(255, 255, 255, 0.3)',
                  maxWidth: (props.type === 'short_answer' || props.type === 'long_answer' || props.type === 'multiple_choice' || props.type === 'multiple_answer')
                     && !(props.template === '3' || props.template === 3) && props.mode === "answer" ? '700px' : '1100px',
                  // maxWidth: isMobile ? '96%' : '1000px',
               }}
               hoverable={true}
               extra={props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                  ?
                  <Space>
                     {
                        props.question_type === "2" &&
                        <Tooltip placement="left" title='Change Template'>
                           <Dropdown overlay={menu_question_template} placement='bottomCenter' trigger={['click']} arrow>
                              <RetweetOutlined style={{ cursor: "pointer", fontSize: "24px", color: '#aaa' }} />
                           </Dropdown>
                        </Tooltip>
                     }
                     <Popconfirm
                        title="Remove this answer key?"
                        onConfirm={() => handleCardRequest("remove", props.id)}
                        okText="Yes"
                        cancelText="No"
                     >

                        <CloseCircleTwoTone twoToneColor="#aaa" style={{ cursor: "pointer", fontSize: "24px" }} />
                     </Popconfirm>
                  </Space>
                  :
                  props.mode === "teacher-view" && props.type === "short_answer" && props.matched === false && enable_consider
                     ?
                     <>
                        <Popconfirm
                           title="Are you sure you want to consider this answer?"
                           onConfirm={() => handleConsiderAnswer(props.question_key_id, props.answers)}
                           okText="Yes"
                           cancelText="No"
                        >
                           <Button
                              size='small'
                              className='button-shadow'
                              type='primary'
                              shape='round'
                           //onClick={() => handleConsiderAnswer(props.question_key_id, props.answers)}
                           >
                              Consider Answer
                           </Button>
                        </Popconfirm>

                     </>
                     :
                     <></>
               }
            >
               <div>
                  {
                     props.question_type === "2" //Manual encode
                     &&
                     <>
                        {renderQuestionSection(props.template)}
                     </>
                  }

                  {
                     props.type !== "long_answer" && props.question_type === "2"
                        ?
                        props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                           ?
                           <Divider orientation="center">
                              <Title level={5} style={{ textAlign: "left", color: "#000" }}>Answer Keys</Title>
                           </Divider>
                           :
                           <Divider orientation="center">
                              <Title level={5} style={{ textAlign: "left", color: "#000" }}>{props.mode.includes('teacher') ? 'Answer' : 'Your Answer'}</Title>
                           </Divider>
                        :
                        <></>
                  }

                  {
                     props.type !== "long_answer"
                        ?
                        <>
                           <div style={{
                              // backgroundColor: props.matched !== undefined ? (props.matched ? '#90d389' : '#d3898e') : '#D5F4F9',
                              backgroundColor: '#D5F4F9',
                              borderRadius: '7px',
                              opacity: '90%',
                              padding: '12px',
                              border: '1px solid #ccc',
                              zIndex: -1
                           }}>
                              {generateQuestionOptions()}

                           </div>
                           {
                              props.matched !== undefined
                                 ?
                                 (props.matched === false
                                    ?
                                    <>
                                       <Badge.Ribbon text="Correct Answer">
                                          <Card
                                             size="small"
                                             style={{ paddingLeft: "0px", paddingTop: "0px", paddingBottom: "0px", paddingRight: "70px", textAlign: "left", marginTop: '10px' }}
                                          >
                                             {getCorrectAnswer(props.type, props.correct, props.option_labels)}

                                          </Card>
                                       </Badge.Ribbon>
                                    </>
                                    :
                                    <></>)
                                 : <></>
                           }
                        </>
                        :
                        <>
                           {/* {
                              props.mode === 'tracker-view' &&
                              renderQuestionSection(props.template)
                           } */}
                           {generateQuestionOptions()}
                           {
                              ((props.mode === 'teacher-view' || props.mode === 'student-view') && props.mode !== 'tracker-view') &&
                              <Descriptions bordered size='small' column={4} style={{ marginTop: '10px' }} >
                                 <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                    <InputNumber
                                       id={'essayinput' + props.id}
                                       disabled
                                       size='middle'
                                       style={{ width: "100%", borderRadius: "7px" }}
                                       defaultValue={props.essay_score}
                                    />
                                 </Descriptions.Item>
                                 <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                    <TextArea
                                       disabled
                                       id={'essaytext' + props.id}
                                       name={'essaytext' + props.id}
                                       rows={5}
                                       defaultValue={props.essay_comment}
                                    />
                                 </Descriptions.Item>
                              </Descriptions>
                           }
                        </>
                  }
                  {
                     props.type !== "long_answer"
                        ?
                        props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view")
                           ?
                           props.type === "short_answer"
                              ?
                              <div style={{ display: 'flex', justifyContent: 'center' }}>
                                 <Button className='button-shadow' id={props.id} type='primary' shape='round' onClick={() => handleRequest("add-correct", "", -1)} style={{ marginTop: '10px' }}><PlusOutlined />  Add Correct Answer</Button>
                              </div>
                              :
                              (
                                 props.type.includes('matching_type')
                                    ?
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                       <Button className='button-shadow' id={props.id} type='primary' shape='round' onClick={() => handleRequest("add-mt", "", -1)} style={{ marginTop: '10px' }}><PlusOutlined /> Add Answer Key</Button>
                                    </div>
                                    :
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                       <Button className='button-shadow' id={props.id} type='primary' shape='round' onClick={() => handleRequest("add", "", -1)} style={{ marginTop: '10px' }}><PlusOutlined /> Add Answer Key</Button>
                                    </div>
                              )
                           :
                           <></>
                        :
                        <></>
                  }
               </div>
               {/* </Space> */}
            </Card>
         )
      }
   }

   return (
      <>
         {generateQuestionCard()}

         <Modal
            key="modal-upload"
            title={<div><Image src='../images/brainee_mascot.png' preview={false} height="30px" /> Upload File</div>}
            destroyOnClose={true}
            centered
            open={isUploading}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <span>Uploading...</span>
            <Progress
               percent={percentage}
               status="active"
               strokeColor={{
                  '0%': '#5A98D2',
                  '100%': '#70D0D4',
               }}
            />
         </Modal>
      </>
   )
}