/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import {
   Button, Descriptions, message, Typography, Spin,
   Space, Breadcrumb
} from 'antd';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Split from 'react-split';
import moment from 'moment';
import { useSelector } from 'react-redux';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import * as AntDIcons from '@ant-design/icons';

const { Text, Paragraph } = Typography;
// const { Option } = Select;
// const { TextArea } = Input;

let ansIdx = 0;
let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let matched = false;
let pdfFile = '';
let essayIdx = -1;

export function MasteryTeacherViewManual(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const origin = location.state.origin;
   const masteryrec = useSelector(state => state.masteryrec);

   const assignInfo = location.state.assignInfo;
   const assignment_id = assignInfo.id;
   const assessmentType = location.state.assessment_type;
   const assessmentId = location.state.assessment_id;
   const studentId = location.state.student_id;
   const studentName = location.state.student_name;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState([]);
   const [questionSheetPDF, setQuestionSheetPDF] = useState("");

   const subdomain = localStorage.getItem("lms-subdomain");
   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Essay_score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      pdfFile = '';

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
      essayIdx = -1

      switch (assessmentType) {
         case "formative_1":
            setAnswerSheetData(JSON.parse(masteryrec.formative_1));

            if (masteryrec.formative_1_file_url !== null) {
               if ((masteryrec.formative_1_file_url).includes('base64'))
                  setQuestionSheetPDF(`${masteryrec.formative_1_file_url}`);
               else
                  setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_1_file_url}`);
            }

            break;
         case "formative_2":
            setAnswerSheetData(JSON.parse(masteryrec.formative_2));

            if (masteryrec.formative_2_file_url !== null) {
               if ((masteryrec.formative_2_file_url).includes('base64'))
                  setQuestionSheetPDF(`${masteryrec.formative_2_file_url}`);
               else
                  setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_2_file_url}`);
            }

            break;
         case "formative_3":
            setAnswerSheetData(JSON.parse(masteryrec.formative_3));

            if (masteryrec.formative_3_file_url !== null) {
               if ((masteryrec.formative_3_file_url).includes('base64'))
                  setQuestionSheetPDF(`${masteryrec.formative_3_file_url}`);
               else
                  setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_3_file_url}`);
            }

            break;
         case "summative":
            setAnswerSheetData(JSON.parse(masteryrec.summative));

            if (masteryrec.summative_file_url !== null) {
               if ((masteryrec.summative_file_url).includes('base64'))
                  setQuestionSheetPDF(`${masteryrec.summative_file_url}`);
               else
                  setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.summative_file_url}`);
            }

            break;
         default:
            setAnswerSheetData("");
            setQuestionSheetPDF("");
      }

      // getAssessmentInfo(assessmentId);
      getAnswerInfo(assessmentId, assignInfo.id, studentId, assessmentType);
   }, [location.state.assessment_id]);

   // const getAssessmentInfo = (id) => {
   //    ansIdx = 0;

   //    axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;
   //          setAssInfo({
   //             id: data.id,
   //             title: data.title,
   //             term: data.term,
   //             level: data.level,
   //             subject: data.subject,
   //             formative_1: data.formative_1,
   //             formative_1_file_url: data.formative_1_file_url,
   //             formative_2: data.formative_2,
   //             formative_2_file_url: data.formative_2_file_url,
   //             formative_3: data.formative_3,
   //             formative_3_file_url: data.formative_3_file_url,
   //             summative: data.summative,
   //             summative_file_url: data.summative_file_url,
   //             study_plan_1: data.study_plan_1,
   //             study_plan_2: data.study_plan_2,
   //             mastery_level: data.mastery_level,
   //             mastery_competency: data.competency,
   //             created_by: data.created_by,
   //          });

   //          getAnswerInfo(assessmentId, assignInfo.id, studentId, assessmentType);
   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const getAnswerInfo = (assessment_id, assignment_id, student_id, assessment_type) => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/answerinfo?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&assessment_type=${assessment_type}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var answer_info = {
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score + data.essay_score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_score_multi: data.essay_score_multi !== "" && data.essay_score_multi !== null ? JSON.parse(data.essay_score_multi) : [],
               Essay_multi_comments: data.essay_multi_comments !== "" && data.essay_multi_comments !== null ? JSON.parse(data.essay_multi_comments) : [],
               Essay_score: data.essay_score,
            };

            setAnswerInfo(answer_info);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleConsiderAnswer = (question_key_id, answer_to_consider) => {
      const key = 'updatable';
      message.loading("Updating correct answers...", key);

      var data = {
         Assessment_id: assessmentId,
         Question_id: question_key_id,
         Answer_to_consider: answer_to_consider[0],
         Assignment_id: assignInfo.id,
         Student_id: studentId
      }

      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/considerformativeanswer`, data).then((response) => {
         console.log(response);

         //if (response.data) {
         const data = response.data;
         message.success({ content: "Update complete!", key, duration: 1 });
         // getAssessmentInfo(assessmentId);
         getAnswerInfo(assessmentId, assignInfo.id, studentId, assessmentType);
         //}
      }, (error) => {
         console.log(error.message);
      });
   }

   function sPDFFile() {
      pdfFile = questionSheetPDF;
      return true;
   }

   // const BackToTracker = () => {
   //    // navigate('/masteryassessment/tracker',{replace: true, state: {assign_id: assignment_id, assessment_id: assessmentId, origin: '2'}})
   //    navigate(-1);
   // }

   // function GoBack() {
   //    // window.history.pushState();
   //    // window.history.back();
   //    window.history.go(-1);
   // }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     {/* <ul>
                        <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">Brainee LMS</li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subjects</a></li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subject}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a></li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate(`/masteryassessment/tracker?assign_id=${assignment_id}&assessment_id=${assessmentId}&origin=${origin}&subject=${location.state.subject}&subjectname=${location.state.subjectname}`)}>Tracker</a></li>
                        <li><span>/</span></li>
                        <li className="active">View Answer</li>
                     </ul> */}
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate('/masterysubjects')}>Mastery Subject</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessmentsubject?id=${location.state.subject}&name=${location.state.subjectname}`)}>Mastery Assessment ({location.state.subjectname})</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/masteryassessment/tracker?assign_id=${assignment_id}&assessment_id=${assessmentId}&origin=${origin}&subject=${location.state.subject}&subjectname=${location.state.subjectname}`)}>Tracker</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>View Answer</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>
                  {answerInfo.Answers.length > 0
                     ?
                     <div className='answer-sheet-content2'>
                        <SimpleBarReact>
                           <ul className="questions">
                              {answerSheetData.map(({ id, type, points, correct, option_labels, question, image_url, template, question_image, question_audio, question_video }, idx) => {

                                 if (type === "section") {
                                    qcardIdx = 1;
                                    idxCol.push(sectionIdx);
                                    sectionIdx++;
                                 } else {
                                    idxCol.push(qcardIdx);
                                    qcardIdx++;
                                 }

                                 //-- Check if answer and correct are matched 
                                 var matched = false;
                                 var cntMatched = 0;

                                 if (type !== "long_answer" && type !== "section") {
                                    if (type === "short_answer") {
                                       for (var i = 0; i < correct.length; i++) {
                                          //-- Remove all white spaces before comparing
                                          var tmp_correct = correct[i].replace(/\s/g, '');
                                          var tmp_answer = answerInfo.Answers[idx].answer[0].replace(/\s/g, '');

                                          // if (correct[i].toLowerCase().trim() === answerInfo.Answers[idx].answer[0].toLowerCase().trim()) {
                                          if (tmp_correct.toLowerCase() === tmp_answer.toLowerCase()) {
                                             matched = true;
                                             break;
                                          }
                                       }
                                    }
                                    else if (type === 'matching_type') {
                                       for (var i = 0; i < correct.length; i++) {
                                          if (correct[i].toLowerCase() === answerInfo.Answers[idx].answer[i].toLowerCase()) {
                                             cntMatched++;
                                          }
                                       }

                                       if (cntMatched === correct.length)
                                          matched = true;
                                    }
                                    else {
                                       for (var i = 0; i < correct.length; i++) {
                                          if (correct[i] === answerInfo.Answers[idx].answer[i]) {
                                             cntMatched++;
                                          }
                                       }

                                       if (cntMatched === correct.length)
                                          matched = true;
                                    }
                                 }

                                 if (type === "long_answer")
                                    essayIdx++;

                                 return (
                                    <li>
                                       <BraineeQuestionCard
                                          key={id}
                                          question_key_id={id}
                                          index={idxCol[idx]}
                                          id={idx}
                                          type={type}
                                          points={points}
                                          correct={correct}
                                          answers={answerInfo.Answers[idx].answer}
                                          option_labels={option_labels}
                                          question={question}
                                          image_url={image_url}
                                          question_type={'2'}
                                          mode={'teacher-view'}
                                          matched={matched}
                                          considerAnswerCallback={handleConsiderAnswer}
                                          enable_consider={false}
                                          card_bg={location.state.card_bg}
                                          template={template}
                                          question_image={question_image}
                                          question_audio={question_audio}
                                          question_video={question_video}
                                          subject_name={location.state.subject}
                                          essay_score={answerInfo.Essay_score_multi[essayIdx]}
                                          essay_comment={answerInfo.Essay_multi_comments[essayIdx]}
                                          assessment_type={'mastery'}
                                       />

                                       {/* {
                                          type === "long_answer" &&
                                          <>
                                             <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px", marginLeft: '55px', marginRight: '55px' }}>
                                                <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                   <InputNumber
                                                      id={essayIdx}
                                                      disabled
                                                      size='middle'
                                                      // max={points} 
                                                      // min={0} 
                                                      style={{ width: "100%", borderRadius: "7px" }}
                                                      defaultValue={answerInfo.Essay_score_multi[essayIdx]}
                                                   />
                                                </Descriptions.Item>
                                                <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                   <TextArea
                                                      // allowClear
                                                      disabled
                                                      id={essayIdx}
                                                      name={essayIdx}
                                                      rows={5}
                                                      defaultValue={answerInfo.Essay_multi_comments[essayIdx]}
                                                   />
                                                </Descriptions.Item>
                                             </Descriptions>
                                          </>
                                       } */}
                                    </li>
                                 );
                              })}
                           </ul>
                        </SimpleBarReact>
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>

               <div className="notice-item">
                  <>
                     {answerInfo.Answers.length > 0
                        ?
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                 <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{studentName}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Mastery Assessment Type"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{Utils.GetTypeLabel(assessmentType)}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{masteryrec.title}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Score"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{(answerInfo.Score + answerInfo.Essay_score) + " of " + answerInfo.Total_points}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Duration"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{Math.floor((Math.abs(new Date(answerInfo.End_date) - new Date(answerInfo.Start_date)) / 1000 / 60))} min(s)</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Legend"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}>
                                       <Space wrap>
                                          <Button size='middle' shape="circle" style={{ backgroundColor: "rgba(1, 170, 7, 0.5)" }}>
                                             {" "}
                                          </Button>
                                          Correct Answer

                                          <Button size='middle' shape="circle" style={{ backgroundColor: "rgba(170, 1, 7, 0.5)" }}>
                                             {" "}
                                          </Button>
                                          Incorrect Answer
                                       </Space>
                                    </Paragraph>
                                 </Descriptions.Item>
                              </Descriptions>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </>
               </div>
            </Split>
         </div>
      </>
   )
}
