import React, { useEffect, useRef, useState } from 'react'
// import { Configuration, OpenAIApi } from 'openai';
import "./chatGPT.scss"
import { CopyOutlined, SendOutlined, DownloadOutlined } from '@ant-design/icons';

import { Button, message, Image, Input, Avatar, Tooltip, Row, Col, Form, InputNumber, Select, Segmented, List } from 'antd';
// import { setMinutes } from 'date-fns';
import SimpleBarReact from "simplebar-react";
import { Configuration, OpenAIApi } from 'openai';
import { OpenAIExt } from 'openai-ext';
import Iframe from 'react-iframe';
import ReactTextFormat from 'react-text-format';
import html2pdf from 'html2pdf.js';

export function ChatGPT2(props) {
   const [form] = Form.useForm();

   const { TextArea } = Input;
   const { Option } = Select;

   const [storedValues, setStoredValues] = useState([]);
   const [newQuestion, setNewQuestion] = useState('');
   const [botSearching, setBotSearching] = useState(false);
   const [chatMessages, setMessages] = useState([{ "role": "system", "content": "You are a helpful assistant." },]);
   const [isTyping, setIsTyping] = useState(false);

   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
   });

   const openai = new OpenAIApi(configuration);

   const messagesEndRef = useRef(null);

   const [systemPrompt, setSystemPrompt] = React.useState('You are a helpful assistant.');
   const [userPrompt, setUserPrompt] = React.useState('');
   const [completion, setCompletion] = React.useState('');
   const [xhr, setXhr] = React.useState(null);

   const [shouldRun, setShouldRun] = React.useState(false);
   const [running, setRunning] = React.useState(false);

   const [subject, setSubject] = useState('araling panlipunan');
   const [topic, setTopic] = useState(null);
   const [noi, setNOI] = useState(1);
   const [toq, setTOQ] = useState(['short answer']);
   const [lod, setLOD] = useState('easy');
   const [language, setLanguage] = useState('english');

   const [gradeLevel, setGradeLevel] = useState('kinder');
   // const [depEdCode, setDepEdCode] = useState(null);
   const [lessonTitle, setLessonTitle] = useState(null);
   const [lessonDescription, setLessonDescription] = useState(null);

   const [aiType, setAiType] = useState('lesson');
   const [otherResouce, setOtherResouce] = useState(null);
   const [selectedDocumentIdx, setSelectedDocumentIdx] = useState(-1);

   const otherResourceList = [
      {
         title: 'YOU.com',
         link: 'https://you.com',
         container: 'new window'
      },
      {
         title: 'to teach_',
         link: 'https://www.to-teach.ai/',
         container: 'iframe'
      },
      {
         title: 'Talk to Books',
         link: 'https://books.google.com/talktobooks/',
         container: 'new window'
      },
      {
         title: 'TinyWow',
         link: 'https://tinywow.com/',
         container: 'new window'
      },
   ]

   // function linkify(text) {
   //    var urlRegex = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/ig;
   //    return text.replace(urlRegex, function (url) {
   //       return '<a href="' + url + '" target="_blank">' + url + '</a>';
   //    });
   // }

   const copyText = (text) => {
      navigator.clipboard.writeText(text);
      const key = 'updatable';
      message.info({ content: 'Copied to clipboard!', key });
   };



   const handleDownloadResult = () => {
      var element = document.getElementById('result-gpt');

      if (element !== null) {
         var reportFileName = 'BraineeAI-Result.pdf';

         var opt = {
            margin: 0.5,
            filename: reportFileName,
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
         };

         // New Promise-based usage:
         html2pdf().from(element).set(opt).save();
      }
   }

   const generateBotResponse = async () => {
      if (newQuestion !== '' && newQuestion !== '\r' && newQuestion !== '\n') {
         setBotSearching(true);
         var tmpMessage = [...chatMessages];
         tmpMessage.push({ "role": "user", "content": newQuestion });
         setMessages(tmpMessage);

         try {
            var tmpStoreMessage = [...storedValues];
            tmpStoreMessage.push({
               type: "question",
               message: newQuestion
            })
            setStoredValues(tmpStoreMessage);
            setNewQuestion('');
            // Scroll down to the bottom
            setIsTyping(true);
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });

            const response = await openai.createChatCompletion({
               // model: "gpt-3.5-turbo-0125",
               model: "gpt-4",
               max_tokens: 1500,
               temperature: 0.5,
               // top_p: 1,
               messages: tmpMessage,
               n: 1,
               stream: false,
            });
            setIsTyping(false);

            if (response.data.choices) {
               tmpStoreMessage.push({
                  type: "answer",
                  message: response.data.choices[0].message?.content.trim()
               })
               setStoredValues(tmpStoreMessage);
               tmpMessage.push({ "role": "assistant", "content": response.data.choices[0].message?.content });
               setMessages(tmpMessage);
               // Scroll down to the bottom
               messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
            }
         }
         catch (ex) {
            setIsTyping(false);
            message.warning("Error connecting to the server. Please try again.");
            //console.log(ex);
         }

         setBotSearching(false)
      } else
         setNewQuestion('');
   };

   const AI_Greeting = () => {
      var greeting_msg = "Greetings! I am Brainee BOT your personal AI assistant. I can help you search the resources you need for your lesson or assessment or even for homeworks. Just ask me anything and I will be glad to help.";

      var tmpStoreMessage = [...storedValues];
      tmpStoreMessage.push({
         type: "answer",
         message: greeting_msg,
      });
      setStoredValues(tmpStoreMessage);
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
   }

   useEffect(() => {
      AI_Greeting();
   }, []);

   useEffect(() => {
      messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
   }, [isTyping]);

   useEffect(() => {
      // AI_Greeting();

      function BuildUserPrompt(type) {
         // let retVal = 'generate a formative assessment based on Philippine DepEd curriculum with the following parameters \n';

         let retVal = '';
         if (aiType === 'assessment') {
            let type_of_questions = toq.join();

            retVal = 'Generate an assessment for ' + gradeLevel + ' with the following parameters \n';
            retVal += 'Subject: ' + subject + ' \n';
            retVal += "Grade Level: " + gradeLevel + "\n";
            retVal += 'Topic: ' + topic + ' \n';
            retVal += 'Number of questions: ' + noi + ' \n';
            retVal += 'Type of question: ' + type_of_questions + '\n';
            retVal += 'Difficulty: ' + lod + ' \n';
            retVal += 'Translate the language to: ' + language + ' \n';
            retVal += 'Show the subject and topic at the beginning and add icon that represents the topic. Provide the question, correct answer, and explanation in order. \n';
            retVal += 'Add greetings for the teacher at the end with heart and smiley';
            // retVal += 'provide the question, correct answer, and explanation in order then give recommendations for the students with less then 50% correct answers';
         } else if (aiType === 'lesson') {
            retVal = 'Generate a lesson package based on Philippines DepEd curriculum with the following parameters \n';
            // retVal = 'Generate a learning plan based on Philippines DepEd curriculum BOPPPS with the following parameters \n';
            // retVal = 'Generate a learning plan based on the following parameters \n';

            // retVal += 'Subject: ' + subject + ' \n';
            // retVal += 'Grade Level: ' + gradeLevel + ' \n';
            // retVal += 'Objective: ' + objective + ' \n';
            // retVal += 'Translation result to: ' + language + ' \n';
            // retVal += 'Add greetings for the teacher at the end with heart and smiley';

            // retVal += 'Generate a lesson plan on ' + objective + ' based on bloom’s taxonomy that is applicable to teach for grade ' + gradeLevel + ' students. ';
            // retVal += 'Use the following parameters: ';
            // retVal += 'Lesson Title, ';
            // retVal += 'Topic, ';
            // retVal += 'Subject, ';
            // retVal += 'Grade Level,';
            // retVal += 'Term 2, ';
            // retVal += 'Competency, ';
            // retVal += 'Objective (content standard, performance standard), ';
            // retVal += 'Description using Brainee LMS (5E’s duration), ';
            // retVal += 'Activity, ';
            // retVal += 'Reflection, ';
            // retVal += 'and Assignment ';
            // retVal += 'and use ' + language + ' as the language.';

            // retVal += 'Generate a lesson plan on CELLS based on bloom’s taxonomy that is applicable to teach for grade 12 students. Use the following parameters lesson title, topic, subject, grade level, term 2, competency, objective (content standard, performance standard), description using Brainee LMS (5E’s duration), activity, reflections, assignment';


            let otherParams = lessonDescription; //'5E\'s instructional model, Lesson Instruction, more technology resources, reflections, homework \n';

            retVal += 'Generate a lesson plan on ' + subject + ' with the topic of ' + lessonTitle + ' and follow the instructions below: \n';
            // retVal += '- Lesson should be based on Bloom\'s Taxonomy. \n';
            retVal += "- It should be suitable for " + gradeLevel + " students. \n";
            retVal += "- Keep language level suitable for " + gradeLevel + " students. \n";
            retVal += "- It will use Brainee LMS E-Learning platform for teaching. \n";
            // retVal += "- Include at least 5 items of worksheet with the correct answers with explanation at the end of the lesson plan. \n"
            retVal += "- Translate the lesson plan in " + language;
            retVal += "- Use the template below: \n";
            retVal += "Lesson title" + lessonTitle + "\n";
            retVal += "Topic\n";
            retVal += "Subject\n";
            retVal += "Grade Level\n";
            // retVal += "Term 2\n";
            retVal += "Competency\n";
            retVal += "Objective (content standard, performance standard)\n";
            retVal += "Teaching Materials(Illustrations)\n";
            retVal += "References from at least 3 books(show APA format, pages and copyright) or website links\n";
            // retVal += "References from at least 3 books APA format with pages and copyright or website links\n";
            retVal += "Specific Strategy(with instructions)\n";
            retVal += "Lesson Procedure\n";
            retVal += "Critical Thinking or Reflective Questions\n";
            retVal += "3-2-1 strategy (For strategy 3 - Takeaways or something you learned, For strategy 2 - Things you found interesting, For strategy 1 - Questions you have)\n";
            retVal += otherParams + '\n';
            retVal += "- Create at least 15 items of worksheet with the correct answers and explanation. \n"
            // retVal += "- Reference should be at least 3 items and do not include dictionary as referece \n";
            // retVal += "- Use " + language + " as the language for generating the lesson plan \n";


            // retVal = 'Generate lesson on adjectives integrated in Brainee LMS, with the following parameters: Topic, Subject, Grade Level 6, Competency, Objective (Content standard, Performance standard), teaching materials (illustrations), references (links) APA format with pages and copyright, specific strategies, critical thinking or reflective questions';
         }

         return retVal;
      }

      if (shouldRun && !running) {
         setShouldRun(false);
         setRunning(true);
         setCompletion('');

         let prompt = BuildUserPrompt(aiType);

         const xhr = OpenAIExt.streamClientChatCompletion(
            {
               model: 'gpt-3.5-turbo-0125',
               max_tokens: 3000,
               temperature: 0.5,
               n: 1,
               messages: [
                  { role: 'system', content: systemPrompt },
                  { role: 'user', content: prompt },
               ],
            },
            {
               apiKey: process.env.REACT_APP_OPENAI_API_KEY,
               handler: {
                  onContent(contentDraft, isFinal, xhr) {
                     setCompletion(contentDraft);
                     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
                  },
                  onDone(xhr) {
                     setRunning(false);
                     setXhr(null);
                  },
                  onError(error, status, xhr) {
                     console.error(error);
                     setXhr(null);
                     setRunning(false);
                  },
               },
            },
         );

         setXhr(xhr);
      }
   }, [aiType, gradeLevel, language, lod, noi, lessonTitle, running, shouldRun, subject, systemPrompt, topic, toq, userPrompt]);


   const handleShowAIType = (val) => {
      setAiType(val);
   }

   const onFinish = (values) => {
      console.log('Success:', values);
      setShouldRun(true);
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   function showFunctionDescription(aiType) {
      if (aiType === 'lesson') {
         return (
            <div style={{ display: "block", textAlign: "center" }}>
               <h5 style={{ marginBottom: "-2px", marginTop: "3px" }}>Create a Learning Plan</h5>
               <span>Create a learning plan for the topic of your choice</span>
            </div>
         )
      } else if (aiType === 'assessment') {
         return (
            <div style={{ display: "block", textAlign: "center" }}>
               <h5 style={{ marginBottom: "-2px", marginTop: "3px" }}>Create Assessment/Quiz</h5>
               <span>Create an Assessment or Quiz to the topic of your choice</span>
            </div>
         )
      } else if (aiType === 'other resource') {
         return (
            <div style={{ display: "block", textAlign: "center" }}>
               <h5 style={{ marginBottom: "-2px", marginTop: "3px" }}>Get Resouces</h5>
               <span>Get resources from other AI tools</span>
            </div>
         )
      } else if (aiType === 'braineebot') {
         return (
            <div style={{ display: "block", textAlign: "center" }}>
               <h5 style={{ marginBottom: "-2px", marginTop: "3px" }}>Ask Brainee Bot</h5>
               <span>Ask Brainee Bot to search for resources</span>
            </div>
         )
      }
   }

   function showFunctionInputs(aiType) {
      if (aiType === 'assessment') {
         return (
            <>
               <Form
                  form={form}
                  layout={"vertical"}
                  colon={false}
                  className='aiform'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
               >
                  <SimpleBarReact key={'uid'} style={{ maxHeight: "calc(100vh - 317px)" }}>
                     <Form.Item name="subject" label="Subject"
                        rules={[{
                           required: true,
                           message: 'Subject should not be blank'
                        }]}
                        initialValue={subject}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select subject"
                           // defaultValue={'araling panlipunan'}
                           onChange={(val) => setSubject(val)}
                        >
                           <Option value={'arts'}>Arts</Option>
                           <Option value={'araling panlipunan'}>Araling Panlipunan</Option>
                           <Option value={'edukasyong pantahanan at pangkabuhayan'}>Edukasyong Pantahanan at Pangkabuhayan</Option>
                           <Option value={'edukasyong sa pagpapakatao'}>Edukasyon sa Pagpapakatao</Option>
                           <Option value={'english'}>English</Option>
                           <Option value={'filipino'}>Filipino</Option>
                           <Option value={'mathematics'}>Mathematics</Option>
                           <Option value={'music'}>Music</Option>
                           <Option value={'science'}>Science</Option>
                           <Option value={'technology and livelihood education'}>Technology and Livelihood Education</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item name="gradelevel" label="Grade Level"
                        rules={[{
                           required: true,
                           message: 'Grade level should not be blank'
                        }]}
                        initialValue={gradeLevel}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select grade level"
                           // defaultValue={'kinder'}
                           onChange={(val) => setGradeLevel(val)}
                        >
                           <Option value={'kinder'}>Kinder</Option>
                           <Option value={'grade 1'}>Grade 1</Option>
                           <Option value={'grade 2'}>Grade 2</Option>
                           <Option value={'grade 3'}>Grade 3</Option>
                           <Option value={'grade 4'}>Grade 4</Option>
                           <Option value={'grade 5'}>Grade 5</Option>
                           <Option value={'grade 6'}>Grade 6</Option>
                           <Option value={'grade 7'}>Grade 7</Option>
                           <Option value={'grade 8'}>Grade 8</Option>
                           <Option value={'grade 9'}>Grade 9</Option>
                           <Option value={'grade 10'}>Grade 10</Option>
                           <Option value={'grade 11'}>Grade 11</Option>
                           <Option value={'grade 12'}>Grade 12</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item name="topic" label="Topic"
                        rules={[{
                           required: true,
                           message: 'Topic should not be blank'
                        }]}
                     >
                        <Input
                           key={'uid'}
                           allowClear
                           size='large'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Topic"
                           onBlur={(event) => setTopic(event.target.value)}
                        />
                     </Form.Item>
                     <Form.Item name="noi" label="Number of Items"
                        rules={[{
                           required: true,
                           message: 'Number of items should not be blank'
                        }]}
                        initialValue={noi}
                     >
                        <InputNumber
                           key={'uid'}
                           size='large'
                           style={{ borderRadius: 7, width: "100%" }}
                           // defaultValue={1}
                           min="1"
                           max='100'
                           onChange={(val) => setNOI(val)}
                        />
                     </Form.Item>
                     <Form.Item name="toq" label="Type of Question/s"
                        rules={[{
                           required: true,
                           message: 'Type of question should not be blank'
                        }]}
                        initialValue={toq}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select type of question"
                           // defaultValue={'short answer'}
                           onChange={(val) => setTOQ(val)}
                           mode="multiple"
                        >
                           <Option value={'short answer'}>Short Answer</Option>
                           <Option value={'multiple choice'}>Mutiple Choice</Option>
                           <Option value={'true or false'}>True or False</Option>
                           <Option value={'multiple choice with multiple correct answers'}>Multiple Answers</Option>
                           <Option value={'open-ended, essay'}>Open-ended/Essay</Option>
                           <Option value={'fill in the blank with choices'}>Fill in the Blank</Option>
                           <Option value={'matching type'}>Matching Type</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item name="lod" label="Level of Difficulty"
                        rules={[{
                           required: true,
                           message: 'Level of difficulty should not be blank'
                        }]}
                        initialValue={lod}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select difficulty level"
                           // defaultValue={'easy'}
                           onChange={(val) => setLOD(val)}
                        >
                           <Option value={'easy'}>Easy</Option>
                           <Option value={'average'}>Average</Option>
                           <Option value={'difficult'}>Difficult</Option>
                        </Select>
                     </Form.Item>

                     <Form.Item name="translation" label="Translation"
                        rules={[{
                           required: true,
                           message: 'Translation should not be blank'
                        }]}
                        initialValue={language}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select translation"
                           // defaultValue={'english'}
                           onChange={(val) => setLanguage(val)}
                        >
                           <Option value={'english'}>English</Option>
                           <Option value={'tagalog'}>Filipino</Option>
                           {/* <Option value={'japanese'}>Japanese</Option>
                           <Option value={'arabic'}>Arabic</Option> */}
                        </Select>
                     </Form.Item>
                  </SimpleBarReact>

                  <Row gutter={[10, 10]} style={{ marginTop: "5px" }}>
                     <Col sm={24} md={12} lg={12}>
                        {
                           running
                              ?
                              <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={running}
                                 htmlType='submit'
                              >
                                 Generating...
                              </Button>
                              :
                              <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={running}
                                 htmlType='submit'
                              >
                                 <SendOutlined />Generate
                              </Button>
                        }

                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Button className='button-shadow' size='middle' type="primary" shape='round' block disabled={!running} onClick={() => xhr?.abort()}>
                           Stop
                        </Button>
                     </Col>
                  </Row>
               </Form>

            </>
         )
      } else if (aiType === 'lesson') {
         return (
            <>
               <Form
                  form={form}
                  layout={"vertical"}
                  colon={false}
                  className='aiform'
                  onFinish={onFinish}
                  onFinishFailed={onFinishFailed}
               >
                  <SimpleBarReact key={'uid'} style={{ maxHeight: "calc(100vh - 317px)" }}>
                     <Form.Item name="subject" label="Subject"
                        rules={[{
                           required: true,
                           message: 'Subject should not be blank'
                        }]}
                        initialValue={subject}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select subject"
                           // defaultValue={'araling panlipunan'}
                           onChange={(val) => setSubject(val)}
                        >
                           <Option value={'arts'}>Arts</Option>
                           <Option value={'araling panlipunan'}>Araling Panlipunan</Option>
                           <Option value={'edukasyong pantahanan at pangkabuhayan'}>Edukasyong Pantahanan at Pangkabuhayan</Option>
                           <Option value={'edukasyong sa pagpapakatao'}>Edukasyon sa Pagpapakatao</Option>
                           <Option value={'english'}>English</Option>
                           <Option value={'filipino'}>Filipino</Option>
                           <Option value={'mathematics'}>Mathematics</Option>
                           <Option value={'music'}>Music</Option>
                           <Option value={'science'}>Science</Option>
                           <Option value={'technology and livelihood education'}>Technology and Livelihood Education</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item name="gradelevel" label="Grade Level"
                        rules={[{
                           required: true,
                           message: 'Grade level should not be blank'
                        }]}
                        initialValue={gradeLevel}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select grade level"
                           // defaultValue={'kinder'}
                           onChange={(val) => setGradeLevel(val)}
                        >
                           <Option value={'kinder'}>Kinder</Option>
                           <Option value={'grade 1'}>Grade 1</Option>
                           <Option value={'grade 2'}>Grade 2</Option>
                           <Option value={'grade 3'}>Grade 3</Option>
                           <Option value={'grade 4'}>Grade 4</Option>
                           <Option value={'grade 5'}>Grade 5</Option>
                           <Option value={'grade 6'}>Grade 6</Option>
                           <Option value={'grade 7'}>Grade 7</Option>
                           <Option value={'grade 8'}>Grade 8</Option>
                           <Option value={'grade 9'}>Grade 9</Option>
                           <Option value={'grade 10'}>Grade 10</Option>
                           <Option value={'grade 11'}>Grade 11</Option>
                           <Option value={'grade 12'}>Grade 12</Option>
                        </Select>
                     </Form.Item>
                     <Form.Item name="title" label="Lesson Title"
                        rules={[{
                           required: true,
                           message: 'Lesson Title should not be blank'
                        }]}
                     >
                        <Input
                           key={'uid'}
                           allowClear
                           size='large'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Lesson Plan on"
                           onBlur={(event) => setLessonTitle(event.target.value)}
                        />
                     </Form.Item>
                     <Form.Item name="description" label="Lesson Description"
                     // rules={[{
                     //    required: true,
                     //    message: 'Lesson Description should not be blank'
                     // }]}
                     >
                        <Input
                           key={'uid'}
                           allowClear
                           size='large'
                           style={{ borderRadius: 7 }}
                           placeholder="Enter Lesson Description"
                           onBlur={(event) => setLessonDescription(event.target.value)}
                        />
                     </Form.Item>

                     <Form.Item name="translation" label="Translation"
                        rules={[{
                           required: true,
                           message: 'Translation should not be blank'
                        }]}
                        initialValue={language}
                     >
                        <Select
                           key={'uid'}
                           showArrow
                           size='large'
                           style={{ width: '100%' }}
                           allowClear
                           placeholder="Select translation"
                           // defaultValue={'english'}
                           onChange={(val) => setLanguage(val)}
                        >
                           <Option value={'english'}>English</Option>
                           <Option value={'tagalog'}>Filipino</Option>
                           {/* <Option value={'japanese'}>Japanese</Option>
                           <Option value={'arabic'}>Arabic</Option> */}
                        </Select>
                     </Form.Item>
                  </SimpleBarReact>

                  <Row gutter={[10, 10]} style={{ marginTop: "5px" }}>
                     <Col sm={24} md={12} lg={12}>
                        {
                           running
                              ?
                              <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={running}
                                 htmlType='submit'
                              >
                                 Generating...
                              </Button>
                              :
                              <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={running}
                                 htmlType='submit'
                              >
                                 <SendOutlined />Generate
                              </Button>
                        }
                     </Col>
                     <Col sm={24} md={12} lg={12}>
                        <Button className='button-shadow' size='middle' type="primary" shape='round' block disabled={!running} onClick={() => xhr?.abort()}>
                           Stop
                        </Button>
                     </Col>
                  </Row>
               </Form>
            </>
         )
      } else if (aiType === "other resource") {
         return (
            <>
               <SimpleBarReact
                  style={{ height: "calc(100vh - 627px)", backgroundColor: "white", minHeight: "200px" }}
               // onClick={() => setSelectedDocumentIdx(-1)}
               >
                  <List
                     size="small"
                     dataSource={otherResourceList}
                     renderItem={(item, idx) =>
                        // <Tooltip placement="left" title={otherResourceList[idx].title}>
                        <List.Item
                           onClick={() => handleViewLink(otherResourceList[idx].link, otherResourceList[idx].container)}
                           className={idx === selectedDocumentIdx ? 'document-file-selected' : 'document-file'}
                           style={{ textAlign: "left", whiteSpace: "nowrap", textOverflow: "ellipsis", margin: "5px" }}
                        >
                           {otherResourceList[idx].title}
                        </List.Item>
                        // </Tooltip>
                     }
                  />
               </SimpleBarReact>
            </>
         )
      } else if (aiType === "braineebot") {
         // AI_Greeting();

         return (
            <>
               <TextArea
                  allowClear
                  showCount
                  value={newQuestion}
                  maxLength={500}
                  style={{ height: 220, resize: "none" }}
                  rows={8}
                  onChange={(e) => setNewQuestion(e.target.value)}
                  placeholder="Ask me anything..."
               // autoSize={{
               //    maxRows: 10,
               // }}
               />
               <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={botSearching} onClick={() => generateBotResponse()}>
                  <SendOutlined /> Send Message
               </Button>
            </>
         )
      }
   }

   const handleViewLink = (link, container) => {
      if (container === 'iframe')
         setOtherResouce(link);
      else {
         setOtherResouce(null);
         window.open(link, '_blank').focus();
      }
   }

   return (
      <>
         <Row gutter={[0, 10]}>
            <Col xs={24} md={9} lg={9}>
               <div className='student_list_container' style={{ paddingRight: "10px", paddingBottom: "6px" }}>
                  <div style={{ paddingBottom: '10px' }}>
                     <Segmented
                        disabled={running ? true : false}
                        key={'uid'}
                        value={aiType}
                        block
                        options={[
                           {
                              label: 'Learning Plan',
                              value: 'lesson',
                              title: 'Learning Plan',
                           },
                           // {
                           //    label: 'Assessment/Quiz',
                           //    value: 'assessment',
                           //    title: 'Assessment/Quiz',
                           // },
                           {
                              label: 'Ask Brainee Bot',
                              value: 'braineebot',
                              title: 'Ask Brainee Bot',
                           },
                           // {
                           //    label: 'Other AI Resources',
                           //    value: 'other resource',
                           //    title: 'Other AI Resources',
                           // },
                        ]}
                        onChange={(val) => handleShowAIType(val)}
                     />
                     {
                        showFunctionDescription(aiType)
                     }
                  </div>

                  {
                     showFunctionInputs(aiType)
                  }

               </div>
            </Col>
            <Col xs={24} md={15} lg={15}>
               <div className="stream-answer-container">
                  {
                     aiType !== "other resource"
                        ?
                        <div style={{ position: "relative" }}>
                           <SimpleBarReact key={'uid'} style={{ maxHeight: "calc(100vh - 187px)" }}>
                              <div style={{ display: "flex", justifyContent: "center", paddingBottom: "20px", paddingTop: "10px" }}>
                                 {/* <Image src='../images/brainee_bot.png' preview={false} height="150px" /> */}
                              </div>
                              {
                                 aiType === 'braineebot'
                                    ?
                                    <>
                                       {storedValues.map((value, index) => {
                                          return (
                                             <div className="answer-section" key={index}>
                                                {value.type === "answer" &&
                                                   <>
                                                      <div style={{ display: "flex", justifyContent: "left", marginTop: '5px' }}>
                                                         <div style={{ paddingLeft: "5px", marginLeft: "10px", marginTop: "10px" }}>
                                                            {/* <Avatar src="../images/brainee_mascot.png" /> */}
                                                            {/* <Avatar src="../images/braineebot.png" /> */}
                                                            <Image src='../images/brainee_bot.png' preview={false} height="40px" />
                                                         </div>
                                                         <div style={{ maxWidth: "65%" }}>
                                                            <p className="answer">
                                                               <ReactTextFormat linkTarget="_blank">
                                                                  {value.message}
                                                               </ReactTextFormat>
                                                            </p>
                                                         </div>
                                                      </div>
                                                      <div className="copy-icon">
                                                         <Tooltip placement="top" title='Click to copy text'>
                                                            <CopyOutlined onClick={() => copyText(value.message)} />
                                                         </Tooltip>
                                                      </div>
                                                   </>
                                                }
                                                {value.type === "question" &&
                                                   <div style={{ display: "flex", justifyContent: "right" }}>
                                                      <div style={{ maxWidth: "65%" }}>
                                                         <p className="question">{value.message}</p>
                                                      </div>
                                                   </div>}
                                             </div>
                                          );
                                       })}
                                       {isTyping &&
                                          <div style={{ display: "flex" }}>
                                             <div style={{ paddingLeft: "5px", marginLeft: "10px", marginTop: "10px", marginBottom: '10px' }}>
                                                <Image src='../images/brainee_bot.png' preview={false} height="40px" />
                                             </div>
                                             <div className="typing">
                                                <div className="typing__dot" />
                                                <div className="typing__dot" />
                                                <div className="typing__dot" />
                                             </div>
                                          </div>}
                                       <div ref={messagesEndRef} />
                                    </>
                                    :
                                    <div className='wrapword' style={{ padding: "15px" }} id='result-gpt'>
                                       {completion}
                                       {running && <>█</>}
                                    </div>
                              }


                              <div ref={messagesEndRef} />
                           </SimpleBarReact>
                           {
                              completion &&
                              <div style={{ display: "flex" }}>
                                 <div>
                                    <Tooltip placement="left" title='Click to copy results'>
                                       <div className="stream-copy-icon" style={{ right: 0 }}>
                                          <CopyOutlined onClick={() => copyText(completion)} />
                                       </div>
                                    </Tooltip>
                                 </div>
                                 <div>
                                    <Tooltip placement="left" title='Click to download results'>
                                       <div className="stream-copy-icon" style={{ right: 45 }}>
                                          <DownloadOutlined onClick={() => handleDownloadResult()} />
                                       </div>
                                    </Tooltip>
                                 </div>
                              </div>

                           }
                        </div>
                        :
                        <div style={{ display: "flex", height: "calc(100vh - 187px)" }}>
                           {
                              otherResouce !== null
                                 ?
                                 <Iframe
                                    id="otherresource"
                                    title="Other Resource"
                                    src={otherResouce}
                                    frameBorder="0"
                                    height='100%'
                                    width='100%'
                                 />
                                 :
                                 <div style={{ display: "flex", width: "100%", justifyContent: "center", paddingBottom: "20px", paddingTop: "10px" }}>
                                    <Image src='../images/brainee_bot.png' preview={false} height="150px" />
                                 </div>
                           }

                        </div>
                  }

               </div>
            </Col>
         </Row>
      </>
   )
}