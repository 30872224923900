/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import axios from 'axios';
import { Button, Descriptions, Typography, Spin, InputNumber, Input, Space, Breadcrumb } from 'antd';
import './MasteryAssessment.css';
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import Split from 'react-split';
import moment from 'moment';
import { useSelector } from 'react-redux';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
// import * as AntDIcons from '@ant-design/icons';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Paragraph } = Typography;
// const { Option } = Select;
const { TextArea } = Input;

let ansIdx = 0;
let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let matched = false;
let pdfFile = '';
let essayIdx = -1;

export function MasteryStudentViewPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const masteryrec = useSelector(state => state.masteryrec);

   const origin = location.state.activetab;
   const assignInfo = location.state.assignInfo;
   const assessmentType = location.state.assessment_type;
   const assessmentId = location.state.assessment_id;
   const studentId = location.state.student_id;
   const studentName = location.state.student_name;

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [answerSheetData, setAnswerSheetData] = useState([]);
   const [questionSheetPDF, setQuestionSheetPDF] = useState("");
   const subdomain = localStorage.getItem("lms-subdomain");

   const [assInfo, setAssInfo] = useState({
      Id: "",
      Title: "",
      Term: "",
      File_url: "",
      Question_type: "1",
      Created_by: "",
      Date_created: "",
      Question_sheet: "",
      Level: "",
      Subject: "",
      Level_desc: "",
      Subject_desc: ""
   });

   const [answerInfo, setAnswerInfo] = useState({
      Id: "",
      Answers: "",
      Score: "",
      Essay_score: "",
      Total_points: "",
      Start_date: "",
      End_date: ""
   });

   useEffect(() => {
      Utils.verifyJwt();

      pdfFile = '';

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];
      essayIdx = -1;

      // switch (assessmentType) {
      //    case "formative_1":
      //       setAnswerSheetData(masteryrec.formative_1);
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_1_file_url}`);
      //       break;
      //    case "formative_2":
      //       setAnswerSheetData(masteryrec.formative_2);
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_2_file_url}`);
      //       break;
      //    case "formative_3":
      //       setAnswerSheetData(masteryrec.formative_3);
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.formative_3_file_url}`);
      //       break;
      //    case "summative":
      //       setAnswerSheetData(masteryrec.summative);
      //       setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${masteryrec.summative_file_url}`);
      //       break;
      //    default:
      //       setAnswerSheetData("");
      //       setQuestionSheetPDF("");
      // }

      // getAnswerInfo(assessmentId, assignInfo.id, studentId, assessmentType);
      getAssessmentInfo(assessmentId);
   }, [location.state.assessment_id]);

   const getAssessmentInfo = (id) => {
      ansIdx = 0;

      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/info?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            setAssInfo({
               id: data.id,
               title: data.title,
               term: data.term,
               level: data.level,
               subject: data.subject,
               formative_1: data.formative_1,
               formative_1_file_url: data.formative_1_file_url,
               formative_2: data.formative_2,
               formative_2_file_url: data.formative_2_file_url,
               formative_3: data.formative_3,
               formative_3_file_url: data.formative_3_file_url,
               summative: data.summative,
               summative_file_url: data.summative_file_url,
               study_plan_1: data.study_plan_1,
               study_plan_2: data.study_plan_2,
               mastery_level: data.mastery_level,
               mastery_competency: data.competency,
               created_by: data.created_by,
            });

            switch (assessmentType) {
               case "formative_1":
                  setAnswerSheetData(JSON.parse(data.formative_1));
                  if (data.formative_1_file_url.includes('base64'))
                     setQuestionSheetPDF(data.formative_1_file_url);
                  else
                     setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.formative_1_file_url}`);
                  break;
               case "formative_2":
                  setAnswerSheetData(JSON.parse(data.formative_2));

                  if (data.formative_2_file_url.includes('base64'))
                     setQuestionSheetPDF(data.formative_2_file_url);
                  else
                     setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.formative_2_file_url}`);
                  break;
               case "formative_3":
                  setAnswerSheetData(JSON.parse(data.formative_3));

                  if (data.formative_3_file_url.includes('base64'))
                     setQuestionSheetPDF(data.formative_3_file_url);
                  else
                     setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.formative_3_file_url}`);
                  break;
               case "summative":
                  setAnswerSheetData(JSON.parse(data.summative));

                  if (data.summative_file_url.includes('base64'))
                     setQuestionSheetPDF(data.summative_file_url);
                  else
                     setQuestionSheetPDF(`${process.env.REACT_APP_API_S3_URL}/${subdomain}/${data.summative_file_url}`);

                  break;
               default:
                  setAnswerSheetData("");
                  setQuestionSheetPDF("");
            }

            getAnswerInfo(assessmentId, assignInfo.id, studentId, assessmentType);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const getAnswerInfo = (assessment_id, assignment_id, student_id, assessment_type) => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/answerinfo?assessment_id=${assessment_id}&assignment_id=${assignment_id}&student_id=${student_id}&assessment_type=${assessment_type}`;
      axios.get(url).then((response) => {
         if (response.data) {
            const data = response.data;

            var answer_info = {
               Id: data.id,
               Answers: JSON.parse(data.answer),
               Score: data.score + data.essay_score,
               Total_points: data.total_points,
               Start_date: moment(data.start_date).format("MMM DD, YYYY hh:mm a"),
               End_date: moment(data.end_date).format("MMM DD, YYYY hh:mm a"),
               Essay_score_multi: data.essay_score_multi !== "" && data.essay_score_multi !== null ? JSON.parse(data.essay_score_multi) : [],
               Essay_multi_comments: data.essay_multi_comments !== "" && data.essay_multi_comments !== null ? JSON.parse(data.essay_multi_comments) : [],
               Essay_score: data.essay_score,
            };

            setAnswerInfo(answer_info);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   function sPDFFile() {
      pdfFile = questionSheetPDF;
      return true;
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
            >
               <div className="dashboard-item">
                  <div className="dashboard-item-inner">
                     {/* <ul>
                        <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                        <li><span>/</span></li>
                        <li className="active">Brainee LMS</li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessments</a></li>
                        <li><span>/</span></li>
                        <li><a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assignInfo.id}&mastery_id=${assessmentId}&origin=${origin}&qt=1`)}>Start</a></li>
                        <li><span>/</span></li>
                        <li className="active">{Utils.GetTypeLabel(assessmentType)} Result</li>
                     </ul> */}
                     <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                        <Breadcrumb.Item>
                           <Link to="/home">Home</Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate('/studentmasteryassessment', { state: { activetab: origin } })}>Mastery Assessment</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                           <a href="#" onClick={() => navigate(`/studentmasteryassessment/preview?assign_id=${assignInfo.id}&mastery_id=${assessmentId}&origin=${origin}&qt=2`)}>Start</a>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{Utils.GetTypeLabel(assessmentType)} Result</Breadcrumb.Item>
                     </Breadcrumb>
                  </div>
                  {answerInfo.Answers.length > 0
                     ?
                     <div className="pdf-all-page-container">
                        <div style={{ width: "100%", height: "calc(100vh - 58px)" }}>
                           <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                              <Viewer
                                 pageLayout={{
                                    transformSize: ({ size }) => ({
                                       height: size.height + 30,
                                       width: size.width + 30,
                                    }),
                                 }}
                                 fileUrl={questionSheetPDF}
                                 defaultScale={SpecialZoomLevel.PageWidth}
                                 initialPage={0}
                              />
                           </Worker>
                        </div>
                        {/* <RenderPDFReader url={questionSheetPDF} height={"calc(100vh - 58px)"} /> */}
                        {/* <RenderPDFViewer url={questionSheetPDF} height={"calc(100vh - 58px)"} load={pdfFile !== questionSheetPDF ? sPDFFile() : false} /> */}
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>

               <div className="notice-item">
                  <>
                     {answerInfo.Answers.length > 0
                        ?
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4}>
                                 <Descriptions.Item label={"Student Name"} span={4} style={{ textAlign: "left" }} >
                                    <Paragraph style={{ textAlign: "left", width: "100%" }}><b>{studentName}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Assessment Type"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{Utils.GetTypeLabel(assessmentType)}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{assignInfo.title}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Score"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{(answerInfo.Score + answerInfo.Essay_score) + " of " + answerInfo.Total_points}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Duration"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{Math.floor((Math.abs(new Date(answerInfo.End_date) - new Date(answerInfo.Start_date)) / 1000 / 60))} min(s)</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Legend"} span={4} style={{ textAlign: "center" }} >
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}>
                                       <Space wrap>
                                          <Button size='middle' shape="circle" style={{ backgroundColor: "rgba(1, 170, 7, 0.5)" }}>
                                             {" "}
                                          </Button>
                                          Correct Answer

                                          <Button size='middle' shape="circle" style={{ backgroundColor: "rgba(170, 1, 7, 0.5)" }}>
                                             {" "}
                                          </Button>
                                          Incorrect Answer
                                       </Space>
                                    </Paragraph>
                                 </Descriptions.Item>
                              </Descriptions>

                              <ul className="questions">
                                 {answerSheetData.map(({ id, type, points, correct, option_labels, image_url }, idx) => {

                                    if (type == "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    //-- Check if answer and correct are matched 
                                    var matched = false;

                                    if (type !== "long_answer" && type !== "section") {
                                       if (type === "short_answer") {
                                          for (var i = 0; i < correct.length; i++) {
                                             //-- Remove all white spaces before comparing
                                             var tmp_correct = correct[i].replace(/\s/g, '');
                                             var tmp_answer = answerInfo.Answers[idx].answer[0].replace(/\s/g, '');

                                             // if (correct[i].toLowerCase().trim() === answerInfo.Answers[idx].answer[0].toLowerCase().trim()) {
                                             if (tmp_correct.toLowerCase() === tmp_answer.toLowerCase()) {
                                                matched = true;
                                                break;
                                             }
                                          }
                                       } else {
                                          var cntMatched = 0;
                                          for (var i = 0; i < correct.length; i++) {
                                             if (correct[i] === answerInfo.Answers[idx].answer[i]) {
                                                cntMatched++;
                                             }
                                          }

                                          if (cntMatched === correct.length)
                                             matched = true;
                                       }
                                    }

                                    if (type === "long_answer")
                                       essayIdx++;

                                    return (
                                       <li>
                                          <BraineeQuestionCard
                                             key={id}
                                             question_key_id={id}
                                             index={idxCol[idx]}
                                             id={idx}
                                             type={type}
                                             points={points}
                                             correct={correct}
                                             answers={answerInfo.Answers[idx].answer}
                                             option_labels={option_labels}
                                             image_url={image_url}
                                             question_type={'1'}
                                             mode={'student-view'}
                                             matched={matched}
                                             card_bg={location.state.card_bg}
                                             assessment_type={'mastery'}
                                          />
                                          {type === "long_answer" &&
                                             <>
                                                <Descriptions bordered size='small' column={4} style={{ marginBottom: "20px" }}>
                                                   <Descriptions.Item label={"Points (Max: " + points + ")"} span={4} style={{ fontSize: 13 }}>
                                                      <InputNumber
                                                         id={essayIdx}
                                                         disabled
                                                         size='middle'
                                                         // max={points} 
                                                         // min={0} 
                                                         style={{ width: "100%", borderRadius: "7px" }}
                                                         defaultValue={answerInfo.Essay_score_multi[essayIdx]}
                                                      />
                                                   </Descriptions.Item>
                                                   <Descriptions.Item label="Comments/Remarks" span={4} style={{ fontSize: 13 }}>
                                                      <TextArea
                                                         // allowClear
                                                         disabled
                                                         id={essayIdx}
                                                         name={essayIdx}
                                                         rows={5}
                                                         defaultValue={answerInfo.Essay_multi_comments[essayIdx]}
                                                      />
                                                   </Descriptions.Item>
                                                </Descriptions>
                                             </>
                                          }
                                       </li>
                                    );
                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                        :
                        <div className='temp-answer-sheet-content2'>
                           <Spin size='large' tip="Loading, please wait..." />
                        </div>
                     }
                  </>
               </div>
            </Split>
         </div>
      </>
   )
}
