/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import moment from 'moment';
import Utils from '../misc/Utils';
import Enums from '../misc/Enums';

const getResources = () => {
   let user = Utils.getCurrentUserInfo(Enums.UserInfo.id)
   const headers = {};
   let api = `${process.env.REACT_APP_LMS_API}api/AWS/viewLMSFile?userid=${user}`;
   return axios.get(api, { headers: headers });
}

const postResources = (formdata, option) => {
   const headers = { 'Content-Type': 'multipart/form-data' };
   var api = `${process.env.REACT_APP_LMS_API}api/AWS/uploadLMSFile`;
   return axios.post(api, formdata, option, { headers: headers })
}

const deleteResources = (uuid) => {
   const headers = { 'Content-Type': 'multipart/form-data' };
   var api = `${process.env.REACT_APP_LMS_API}api/AWS/deleteLMSFile?uuid=${uuid}`;
   return axios.post(api, { headers: headers })
}

const saveStateLesson = (lesson) => {
   let api = `${process.env.REACT_APP_API_LESSON}/updateLessonContent`
   const headers = {};
   return axios.post(api, lesson, { headers: headers })
}

const getLessonById = (id) => {

   let api = `${process.env.REACT_APP_API_LESSON}/getLessonItem?lessonid=${id}`
   const headers = {};
   return axios.get(api, { headers: headers })
}

const getNotesById = (id) => {

   let api = `${process.env.REACT_APP_LMS_API}api/Notes/getAllNotesByLessonId?lessonid=${id}`
   const headers = {};
   return axios.get(api, { headers: headers })
}

const saveNote = (note) => {
   let api = `${process.env.REACT_APP_LMS_API}api/Notes/upsertNotes`
   const headers = {};
   return axios.post(api, note, { headers: headers })
}

const deleteNote = (id) => {
   let api = `${process.env.REACT_APP_LMS_API}api/Notes/deleteNotes?noteId=${id}`
   const headers = {};
   return axios.post(api, { headers: headers })
}

const getUserSchedule = (id, role) => {
   let api = `${process.env.REACT_APP_LMS_API}api/Lesson/listEntityLessons?userid=${id}&role=${role}`
   const headers = {};
   return axios.get(api, { headers: headers })
}

const getListFormative = (id, level) => {
   let api = `${process.env.REACT_APP_API_ASSESSMENT}/listbylevel?created_by=${id}&level=${level}&at=formative`
   const headers = {};
   return axios.get(api, { headers: headers })
}

const assignFormative = (assignment_id, assessment_id, user_id, lesson_id, effective_date_start, effective_date_end, level_id) => {

   let assignment_options = {
      id: assignment_id,
      assessment_id: assessment_id,
      assigned_by: user_id,
      duration: 1,
      allowed_attempts: 1,
      passing_percentage: 50,
      allow_result_viewing: 1,
      enable_timer: 0,
      start_date: effective_date_start, //moment().format("YYYY-MM-DD HH:mm"),
      end_date: effective_date_end, //moment().format("YYYY-MM-DD HH:mm"),
      assigned_students: '',
      term: 1,
      level: level_id 
   }
   let api = `${process.env.REACT_APP_API_ASSESSMENT}/assignwlesson?lessonid=${lesson_id}&at=formative`
   const headers = {};
   return axios.post(api, assignment_options, { headers: headers })
}

const updateAssignFormative = (assignment_id, assessment_id, user_id, lesson_id, effective_date_start, effective_date_end, level_id) => {

   let assignment_options = {
      id: assignment_id,
      assessment_id: assessment_id,
      assigned_by: user_id,
      duration: 1,
      allowed_attempts: 1,
      passing_percentage: 50,
      allow_result_viewing: 1,
      enable_timer: 0,
      start_date: effective_date_start, //moment().format("YYYY-MM-DD HH:mm"),
      end_date: effective_date_end, //moment().format("YYYY-MM-DD HH:mm"),
      assigned_students: '',
      term: 1,
      level: level_id 
   }
   let api = `${process.env.REACT_APP_API_ASSESSMENT}/updateassignwlesson?lessonid=${lesson_id}&at=formative`
   const headers = {};
   return axios.post(api, assignment_options, { headers: headers })
}

export default {
   getResources,
   postResources,
   deleteResources,
   saveStateLesson,
   getUserSchedule,
   getLessonById,
   getNotesById,
   saveNote,
   deleteNote,
   getListFormative,
   assignFormative,
   updateAssignFormative
};