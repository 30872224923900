import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
   Input, Table, Space, Tooltip, Button, Row, Col, Popconfirm,
   Tag, Modal, Spin, Breadcrumb
} from 'antd';
import { SearchOutlined, CloudUploadOutlined, ProfileOutlined, DeleteOutlined } from '@ant-design/icons';
// import SideCalendar from '../../components/side-calendar/SideCalendar';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import axios from 'axios';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import Submit from './Components/Submit';
import Details from './Components/Details';

// const { Text } = Typography;
// const { Option } = Select;

export function StudentHomeworks() {
   // const navigate = useNavigate();

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");

   const [homeworkID, setHomeworkID] = useState("");
   const [homeworkList, setHomeworkList] = useState([]);
   const [homeworkListLoading, setHomeworkListLoading] = useState(false);

   const [submitModalVisible, setSubmitModalVisible] = useState(false);
   const [submitting, setSubmitting] = useState(false);

   const [detailsModalVisible, setDetailsModalVisible] = useState(false);

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   useEffect(() => {
      Utils.verifyJwt();
      handleSearchHomeworks(user_id);
   }, []);

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const HomeworkListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'submit_id',
         key: 'submit_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'evaluation_id',
         key: 'evaluation_id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'expired',
         key: 'expired',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            // <Tooltip placement="right" title={object.title}>
            //    <span style={{cursor:"default"}}>{object.title}</span>
            // </Tooltip>
            <Tag
               style={{ fontSize: 12, cursor: 'default' }}
               color={object.submit_id === null && object.evaluation_id === null ? 'red' : (object.submit_id !== null && object.evaluation_id === null ? 'gold' : 'green')}
            >
               <Tooltip placement="right" title={object.evaluation_id !== null ? object.title + " (Complete)" : (object.submit_id !== null && object.evaluation_id === null ? object.title + " (For Evaluation)" : object.title)}>
                  <span style={{ cursor: "default" }}>{object.title}</span>
               </Tooltip>
            </Tag>
         ),
         width: '20%'
      },
      {
         title: 'Teacher',
         key: 'teacher',
         dataIndex: 'teacher',
         sorter: (a, b) => { return a.teacher.localeCompare(b.teacher) },
         width: '13%'
      },
      {
         title: 'Subject',
         key: 'subject',
         dataIndex: 'subject',
         sorter: (a, b) => { return a.subject.localeCompare(b.subject) },
         ...getColumnSearchProps('subject'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="right" title={object}>
               <span style={{ cursor: "default" }}>{object}</span>
            </Tooltip>
         ),
         width: '14%'
      },
      {
         title: 'Homework Date',
         key: 'homework_date',
         dataIndex: 'homework_date',
         sorter: (a, b) => { return a.homework_date.localeCompare(b.homework_date) },
         width: '12%'
      },
      {
         title: 'Submission Date',
         key: 'submission_date',
         dataIndex: 'submission_date',
         sorter: (a, b) => { return a.submit_date.localeCompare(b.submit_date) },
         width: '12%'
      },
      {
         title: 'Score',
         key: 'score',
         dataIndex: 'score',
         sorter: (a, b) => { return a.created_at.localeCompare(b.created_at) },
         render: score => (
            <span>{score == 0 ? '' : score}</span>
         ),
         width: '6%'
      },
      {
         title: 'Remarks',
         key: 'remarks',
         dataIndex: 'remarks',
         width: '19%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               {user_role !== "Parent"
                  ?
                  <Space size="small">
                     {
                        (payload.submit_id === null && !payload.expired) &&
                        <Tooltip placement="left" title='Submit Homework'>
                           <CloudUploadOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                              onClick={() => handleSubmitHomework(payload.id)}
                           />
                        </Tooltip>
                     }
                     <Tooltip placement="left" title='Show Homework Details'>
                        <ProfileOutlined color='#4abec7' style={{ cursor: 'pointer' }}
                           onClick={() => handleShowHomeworkDetails(payload.id)}
                        />
                     </Tooltip>
                     {payload.submit_id !== null && payload.evaluation_id === null &&
                        <Tooltip placement="left" title='Unsubmit Homework'>
                           <Popconfirm
                              title="Are you sure you want to unsubmit your homework?"
                              onConfirm={() => handleRemoveSubmission(payload.submit_id)}
                              okText="Yes"
                              cancelText="No"
                           >
                              <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                     }
                  </Space>
                  :
                  <></>
               }

            </>
         },
         width: '7%'
      },
   ].filter(item => !item.hidden);

   const handleSearchHomeworks = () => {
      setHomeworkListLoading(true);
      var url = `${process.env.REACT_APP_API_HOMEWORK}/listperstudent?id=${user_id}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setHomeworkList(data);

            setHomeworkListLoading(false);
         }
      }, (error) => {
         setHomeworkListLoading(false);
         console.log(error.message);
      });
   }

   const handleRemoveSubmission = (id) => {
      setHomeworkListLoading(true);
      var url = `${process.env.REACT_APP_API_HOMEWORK}/unsubmithomework?id=${id}`;

      axios.post(url).then(response => {
         if (response.data) {
            setHomeworkListLoading(false);

            Modal.success({
               title: 'Homework',
               content: 'Homework unsubmitted.',
               centered: true,
            });

            handleSearchHomeworks();
         }
      }, (error) => {
         setHomeworkListLoading(false);
         Modal.error({
            title: 'Homework',
            content: 'Homework unsubmit failed.',
            centered: true,
         });
      });
   }

   const handleCancelSubmit = () => {
      setHomeworkID("");
      setSubmitModalVisible(false);
   }

   const handleSubmitHomework = (id) => {
      setHomeworkID(id);
      setSubmitModalVisible(true);
   }

   const handleShowHomeworkDetails = (id) => {
      setHomeworkID(id);
      setDetailsModalVisible(true);
   }

   const handleCancelDetails = () => {
      setDetailsModalVisible(false);
   }

   const handleSaveSubmittedHomework = (data, fileForUpload) => {
      setSubmitting(true);

      if (fileForUpload.length > 0) {
         var formData = new FormData();

         fileForUpload.forEach(file => {
            formData.append('files', file);
         });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadHomework?s3path=${process.env.REACT_APP_S3_HOMEWORKS_STUDENTS_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               data.document = uploadedfiles;

               var url = `${process.env.REACT_APP_API_HOMEWORK}/submithomework`;
               axios.post(url, data).then(response => {
                  setSubmitting(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Homework',
                        content: 'Homework submitted.',
                        centered: true,
                        onOk: () => { setSubmitModalVisible(false) },
                     });

                     handleSearchHomeworks();
                  }
               }, (error) => {
                  setSubmitting(false);
                  Modal.error({
                     title: 'Homework',
                     content: 'Homework submission failed.',
                     centered: true,
                     onOk: () => { setSubmitModalVisible(false) },
                  });
               });

            }
         }, (error) => {
            // console.log(error.message);
         });
      } else {
         var url = `${process.env.REACT_APP_API_HOMEWORK}/submithomework`;
         axios.post(url, data).then(response => {
            setSubmitting(false);
            if (response.data) {
               Modal.success({
                  title: 'Homework',
                  content: 'Homework submitted.',
                  centered: true,
                  onOk: () => { setSubmitModalVisible(false) },
               });

               handleSearchHomeworks();
            }
         }, (error) => {
            setSubmitting(false);
            Modal.error({
               title: 'Homework',
               content: 'Homework submission failed.',
               centered: true,
               onOk: () => { setSubmitModalVisible(false) },
            });
         });
      }
   }

   return (
      <>
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Homeworks & Projects</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Homeworks & Projects</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               <Row gutter={12}>
                  <Col span={24}>
                     <div className="dashboard-item-inner3" style={{ overflow: 'auto' }}>
                        <Table
                           rowKey={'uid'}
                           loading={homeworkListLoading}
                           size='small'
                           columns={HomeworkListColumns}
                           dataSource={homeworkList}
                           pagination={{ position: ['topCenter'] }}
                           scroll={{
                              x: '1000px',
                              y: 'calc(100svh - 13.2rem)',
                           }}
                           footer={() => 'Total Records: ' + homeworkList.length}
                        />
                     </div>
                  </Col>
               </Row>
            </div>
         </div>
         {/* <div className="notice-area">
            <div className="notice-item">
               <SideCalendar />
               <NoticeBoard />
            </div>
         </div> */}

         <Modal
            title={"Submit Homework"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelSubmit(); }}
            open={submitModalVisible}
            // width={window.innerWidth - 800}
            width='60%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={submitting} tip={"Submitting, please wait..."}>
               <Submit
                  homeworkID={homeworkID}
                  onCancelCallback={handleCancelSubmit}
                  onSubmitCallback={handleSaveSubmittedHomework}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Homework Details"}
            centered
            okText={"Close"}
            cancelButtonProps={{ style: { display: 'none' } }}
            destroyOnClose={true}
            onOk={() => { handleCancelDetails(); }}
            onCancel={() => { handleCancelDetails(); }}
            open={detailsModalVisible}
            // width={window.innerWidth - 900}
            width='70%'
            okButtonProps={{ shape: "round" }}
         >
            <Details
               homeworkID={homeworkID}
            />
         </Modal>
      </>
   );
}

