import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
// import AllPagesPDFViewer from '../../components/pdf-viewer/all-pages'
import SimpleBarReact from "simplebar-react";
import '../../../node_modules/simplebar/src/simplebar.css';
import Countdown from 'react-countdown';
// import Countdown from '../../components/countdown/Countdown';
import axios from 'axios';
import BraineeQuestionCard from '../../components/assessment/BraineeQuestionCard';
import { SendOutlined, QuestionCircleOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import { Button, Descriptions, Typography, Popconfirm, Modal, Spin, Breadcrumb } from 'antd';
import StickyBox from "react-sticky-box";
// import { format } from 'date-fns';
import platform from 'platform';
import uuid from 'react-uuid';
// import * as AntDIcons from '@ant-design/icons';

import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';

import Split from 'react-split';
// import './SplitPanel.css';
import './Assessment.css';
import './Answer.css';
// import { setRef } from '@fullcalendar/core';

// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import { PDFReader } from 'reactjs-pdf-reader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { Text, Paragraph } = Typography;

let sectionIdx = 1;
let qcardIdx = 1;
let idxCol = [];
let pdfFile = '';

export function AnswerPDF(props) {
   const location = useLocation();
   const navigate = useNavigate();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const name = Utils.getCurrentUserInfo(Enums.UserInfo.fullname);
   const user_other_data = JSON.parse(Utils.getCurrentUserInfo(Enums.UserInfo.otherdata));
   const status = user_other_data.Status;

   const assInfo = location.state.assInfo;
   const assignInfo = location.state.assignInfo;
   // const ansId = location.state.id;

   const [answerSheetData, setAnswerSheetData] = useState(assInfo.Question_sheet);
   const [answerData, setAnswerData] = useState(() => {
      var ansLocal = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_myanswers"));
      if (ansLocal !== null)
         return ansLocal;
      else {
         return initializeAnswerData(assInfo.Question_sheet);
      }
   });

   const [startTime, setStartTime] = useState(() => {
      var timeStarted = JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_timestarted"));
      if (timeStarted !== null) {
         var val = timeStarted;
         return val;
      } else {
         // var val = Date.now();
         // localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
         // return val;
         return null;
      }
   });

   const [pdfLoaded, setPdfLoaded] = useState(false);
   const [isSubmitted, setIsSubmitted] = useState(false);
   const [downloadStatus, setDownloadStatus] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();
      // const unloadCallback = (event) => {
      //   event.preventDefault();
      //   event.returnValue = "";
      // return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);      
      // localStorage.clear();

      // pdfFile = '';

      // LoadPDFViewer(`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`, pdfFile !== assInfo.File_url ? sPDFFile() : false);

      // if (assignInfo.enable_timer) {
      //    var timer = startTime + (assignInfo.duration * 60 * 1000);
      //    var now = Date.now();

      //    if (now > timer) {
      //       handleTimeOut();
      //    }
      // }

   }, [assignInfo.id]);

   function initializeAnswerData(data) {
      let ansData = [];
      let tmpdata = {};

      data.map(({ id, type, option_labels }, idx) => {
         if (data[idx].type.includes("section")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": data[idx].correct,
            }
         } else if (data[idx].type.includes("multiple")) {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": setDefaultAnswers(type, option_labels.length),
               // "considered":"0",
            }
         } else if (data[idx].type === "short_answer") {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
               "considered": "0",
            }
         } else {
            tmpdata = {
               "id": id,
               "type": type,
               "answer": [""],
            }
         }

         ansData.push(tmpdata);
      });

      return ansData;
   }

   function setDefaultAnswers(type, options_length) {
      let retVal = [];
      var multiple = type.includes("multiple");

      if (multiple) {
         for (var i = 0; i < options_length; i++) {
            retVal.push("0");
         }
      }

      return retVal;
   }

   function handleAnswerRequest(data, idx) {
      var asd = [...answerSheetData];
      var ad = [...answerData];

      // var multiple = asd[idx].type.includes("multiple");
      // if (multiple) {
      //    asd[idx].answer = []; asd[idx].answer.push.apply(asd[idx].answer, data);
      //    ad[idx].answer = []; ad[idx].answer.push.apply(ad[idx].answer, data);
      // } else {
      //    asd[idx].answer = []; asd[idx].answer[0] = data[0];
      //    ad[idx].answer = []; ad[idx].answer[0] = data[0];
      // }

      var section = asd[idx].type.includes("section");

      if (section) {
         ad[idx].correct = [];
         ad[idx].correct.push.apply(ad[idx].correct, asd[idx].correct);
      }

      // asd[idx].answer = [];
      // asd[idx].answer.push.apply(asd[idx].answer, data);
      // ad[idx].answer = [];
      // ad[idx].answer.push.apply(ad[idx].answer, data);

      var multiple = ad[idx].type.includes("multiple");
      var matching = ad[idx].type.includes("matching");

      asd[idx].answer = [];
      ad[idx].answer = [];

      if (multiple || matching) {
         asd[idx].answer.push.apply(asd[idx].answer, data);
         ad[idx].answer.push.apply(ad[idx].answer, data);
      } else {
         asd[idx].answer.push(data);
         ad[idx].answer.push(data);
      }

      // localStorage.clear();

      // //-- Save answer to local storage after every answer's filled
      localStorage.removeItem(user_id + "_" + assInfo.Id + "_myanswers");
      localStorage.setItem(user_id + "_" + assInfo.Id + "_myanswers", JSON.stringify(ad));

      sectionIdx = 1;
      qcardIdx = 1;
      idxCol = [];

      setAnswerSheetData(asd);
      setAnswerData(ad);

      // message.info(JSON.stringify(answerData));
   }

   const handleSubmit = () => {
      setIsSubmitted(true);

      try {
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";

         let timestampz = new Date().getTime();

         var ansInfo = {
            Id: "ans_" + o2o_mode + "_" + uuid() + "_" + timestampz + "_" + user_id,
            Assessment_id: assInfo.Id,
            Assignment_id: assignInfo.id,
            Student_id: user_id,
            User_agent: platform.ua,
            Browser: platform.name,
            Browser_version: platform.version,
            OS_platform: platform.os.family,
            Device: platform.product,
            Answer: JSON.stringify(answerData.length > 0 ? answerData : JSON.parse(localStorage.getItem(user_id + "_" + assInfo.Id + "_myanswers"))),
            Submit_status: 1,
            Score: 0,
            Essay_score: 0,
            Expiration: null,
            Status: -1,
         }

         const updateAnsUrl = `${process.env.REACT_APP_API_ASSESSMENT}/updateanswer?at=summative&cache=${uuid()}`;
         axios.post(updateAnsUrl, ansInfo).then(response => {
            if (response.data) {
               const data = response.data;

               localStorage.removeItem(user_id + "_" + assInfo.Id + "_timestarted");
               localStorage.removeItem(user_id + "_" + assInfo.Id + "_myanswers");

               // localStorage.clear();
               setIsSubmitted(false);

               Modal.success({
                  title: 'Summative Assessment',
                  content: 'Your assessment answers was successfully submitted.',
                  centered: true,
                  onOk: handleNavigate()
               });
            }

            console.log(response.data);
         }, (error) => {
            setIsSubmitted(false);

            Modal.error({
               title: 'Summative Assessment',
               content: 'Submission of assessment answers failed.',
               centered: true
            });
         });
      } catch (error) {
         console.log(error);
         Modal.error({
            title: 'Summative Assessment',
            content: 'Submission of assessment answers failed.',
            centered: true
         });
      }


   }

   const handleNavigate = () => {
      if (assignInfo.allow_result_viewing === 1)
         navigate('/studentassessment/view-ans-pdf', { state: { assessment_id: assInfo.Id, assignInfo, student_id: user_id } });
      else
         navigate('/studentassessment', { state: { activetab: "1" } });
   }

   const handleTimeOut = () => {
      Modal.info({
         title: 'Summative Assessment',
         content: 'Your time is up!',
         centered: true,
         onOk: () => handleSubmit(),
      });
   }

   function sPDFFile() {
      pdfFile = assInfo.File_url;
      return true;
   }

   const viewerOptions = {
      // embedMode: "SIZED_CONTAINER",
      defaultViewMode: "FIT_WIDTH",
      showAnnotationTools: false,
      showLeftHandPanel: false,
      showPageControls: true,
      showDownloadPDF: false,
      showPrintPDF: false,
      showFullScreen: false,
      showZoomControl: true
   };

   // function fetchPDF(urlToPDF) {
   //    return new Promise((resolve) => {
   //       fetch(urlToPDF)
   //          .then((resolve) => resolve.blob())
   //          .then((blob) => {
   //             resolve(blob.arrayBuffer());
   //          })
   //    })
   // }

   // const LoadPDFViewer = (url) => {
   //    var loadingStarted = Date.now();

   //    var adobeDCView = new window.AdobeDC.View({
   //       clientId: `${process.env.REACT_APP_API_PDF_KEY}`,
   //       divId: "pdf-div"
   //    });

   //    var previewFilePromise = adobeDCView.previewFile(
   //       {
   //          // content: { promise: fetchPDF(url) },
   //          content: {
   //             location: {
   //                url: url,
   //             },
   //          },
   //          // metaData: { fileName: url.split("/").slice(-1)[0] }
   //          metaData: {
   //             fileName: "Brainee LMS Document",
   //             id: uuid(),
   //          },
   //       },
   //       viewerOptions
   //    );

   //    const eventOptions = {
   //       listenOn: [window.AdobeDC.View.Enum.Events.APP_RENDERING_DONE, window.AdobeDC.View.Enum.Events.APP_RENDERING_FAILED],
   //       enableFilePreviewEvents: true
   //    }

   //    adobeDCView.registerCallback(
   //       window.AdobeDC.View.Enum.CallbackType.EVENT_LISTENER,
   //       function (event) {
   //          console.log(event.type);

   //          if (event.type === "APP_RENDERING_DONE") {
   //             if (startTime === null) {
   //                var val = Date.now(); // - loadingStarted;
   //                localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
   //                setStartTime(val);
   //             } else {
   //                if (assignInfo.enable_timer) {
   //                   var timer = startTime + (assignInfo.duration * 60 * 1000);
   //                   var now = Date.now();

   //                   if (now > timer) {
   //                      handleTimeOut();
   //                   }
   //                }
   //             }

   //             setPdfLoaded(true); //-- This will also set the start of the count down timer
   //          }

   //          if (event.type === 'APP_RENDERING_FAILED' && assignInfo.enable_timer) {
   //             // if (startTime !== null) {
   //             //    var val = Date.now() - loadingStarted;
   //             //    localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
   //             // } else {

   //             // }
   //          }
   //       }, eventOptions
   //    );
   // }

   const setupTimer = () => {
      if (startTime === null) {
         var val = Date.now();
         localStorage.setItem(user_id + "_" + assInfo.Id + "_timestarted", JSON.stringify(val));
         setStartTime(val);
      } else {
         if (assignInfo.enable_timer) {
            var timer = startTime + (assignInfo.duration * 60 * 1000);
            var now = Date.now();
            if (now > timer) {
               handleTimeOut();
            }
         }
      }

      setPdfLoaded(true);
   }

   return (
      <>
         <div className="dashboard-area-all" style={{ paddingRight: `0px` }}>
            <Split
               sizes={[60, 40]}
               direction="horizontal"
               cursor="col-resize"
               className="split-flex"
               style={{ height: `calc(100vh - 2rem)` }}
            >
               <div>
                  {assInfo.File_url
                     ?
                     <div className="dashboard-item">
                        <div className="dashboard-item-inner">
                           {/* {
                              status !== '0'
                                 ?
                                 <ul>
                                    <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                                    <li><span>/</span></li>
                                    <li className="active">Brainee LMS</li>
                                    <li><span>/</span></li>
                                    <li><a href="#" onClick={() => navigate('/studentassessment', { state: { activetab: "1" } })}>Summative Assessments</a></li>
                                    <li><span>/</span></li>
                                    <li className="active">Answer</li>
                                 </ul>
                                 :
                                 <ul>
                                    <li><a href="#" onClick={() => navigate('/studentassessment', { state: { activetab: "1" } })}>Examination</a></li>
                                    <li><span>/</span></li>
                                    <li className="active">Answer</li>
                                 </ul>
                           } */}

                           <Breadcrumb separator=">" style={{ paddingBottom: '15px' }} >
                           {
                              status !== '0'
                              ?
                              <>
                                 <Breadcrumb.Item>
                                    <Link to="/home">Home</Link>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                                 <Breadcrumb.Item>
                                    <Link to="/studentassessment">Summative Assessments</Link>
                                 </Breadcrumb.Item>
                                 <Breadcrumb.Item>Answer</Breadcrumb.Item>
                              </>
                              :
                              <>
                                 <Breadcrumb.Item>Answer</Breadcrumb.Item>
                              </>
                           }
                           </Breadcrumb>
                        </div>

                        <div className="pdf-all-page-container">
                           <div style={{ width : "100%", height:"calc(100vh - 58px)" }}>
                              <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                 <Viewer 
                                    pageLayout={{transformSize: ({ size }) => ({
                                       height: size.height + 30,
                                       width: size.width + 30,
                                    }),}}  
                                    fileUrl={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}  
                                    defaultScale={SpecialZoomLevel.PageWidth}   
                                    initialPage={0} 
                                 />
                              </Worker>
                           </div>
                           {/* <div className='pdf_container'>
                              <SimpleBarReact style={{ height: '100%' }}>
                                 <PDFReader
                                    url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`}
                                    showAllPage={true}
                                    scale={1.3}
                                    onDocumentComplete={() => setupTimer()}
                                 />
                              </SimpleBarReact>
                           </div>

                           <div className='pdf_container pdf_overlay' style={{ display: pdfLoaded ? 'none' : '' }}>
                              <div className='temp-answer-sheet-content2'>
                                 <Spin size='large' tip="Loading, please wait..." />
                              </div>
                           </div> */}
                           {/* <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${assInfo.File_url}`} height={"calc(100vh - 58px)"} load={pdfFile !== assInfo.File_url ? sPDFFile() : false} /> */}
                           {/* <div className="mt-10" >
                              <div id="pdf-div" className="border border-gray-100" style={{ height: 'calc(100vh - 58px)', borderRadius: "7px" }} ></div>
                           </div> */}
                        </div>

                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>

               <div>
                  {answerSheetData
                     ?
                     <div className="notice-item">
                        <div className='answer-sheet-content'>
                           <SimpleBarReact>
                              <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "5px", width: "100%" }} column={4}>
                                 <Descriptions.Item label={"Name"} span={4} style={{ textAlign: "center" }}>
                                    <Paragraph style={{ textAlign: "center", width: "100%" }}><b>{name}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Assessment Title"} span={4} style={{ textAlign: "center" }}>
                                    <Paragraph style={{ textAlign: "center" }}><b>{assInfo.Title}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Date"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                    <Paragraph style={{ textAlign: "center" }}><b>{assInfo.Date_created}</b></Paragraph>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Timer"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                    <Paragraph>
                                       {
                                          assignInfo.enable_timer === 1
                                             ?
                                             pdfLoaded === true
                                                ?
                                                <b>
                                                   <Countdown
                                                      autoStart={pdfLoaded}
                                                      controlled={false}
                                                      date={startTime + (assignInfo.duration * 60 * 1000)}
                                                      onComplete={() => handleTimeOut()}
                                                   >
                                                      <b>Your time is up!</b>
                                                   </Countdown>
                                                </b>
                                                :
                                                <b>...</b>
                                             :
                                             <b>No Time Limit</b>
                                       }
                                    </Paragraph>
                                 </Descriptions.Item>
                              </Descriptions>
                              <StickyBox style={{ zIndex: 1000 }}>
                                 <Popconfirm
                                    title="Have you completed your answers?"
                                    onConfirm={() => handleSubmit()}
                                    // onCancel={cancel}
                                    okText="Yes"
                                    cancelText="No"
                                    icon={<QuestionCircleOutlined />}
                                 >
                                    <Button className='button-shadow' type='primary' block shape='round' style={{ marginBottom: "15px" }} disabled={isSubmitted}>
                                       <Text ellipsis style={{ color: "#fff" }}><SendOutlined />&nbsp;Submit Answer</Text>
                                    </Button>
                                 </Popconfirm>
                              </StickyBox>

                              <ul className="questions" style={{ paddingLeft: "0px" }}>
                                 {answerSheetData.map(({ id, type, points, option_labels, question, image_url }, idx) => {

                                    if (type === "section") {
                                       qcardIdx = 1;
                                       idxCol.push(sectionIdx);
                                       sectionIdx++;
                                    } else {
                                       idxCol.push(qcardIdx);
                                       qcardIdx++;
                                    }

                                    return (
                                       <li>
                                          <BraineeQuestionCard
                                             key={id} 
                                             index={idxCol[idx]} 
                                             id={idx} 
                                             type={type} 
                                             points={points} 
                                             correct={answerData[idx].answer} 
                                             option_labels={option_labels} 
                                             question={question} 
                                             image_url={image_url} 
                                             question_type={'1'} 
                                             mode={'answer'} 
                                             answerCallback={handleAnswerRequest} 
                                             assessment_type={'summative'} 
                                          />
                                       </li>
                                    );
                                 })}
                              </ul>
                           </SimpleBarReact>
                        </div>
                     </div>
                     :
                     <div className='temp-answer-sheet-content2'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  }
               </div>
            </Split>
         </div>
      </>
   )
}
