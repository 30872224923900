import React, { useContext, useState, useEffect } from "react"
import ReactHtmlParser from "react-html-parser"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import { CanvasContext } from "../hooks/LessonPage"
import { Button, Modal, Drawer } from 'antd';
import "./TextElements.scss"


const TextElement = props => {
   const { metadata, id, iseeadonly, dimension, position, type } = props
   const { state, actions } = useContext(CanvasContext)
   const [open, setOpen] = useState(false);
   const [text, setText] = useState("");
   const [targetRef, setTargetRef] = useState(null)
   const elementRef = React.useRef(null)
   const [showGrids, setShowGrids] = React.useState(false)


   const Quill = ReactQuill.Quill;
   var Font = Quill.import("formats/font");
   Font.whitelist = ["Audiowide", "Freeman", "Honk", "Inter", "Italianno", "Monoton", "Pacifico", "Righteous", "Roboto", "Sevillana", "Tangerine", "Ubuntu", "Ultra", "Michroma"];
   Quill.register(Font, true);

   const fontSizeArr = ['8px', '9px', '10px', '12px', '14px', '16px', '20px', '24px', '32px', '42px', '54px', '68px', '84px', '98px'];

   var Size = Quill.import('attributors/style/size');
   Size.whitelist = fontSizeArr;
   Quill.register(Size, true);

   var modules = {
      toolbar: [
         [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
         [{ size: fontSizeArr }],
         ["bold", "italic", "underline", "strike", "blockquote"],
         [{ color: [] }, { background: [] }],
         [{ align: [] }],
         [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
         ],
         ["link", "image", "video"],
         [{ script: "sub" }, { script: "super" }],
      ],
   };

   var formats = [
      "header",
      "font",
      "size",
      "bold",
      "italic",
      "underline",
      "strike",
      "blockquote",
      "background",
      "code",
      "script",
      "list",
      "bullet",
      "indent",
      "link",
      "image",
      "video",
      "color"
   ];


   useEffect(() => {
      setText(metadata.content)
   }, []);




   const onClick = (e) => {
      e.stopPropagation();
      e.preventDefault()
      actions?.setTargetRef(elementRef)
      actions?.setCurrentElementId(id)
      var activeContent = { id: id, type: type, metadata: metadata, dimension: dimension, position: position }
      actions?.setActiveEditable(activeContent)
   }

   const textDoubleClick = (e) => {
      e.stopPropagation();
      setText("")
      setOpen(true)

      setTimeout(function () {
         setText(metadata.content)
      }, 100);

      setTargetRef(state?.targetRef)
      actions.setTargetRef(null)
   }

   const showModal = () => {
      setOpen(true);
   };

   const handleOk = () => {
      setOpen(false);
      actions.setTargetRef(targetRef)
      var contentData = { id: id, content: text }
      actions.updateCanvasData(contentData)
   };

   const handleCancel = () => {
      setOpen(false);
      actions.setTargetRef(targetRef)
      // var contentData = { id :  id, content: text }
      // actions.updateCanvasData(contentData)
   };

   const onEnter = (e, value) => {
      e.stopPropagation()
      e.preventDefault()
      setShowGrids(value)
   }

   const textChange = (text) => {
      setText(text)
   }

   const CustomToolbar = () => (
      <>
         <div id="toolbar" className="toolbar">
            <select className="ql-header" defaultValue={""} onChange={e => e.persist()}>
               <option value="1" />
               <option value="2" />
               <option selected />
            </select>
            <button className="ql-bold" />
            <button className="ql-italic" />
            <select className="ql-color">
               <option value="red" />
               <option value="green" />
               <option value="blue" />
               <option value="orange" />
               <option value="violet" />
               <option value="#d0d1d2" />
               <option selected />
            </select>
         </div>
      </>

   );

   const Editor = () => {
      return (
         <>
            {/* <CustomToolbar/> */}
            <div style={{
               justifyContent: "center", margin: "auto", height: "calc(100vh - 250px)",
               // border: "1px solid #80bdff",
               boxShadow: "0 0 0 0.1rem rgba(51, 52, 52, 0.25)",
               backgroundColor: "#ccc",
               overflow: "auto"
            }}>
               {/* <div> */}
               <ReactQuill
                  style={{
                     // height: "calc(100vh - 530px)",
                     width: "100%",
                     height: "calc(100vh - 339px)",
                     // border : "1px solid black",
                     backgroundSize: "auto 100%",
                     backgroundRepeat: "no-repeat",
                     marginBottom: "10px",
                     // paddingBottom : "10px"

                  }}
                  theme='snow'
                  value={text}
                  onChange={textChange}
                  modules={modules}
                  formats={formats}
                  placeholder='Loading ...'
               />
               {/* </div> */}

            </div>
            <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: '15px' }}>
               <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleOk()} style={{ marginRight: "5px" }} >
                  Update Text
               </Button>
               <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => setOpen(false)} >
                  Cancel
               </Button>
            </div>
         </>
      )
   }


   return (
      <>
         <div
            ref={elementRef}
            onMouseEnter={(e) => onEnter(e, true)}
            onMouseLeave={(e) => onEnter(e, false)}
            onClick={(e) => onClick(e)}
            onDoubleClick={(e) => textDoubleClick(e)}
            style={{
               position: "absolute",
               left: position?.left || 0,
               top: position?.top || 0,
               width: dimension?.width || 0,
               height: dimension?.height || 0,
               transform: dimension?.transform || "translate(1px, 1px)",
               fontFamily: "Arial",
               fontSize: "13px",
               padding: 0,
               float: "left",
               border: `2px solid ${showGrids
                  ? "#21DEE5"
                  : "transparent"
                  }`,
            }}
         >
            <div style={{ height: "100%", width: "100%", textAlign: "left", wordWrap: "break-word" }}>
               {ReactHtmlParser(metadata.content || "")}
            </div>
         </div>

         {/* <Modal
            title="Edit Text"
            centered
            destroyOnClose={true}
            open={open}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelButtonProps={{ shape: "round" }}
            okButtonProps={{ shape: "round" }}      
      >
         {Editor()}
      </Modal>       */}
         <Drawer title="Update Text" onClose={handleCancel} open={open}>
            {Editor()}
         </Drawer>
      </>
   )
}

export default TextElement
