/* eslint-disable react/style-prop-object */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Utils from '../../../misc/Utils';
import { Empty, Spin } from 'antd';

export function Math10Report(props) {
   const [reportData, setReportData] = useState(null);
   const [patternsAndAlgebra, setPatternsAndAlgebra] = useState(null);
   const [geometry, setGeometry] = useState(null);
   const [statisticsAndProbability, setStatisticsAndProbability] = useState(null);
   const [dataLoading, setDataLoading] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      getReport(props.subject_id, props.assignment_id, props.student_id, props.template)
   }, []);

   const getReport = (subject, assignment_id, student_id, template) => {
      setDataLoading(true);
      var url = `${process.env.REACT_APP_API_CPH}/getdiagnosticreport?s=${subject}&ai=${assignment_id}&si=${student_id}&t=${template}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data[0];

            if (data !== undefined) {
               setPatternsAndAlgebra(data.s1 + data.s2 + data.s3 + data.s4 + data.s5 + data.s6 + data.s7 + data.s8 + data.s9 + data.s10 +
                  data.s11 + data.s12 + data.s13 + data.s14 + data.s15 + data.s16 + data.s17 + data.s18 + data.s19 + data.s20 + data.s21);
               setGeometry(data.s22 + data.s23 + data.s24 + data.s25 + data.s26 + data.s27 + data.s28 + data.s29 + data.s30);
               setStatisticsAndProbability(data.s31 + data.s32 + data.s33 + data.s34 + data.s35 + data.s36 + data.s37 + data.s38 +
                  data.s39 + data.s40 + data.s41 + data.s42 + data.s43 + data.s44 + data.s45);

               setReportData(data);
            }

            setDataLoading(false);
         }
      }, (error) => {
         setDataLoading(false);
         console.log(error.message);
      });
      // }
   }

   return (
      <>
         {
            dataLoading
               ?
               <div className='center-empty-space'>
                  <Spin size='large' tip="Loading data, please wait..." />
               </div>
               :
               reportData !== null
                  ?
                  <div className="dashboard-item-inner3" style={{ padding: '7px' }}>
                     <div style={{ fontFamily: 'calibri' }} id='individual-report'>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '115%', fontSize: '14pt' }}><strong>Results in Math Grade 10</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', lineHeight: '115%', borderBottom: '0.75pt solid #000000', paddingBottom: '1pt', fontSize: '12pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ width: '100%', borderCollapse: 'collapse' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '50.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>
                                 </td>
                                 <td style={{ width: '28.64%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Grade Level:&nbsp;</strong>Grade 10</p>
                                 </td>
                                 <td style={{ width: '20.52%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Age:</strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '50.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>School:&nbsp;</strong>{reportData.school_name}</p>
                                 </td>
                                 <td style={{ width: '28.64%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Section:&nbsp;</strong>{reportData.section}</p>
                                 </td>
                                 <td style={{ width: '20.52%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '50.84%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>Date:&nbsp;</strong>{reportData.diagnostic_date}</p>
                                 </td>
                                 <td style={{ width: '28.64%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                                 <td style={{ width: '20.52%', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '12pt' }}><strong>&nbsp;</strong></p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <div style={{ borderTop: '0.75pt solid #000000', clear: 'both' }}>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', paddingTop: '1pt', fontSize: '10pt' }}>&nbsp;</p>
                           <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}>The K to 12 Learning Assessment Package determines how much the learners have benefited from the instruction of the grade level and subject in this result. The assessment determines a set of standard competencies based on the National Curriculum. These learning competencies are classified according to the content standards as presented in the results. When the learning competency is already taken up in class, the purpose of the assessment is to determine the learners’ achievement or what they have learned from instruction. If the learning competency tested is not yet taken, the results serve as diagnostic or determine what students’ know and does not know so that necessary adjustment in the teaching and learning process can be prepared.</p>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt', lineHeight: '115%', fontSize: '10pt' }}><strong>Competency Score.</strong>&nbsp; Each item in the test is aligned to a particular learning competency. If the mark is “1”, the learner is able to meet the competency. If the mark is “0”, the learner is not able to meet that competency. The percentage beside the competency score is the percent of the entire level who is able to meet the learning competency.</p>
                        <table cellSpacing={0} cellPadding={0} style={{ border: '0.75pt solid #000000', borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '58.4pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>0% - 40%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>Very few learners are able to meet the learning competency. The learners need to review the lessons again.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>41%- 60%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>About half of the learners are able to meet the learning competency. The learners need to review the lessons again.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>61% - 80%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', borderBottomStyle: 'solid', borderBottomWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>Majority of the learners are able to meet the learning competency. The remaining students can work on supplementary learning resources to study.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '58.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderRightStyle: 'solid', borderRightWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>81% - 100%</p>
                                 </td>
                                 <td style={{ width: '446.4pt', borderTopStyle: 'solid', borderTopWidth: '0.75pt', borderLeftStyle: 'solid', borderLeftWidth: '0.75pt', paddingRight: '5.03pt', paddingLeft: '5.03pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '10pt' }}>A large number of learners are able to meet the learning competency. The remaining learners can be provided with extra support.</p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '12pt' }}><strong>Competency Score</strong></p>
                        <div style={{ textAlign: 'center' }}>
                           <table cellSpacing={0} cellPadding={0} style={{ marginRight: 'auto', marginLeft: 'auto', borderCollapse: 'collapse', width: '100%' }}>
                              <tbody>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>&nbsp;</span></strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Learning Competency</span></strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Score</span></strong></p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle', backgroundColor: '#000000' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct for the Level</span></strong></p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Patterns and Algebra</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>1</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Generates patterns.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s1}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s1}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>2</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Determines arithmetic means and nth term of an arithmetic sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s2}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s2}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>3</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates a geometric sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s3}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s3}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>4</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Differentiates a geometric sequence from an arithmetic sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s4}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s4}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>5</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Differentiates a finite geometric sequence from an infinite geometric sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s5}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s5}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>6</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Determines geometric means and nth term of a geometric sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s6}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s6}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>7</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Finds the sum of the terms of a given finite or infinite geometric sequence.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s7}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s7}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>8</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving sequences.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s8}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s8}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>9</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Performs division of polynomials using long division and synthetic division.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s9}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s9}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>10</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Performs division of polynomials using long division and synthetic division.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s10}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s10}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>11</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Proves the Remainder Theorem and the Factor Theorem.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s11}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s11}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>12</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Factors polynomials.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s12}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s12}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>13</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Factors polynomials.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s13}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s13}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>14</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Factors polynomials.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s14}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s14}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>15</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Proves Rational Root Theorem.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s15}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s15}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>16</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates polynomial equations (in a graph).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s16}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s16}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>17</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves polynomial equations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s17}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s17}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>18</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves polynomial equations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s18}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s18}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>19</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving polynomials and polynomial equations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s19}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s19}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>20</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates polynomial functions (in a graph).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s20}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s20}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>21</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving polynomial functions.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s21}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s21}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Geometry</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>22</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Proves theorems related to chords, arcs, central angles, and inscribed angles.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s22}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s22}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>23</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates secants, tangents, segments, and sectors of a circle.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s23}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s23}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>24</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Proves theorems on secants, tangents, and segments</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s24}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s24}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>25</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems on circles.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s25}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s25}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>26</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates secants, tangents, segments, and sectors of a circle.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s26}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s26}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>27</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Proves theorems on secants, tangents, and segments</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s27}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s27}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>28</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Determines the center and radius of a circle given its equation and vice versa.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s28}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s28}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>29</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates the center-radius form of the equation of a circle.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s29}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s29}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>30</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving geometric figures on the coordinate plane.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s30}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s30}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>&nbsp;</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}><strong>Statistics and Probabiltiy</strong></p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>&nbsp;</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>31</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates the permutation of objects.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s31}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s31}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>32</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving permutations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s32}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s32}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>33</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Differentiates permutation from combination of n objects taken r at a time.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s33}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s33}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>34</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving permutations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s34}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s34}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>35</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates events, and union and intersection of events.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s35}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s35}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>36</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving permutations and combinations.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s36}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s36}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>37</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Finds the probability of (A U B).</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s37}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s37}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>38</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates the probability of a union of two events.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s38}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s38}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>39</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving probability.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s39}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s39}%</p>
                                    </td>
                                 </tr>

                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>40</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates independent events.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s40}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s40}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>41</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Calculates a specified measure of position (e.g. 90th percentile) of a set of data.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s41}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s41}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>42</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Illustrates the following measures of position: quartiles, deciles and percentiles.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s42}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s42}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>43</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Solves problems involving measures of position.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s43}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s43}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>44</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Interprets measures of position.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s44}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s44}%</p>
                                    </td>
                                 </tr>
                                 <tr>
                                    <td style={{ width: '12.6pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>45</strong></p>
                                    </td>
                                    <td style={{ width: '382.3pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt', textAlign: 'left' }}>Uses appropriate measures of position and other statistical methods in analyzing and interpreting research data.</p>
                                    </td>
                                    <td style={{ width: '31.7pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'middle' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.s45}</p>
                                    </td>
                                    <td style={{ width: '81pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'bottom' }}>
                                       <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>{reportData.percent_correct_s45}%</p>
                                    </td>
                                 </tr>
                              </tbody>
                           </table>
                        </div>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal', fontSize: '10pt' }}><a name="_Hlk103365502"><strong>Summary of Results.</strong>&nbsp; The result for the content standards is expressed in terms of the raw score, percent correct, standard score, and proficiency level.&nbsp;</a></p>
                        <ul type="disc" style={{ margin: '0pt', paddingLeft: '0pt' }}>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Raw Score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The raw score is the total number of correct answers for the content standard.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Percent Correct.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span><span style={{ fontFamily: 'Calibri' }}>The percent correct is the raw score converted into percentage. The percent correct provides you with an easier way to determine how many correct answers you are able to obtain for each content standard. If the percent correct is close to 100%, the more correct answers you are able to attain.</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Standard score.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The scores are converted into a standard score to allow comparison of scores across content standards and across levels. A standard score near 0.0 means that the learner’s standing in the content standard is within the majority of the test takers. A standard score of more than 1.00 mean that the attainment of the competencies is high because there are several correct answers.&nbsp;</span></li>
                           <li style={{ marginLeft: '9.6pt', lineHeight: 'normal', paddingLeft: '8.4pt', fontFamily: 'serif', fontSize: '10pt' }}><strong><span style={{ fontFamily: 'Calibri' }}>Proficiency Level.</span></strong><span style={{ fontFamily: 'Calibri' }}>&nbsp;The proficiency level describes your present level of attainment on content standard. The levels of proficiency start from beginner to basic to competent. The beginner level means few correct answers attained in the content standard. The basic level means that the score is within the majority of the test takers. Competent means that several correct answers are attained.</span><span style={{ fontFamily: 'Calibri' }}>&nbsp;&nbsp;</span></li>
                        </ul>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>&nbsp;</strong></p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>Summary of Results</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Areas</span></strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Raw Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Percent Correct</span></strong></p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', paddingRight: '4.9pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                              </tr>

                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Patterns and Algebra</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          patternsAndAlgebra
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((patternsAndAlgebra / 21) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((patternsAndAlgebra - 5.47) / 1.62).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((patternsAndAlgebra - 5.47) / 1.62) > 1
                                             ? 'Competent'
                                             : (((patternsAndAlgebra - 5.47) / 1.62) >= -0.51
                                                ? 'Basic Level'
                                                : (((patternsAndAlgebra - 5.47) / 1.62) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Geometry</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          geometry
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((geometry / 9) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((geometry - 5.47) / 1.62).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((geometry - 5.47) / 1.62) > 1
                                             ? 'Competent'
                                             : (((geometry - 5.47) / 1.62) >= -0.51
                                                ? 'Basic Level'
                                                : (((geometry - 5.47) / 1.62) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Statistics and Probability</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          statisticsAndProbability
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((statisticsAndProbability / 15) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((statisticsAndProbability - 8.19) / 2.17).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          ((statisticsAndProbability - 8.19) / 2.17) > 1
                                             ? 'Competent'
                                             : (((statisticsAndProbability - 8.19) / 2.17) >= -0.51
                                                ? 'Basic Level'
                                                : (((statisticsAndProbability - 8.19) / 2.17) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '197.1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Overall Math</strong></p>
                                 </td>
                                 <td style={{ width: '79.2pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          geometry + patternsAndAlgebra + statisticsAndProbability
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '61.2pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((geometry + patternsAndAlgebra + statisticsAndProbability) / 45) * 100).toFixed(2)
                                       }%
                                    </p>
                                 </td>
                                 <td style={{ width: '59.9pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((geometry + patternsAndAlgebra + statisticsAndProbability) - 27.52) / 4.47).toFixed(2)
                                       }
                                    </p>
                                 </td>
                                 <td style={{ width: '99.4pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', textAlign: 'center', fontSize: '11pt' }}>
                                       {
                                          (((geometry + patternsAndAlgebra + statisticsAndProbability) - 27.52) / 4.47) > 1
                                             ? 'Competent'
                                             : ((((geometry + patternsAndAlgebra + statisticsAndProbability) - 27.52) / 4.47) >= -0.51
                                                ? 'Basic Level'
                                                : ((((geometry + patternsAndAlgebra + statisticsAndProbability) - 27.52) / 4.47) > -10 ? 'Beginner' : ''))
                                       }
                                    </p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '0pt', lineHeight: 'normal' }}><strong>Interpretation&nbsp;</strong></p>
                        <table cellSpacing={0} cellPadding={0} style={{ borderCollapse: 'collapse', width: '100%' }}>
                           <tbody>
                              <tr>
                                 <td style={{ width: '107.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Proficiency Level</span></strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Range of Standard Score</span></strong></p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top', backgroundColor: '#000000' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong><span style={{ color: '#ffffff' }}>Interpretation</span></strong></p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Beginner</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>Less than -0.5</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>There are several competencies that the student did not master and may need thorough instructional support.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Basic Level</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>-0.51 to 1.0</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>The student has learned sufficient competencies to move to the succeeding level of learning. Needs instructional support to move to the next level.</p>
                                 </td>
                              </tr>
                              <tr>
                                 <td style={{ width: '107.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '4.9pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}><strong>Competent Level</strong></p>
                                 </td>
                                 <td style={{ width: '133.2pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '5.4pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>1.00 and above</p>
                                 </td>
                                 <td style={{ width: '278.1pt', borderTopStyle: 'solid', borderTopWidth: '1pt', borderRightStyle: 'solid', borderRightWidth: '1pt', borderBottomStyle: 'solid', borderBottomWidth: '1pt', borderLeftStyle: 'solid', borderLeftWidth: '1pt', paddingRight: '4.9pt', paddingLeft: '5.4pt', verticalAlign: 'top' }}>
                                    <p style={{ marginTop: '0pt', marginBottom: '0pt', fontSize: '11pt' }}>The student has mastered the required competencies and needs little instruction to learn succeeding lessons.</p>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}>&nbsp;</p>
                        <p style={{ marginTop: '0pt', marginBottom: '10pt' }}><strong>Name:&nbsp;</strong>{reportData.student_name}</p>

                     </div>
                  </div>
                  :
                  <div className='center-empty-space'>
                     <Empty
                        description='No Data Found'
                        image="../images/brainee_mascot.png"
                     />
                  </div>
         }
      </>
   );
}