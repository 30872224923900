
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.less';
import './init';
import Dashboard from './components/dashboard/DashboardPage';
import Schedules from './components/schedule/Schedules';
// import SideMenu from './components/side-menu/SideMenu';
// import SideMenuSmallScreen from './components/side-menu/SideMenuSmallScreen';
import {
   Assessment, CreatePDF, CreateManual, EditPDF, EditManual, AnswerPDF, AnswerManual, EditAssignPDF, ViewPDF, ViewManual,
   StudentAssessment, StudentViewPDF, StudentViewManual, Tracker, TeacherViewPDF, TeacherViewManual,
   PreviewPDF, PreviewManual, StudentPreviewPDF, StudentPreviewManual
} from './pages/AssessmentPage';
import {
   MasteryContents, MasteryCreate, MasteryEdit, MasteryPreview, MasteryPreviewPDF, MasteryPreviewManual,
   MasteryAssessment, MasteryCreatePDF, MasteryCreateManual, MasteryEditPDF, MasteryEditManual, MasteryStudyPlan, MasteryTracker,
   MasteryStudentAssessment, MasteryStudentPreview, MasteryTeacherViewPDF, MasteryTeacherViewManual, MasteryStudentViewPDF, MasteryStudentViewManual,
   MasteryStudentViewResult, MasteryAnswerPDF, MasteryAnswerManual, MasteryRecommendation, MasteryInstruction,
   MasteryAssessmentReport, MasteryReportType1, MasteryReportType2, MasteryReportType3, MasteryReportType4, MasteryReportType5,
   MasteryAssessmentSubject, MasterySubjects,
} from './pages/MasteryAssessmentPage';
import {
   FormativeAssessment, FormativeCreateManual, FormativeCreatePDF, FormativeEditManual, FormativeEditPDF,
   FormativeAnswerManual, FormativeAnswerPDF, FormativePreviewManual, FormativePreviewPDF,
   FormativeAssignManual, FormativeTracker, FormativeViewManual, StudentFormativeAssessment,
   StudentFormativeViewManual, StudentFormativeViewPDF, TeacherFormativeViewPDF, TeacherFormativeViewManual
} from './pages/FormativeAssessmentPage';
import { SubjectEdit, SubjectList, SubjectView, SubjectGroupings } from './pages/SubjectPage';
// import CreateLessonStep from './components/lesson/createlesson/CreateLessonStep';
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import NoticeList from './components/notice-board/NoticeList';
import Login from './pages/LoginPage/Login';
import CreateNotice from './components/notice-board/create/CreateNotice';
// import EditLessonStep from './components/lesson/editlesson/EditLessonStep';
import EditNotice from './components/notice-board/edit/EditNotice';
import { Homeworks, StudentHomeworks } from './pages/HomeworksPage';
import Profile from './components/profile/Profile';
import { ReportCards, StudentReportCards, Certificates, StudentCertificates } from './pages/ReportCardsPage';
import { StudentInfo, StaffInfo, PromoteStudents, Sections, Levels, Subjects, SubjectGroups } from './pages/ManagementPage';
import { ClassSchedule, LessonCalendar, SchoolCalendar } from './pages/SchedulePage';
import { SurveyAssign, Survey } from './pages/SurveyPage';
import { Attendance } from './pages/AttendancePage';
import {
   DiagnosticAssessment, StudentDiagnosticAssessment, DiagnosticReport,
   BDiagnostic, BCreatePDF, BEditPDF, BCreateManual, BEditManual, BPreviewPDF, BPreviewManual,
   BViewManual, BViewPDF, BDiagnosticTracker, BStudentDiagnostic, BAnswerPDF, BAnswerManual,
   BStudentViewManual, BStudentViewPDF, BTeacherViewPDF, BTeacherViewManual,
   BDiagnosticReport
} from './pages/DiagnosticAssessmentPage';
import { ApplicationList, ReviewApplication, Interview } from './pages/ApplicationPage';

import SideMenuNew from './components/side-menu/SideMenuNew';
import SideMenuSmallScreenNew from './components/side-menu/SideMenuSmallScreenNew';
import LessonCreatiionForm from './components/lesson/createlesson/LessonCreationForm';
import Registration from './pages/RegistrationPage/Registration';
// import { EditLessonPage } from './pages/LessonPage2.0/EditLessonPage';
// import { LessonPage } from './pages/LessonPage2.0/LessonPage';
import { LessonPage } from './pages/LessonPage2.0/hooks/LessonPage';
import { LessonEditView } from './pages/LessonPage2.0/LessonEditView/LessonEditView';
import Application from './pages/ApplicationPage/Application';
import PrivacyPolicy from './pages/LoginPage/PrivacyPolicy';
import LessonViewerTest from './pages/LessonPage2.0/Viewer/LessonViewerTest';

function App() {
   // const lesson = useSelector(state => state.lesson);
   const pathname = window.location.pathname
   // const ass_options = useSelector(state => state.assOption)

   return (
      <div>
         {(pathname !== "/" && pathname !== "/application" && pathname !== "/privacy-policy") ?
            <>
               <SideMenuNew />
               <SideMenuSmallScreenNew />
               {/* <SideMenu />
               <SideMenuSmallScreen /> */}
               <main className="main-wrapper">
                  <Routes>
                     <Route path="/home" element={<Dashboard />} />
                     <Route path="/test" element={<LessonViewerTest />} />
                     <Route path="/profile" element={<Profile />} />
                     <Route path="/lesson/createlesson" element={<LessonCreatiionForm mode="add" />} />
                     <Route path="/lesson/editlesson" element={<LessonCreatiionForm mode="edit" />} />
                     <Route path="/notice" element={<NoticeList />} />
                     <Route path="/notice/create" element={<CreateNotice />} />
                     <Route path="/notice/edit" element={<EditNotice />} />
                     <Route path="/schedule" element={<Schedules />} />

                     {/* <Route path="/subject" element={<SubjectList />} /> */}

                     <Route path="/subject" element={<SubjectGroupings />} />
                     {/* <Route path="/subject/edit" element={<EditLessonPage />} />  */}
                     <Route path="/subject/edit/lesson" element={<LessonPage />} />
                     {/* <Route path="/subject/edit" element={<SubjectEdit />} /> */}
                     <Route path="/subject/edit" element={<LessonEditView mode="edit" />} />
                     <Route path="/subject/view" element={<LessonEditView mode="view" />} />
                     <Route path="/activities" element={<Dashboard />} />
                     <Route path="/assignment" element={<Dashboard />} />

                     <Route path="/assessment" element={<Assessment />} />
                     <Route path="/assessment/create-pdf" element={<CreatePDF />} />
                     <Route path="/assessment/create-manual" element={<CreateManual />} />
                     <Route path="/assessment/edit-pdf" element={<EditPDF />} />
                     <Route path="/assessment/edit-manual" element={<EditManual />} />
                     <Route path="/assessment/answer-pdf" element={<AnswerPDF />} />
                     <Route path="/assessment/answer-manual" element={<AnswerManual />} />
                     <Route path="/assessment/edit-pdf-assign" element={<EditAssignPDF />} />
                     <Route path="/assessment/view-pdf" element={<ViewPDF />} />
                     <Route path="/assessment/view-manual" element={<ViewManual />} />
                     <Route path="/assessment/preview-pdf" element={<PreviewPDF />} />
                     <Route path="/assessment/preview-manual" element={<PreviewManual />} />

                     <Route path="/studentassessment" element={<StudentAssessment />} />
                     <Route path="/studentassessment/view-ans-pdf" element={<StudentViewPDF />} />
                     <Route path="/studentassessment/view-ans-manual" element={<StudentViewManual />} />
                     {/* <Route path="/studentassessment/preview-pdf" element={<StudentPreviewPDF />} />
                     <Route path="/studentassessment/preview-manual" element={<StudentPreviewManual />} /> */}

                     <Route path="/masteryassessment/create" element={<MasteryCreate />} />
                     <Route path="/masteryassessment/edit" element={<MasteryEdit />} />
                     <Route path="/masteryassessment/preview" element={<MasteryPreview />} />
                     <Route path="/masterycontents" element={<MasteryContents />} />
                     <Route path="/masteryassessment" element={<MasteryAssessment />} />
                     <Route path="/masteryassessmentsubject" element={<MasteryAssessmentSubject />} />
                     <Route path="/masteryassessment/create-pdf" element={<MasteryCreatePDF />} />
                     <Route path="/masteryassessment/create-manual" element={<MasteryCreateManual />} />
                     <Route path="/masteryassessment/edit-pdf" element={<MasteryEditPDF />} />
                     <Route path="/masteryassessment/edit-manual" element={<MasteryEditManual />} />
                     <Route path="/masteryassessment/preview-pdf" element={<MasteryPreviewPDF />} />
                     <Route path="/masteryassessment/preview-manual" element={<MasteryPreviewManual />} />
                     <Route path="/masteryassessment/answer-pdf" element={<MasteryAnswerPDF />} />
                     <Route path="/masteryassessment/answer-manual" element={<MasteryAnswerManual />} />
                     <Route path="/masteryassessment/study-plan" element={<MasteryStudyPlan />} />
                     <Route path="/masteryassessment/tracker" element={<MasteryTracker />} />
                     <Route path="/masteryassessment/teacher-view-ans-pdf" element={<MasteryTeacherViewPDF />} />
                     <Route path="/masteryassessment/teacher-view-ans-manual" element={<MasteryTeacherViewManual />} />
                     <Route path="/masteryassessment/student-view-ans-pdf" element={<MasteryStudentViewPDF />} />
                     <Route path="/masteryassessment/student-view-ans-manual" element={<MasteryStudentViewManual />} />
                     <Route path="/masteryassessment/view-recommendation" element={<MasteryRecommendation />} />
                     <Route path="/masteryassessmentreport" element={<MasteryAssessmentReport />} />
                     <Route path="/masteryreport1" element={<MasteryReportType1 />} />
                     <Route path="/masteryreport2" element={<MasteryReportType2 />} />
                     <Route path="/masteryreport3" element={<MasteryReportType3 />} />
                     <Route path="/masteryreport4" element={<MasteryReportType4 />} />
                     <Route path="/masteryreport5" element={<MasteryReportType5 />} />
                     <Route path="/masteryassessment/view-instruction" element={<MasteryInstruction />} />
                     <Route path="/masterysubjects" element={<MasterySubjects />} />

                     <Route path="/studentmasteryassessment" element={<MasteryStudentAssessment />} />
                     <Route path="/studentmasteryassessment/preview" element={<MasteryStudentPreview />} />
                     <Route path="/studentmasteryassessment/view-result" element={<MasteryStudentViewResult />} />

                     <Route path="/assessmenttracker" element={<Tracker />} />
                     <Route path="/assessmenttracker/teacher-view-ans-pdf" element={<TeacherViewPDF />} />
                     <Route path="/assessmenttracker/teacher-view-ans-manual" element={<TeacherViewManual />} />

                     <Route path="/formativeassessment" element={<FormativeAssessment />} />
                     <Route path="/formativeassessment/create-manual" element={<FormativeCreateManual />} />
                     <Route path="/formativeassessment/create-pdf" element={<FormativeCreatePDF />} />
                     <Route path="/formativeassessment/edit-manual" element={<FormativeEditManual />} />
                     <Route path="/formativeassessment/edit-pdf" element={<FormativeEditPDF />} />
                     <Route path="/formativeassessment/answer-manual" element={<FormativeAnswerManual />} />
                     <Route path="/formativeassessment/answer-pdf" element={<FormativeAnswerPDF />} />
                     <Route path="/formativeassessment/view-manual" element={<FormativeViewManual />} />
                     <Route path="/formativeassessment/preview-manual" element={<FormativePreviewManual />} />
                     <Route path="/formativeassessment/preview-pdf" element={<FormativePreviewPDF />} />
                     <Route path="/formativeassessmenttracker" element={<FormativeTracker />} />
                     <Route path="/formativeassessmenttracker/teacher-view-ans-pdf" element={<TeacherFormativeViewPDF />} />
                     <Route path="/formativeassessmenttracker/teacher-view-ans-manual" element={<TeacherFormativeViewManual />} />

                     <Route path="/studentformativeassessment" element={<StudentFormativeAssessment />} />
                     <Route path="/studentformativeassessment/view-ans-manual" element={<StudentFormativeViewManual />} />
                     <Route path="/studentformativeassessment/view-ans-pdf" element={<StudentFormativeViewPDF />} />

                     <Route path="/homeworks" element={<Homeworks />} />
                     <Route path="/studenthomeworks" element={<StudentHomeworks />} />
                     <Route path="/reportcards" element={<ReportCards />} />
                     <Route path="/studentreportcard" element={<StudentReportCards />} />
                     <Route path="/certificates" element={<Certificates />} />
                     <Route path="/studentcertificate" element={<StudentCertificates />} />

                     <Route path="/managestudentinfo" element={<StudentInfo />} />
                     <Route path="/managestaffinfo" element={<StaffInfo />} />
                     <Route path="/promotestudents" element={<PromoteStudents />} />
                     <Route path="/sections" element={<Sections />} />
                     <Route path="/levels" element={<Levels />} />
                     <Route path="/subjects" element={<Subjects />} />
                     <Route path="/subjectgroups" element={<SubjectGroups />} />

                     <Route path="/classsched" element={<ClassSchedule />} />
                     <Route path="/lessoncalendar" element={<LessonCalendar />} />
                     <Route path="/schoolcalendar" element={<SchoolCalendar />} />

                     <Route path="/surveyassign" element={<SurveyAssign />} />
                     <Route path="/survey" element={<Survey />} />

                     <Route path="/attendance" element={<Attendance />} />

                     <Route path="/diagnostic" element={<DiagnosticAssessment />} />
                     <Route path="/studentdiagnostic" element={<StudentDiagnosticAssessment />} />
                     <Route path="/diagnosticreport" element={<DiagnosticReport />} />

                     <Route path="/bdiagnostic" element={<BDiagnostic />} />
                     <Route path="/bdiagnostic/create-pdf" element={<BCreatePDF />} />
                     <Route path="/bdiagnostic/edit-pdf" element={<BEditPDF />} />
                     <Route path="/bdiagnostic/create-manual" element={<BCreateManual />} />
                     <Route path="/bdiagnostic/edit-manual" element={<BEditManual />} />
                     <Route path="/bdiagnostic/preview-pdf" element={<BPreviewPDF />} />
                     <Route path="/bdiagnostic/preview-manual" element={<BPreviewManual />} />
                     <Route path="/bdiagnostic/view-manual" element={<BViewManual />} />
                     <Route path="/bdiagnostic/view-pdf" element={<BViewPDF />} />
                     <Route path="/bdiagnostic/tracker" element={<BDiagnosticTracker />} />
                     <Route path="/bdiagnostic/student" element={<BStudentDiagnostic />} />
                     <Route path="/bdiagnostic/student/answer-pdf" element={<BAnswerPDF />} />
                     <Route path="/bdiagnostic/student/answer-manual" element={<BAnswerManual />} />
                     <Route path="/bdiagnostic/student/view-ans-pdf" element={<BStudentViewPDF />} />
                     <Route path="/bdiagnostic/student/view-ans-manual" element={<BStudentViewManual />} />
                     <Route path="/bdiagnostic/teacher/view-ans-pdf" element={<BTeacherViewPDF />} />
                     <Route path="/bdiagnostic/teacher/view-ans-manual" element={<BTeacherViewManual />} />
                     <Route path="/bdiagnostic/report" element={<BDiagnosticReport />} />

                     <Route path="/applicantlist" element={<ApplicationList />} />
                     <Route path="/reviewapplication" element={<ReviewApplication />} />
                     <Route path="/interview" element={<Interview />} />

                  </Routes>
               </main>
            </>
            :
            <>
               <main className="main-wrapper2">
                  <Routes>
                     <Route path="/" element={<Login />} />
                     <Route path="/application" element={<Application />} />
                     <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                  </Routes>
               </main>
            </>}

      </div>
   );
}

export default App;
