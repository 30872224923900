import axios from 'axios';

const init = (function () {
   // Assign the json web token if present, so that all calls will send it in the request.
   const jwt = localStorage.getItem("lms-jwt");
   var host = window.location.host
   var subdomain = host.split('.')[0]
   if (subdomain.indexOf("localhost") !== -1)
      subdomain = "dev"
   localStorage.setItem("lms-subdomain", subdomain);
   if (jwt) {
      axios.defaults.headers.common = {
         'Authorization': `Bearer ${jwt}`,
         'Subdomain': subdomain
      };
   }
   else {
      axios.defaults.headers.common = {
         'Subdomain': subdomain
      };
   }

})();

export default init;