/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Button from 'antd/es/button';
import Row from 'antd/es/row';
import Col from 'antd/es/col';
import Typography from 'antd/es/typography';
import Space from 'antd/es/space';
import Divider from 'antd/es/divider';
import Radio from 'antd/es/radio';
import Card from 'antd/es/card';
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import Input from 'antd/es/input';
import InputNumber from 'antd/es/input-number';
import Modal from 'antd/es/modal';
import Image from 'antd/es/image';
import message from 'antd/es/message';
import Descriptions from 'antd/es/descriptions';

import { EyeOutlined, SendOutlined, SaveOutlined, EyeInvisibleOutlined, PlusOutlined, EditOutlined } from '@ant-design/icons';
import { format } from 'date-fns';
// import StickyBox from "react-sticky-box";
import Collapse from "@kunukn/react-collapse";
import './AssessmentTools.css';
import { Configuration, OpenAIApi } from 'openai';
import SimpleBarReact from "simplebar-react";
import uuid from 'react-uuid';
// import { Editor } from '@tinymce/tinymce-react';
import { SchoolTerms, Levels } from '../../constants';

import Wysiwyg from '../wysiwyg/wysiwyg';

const { Title, Text, Paragraph } = Typography;
const { Option } = Select;
// const SectionSvg = () => (
//    // <svg width="1em" height="1em" fill="currentColor" viewBox="64 64 896 896">
//    //   <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/>
//    // </svg>
//    <svg viewBox="0 0 24 24" focusable="false" width="1em" height="1em" fill="currentColor" aria-hidden="true">
//       <path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z" />
//    </svg>
// );

// const SectionIcon = props => <Icon component={SectionSvg} {...props} />;

export default function AssessmentTool(props) {
   const [form] = Form.useForm();

   const [showSelections, setShowSelections] = useState(props.mode === "create" ? true : false);
   const [btnSelLabel, setBtnSelLabel] = useState("Show Tools");
   const [editableTitle, setEditableTitle] = useState(props.data.Title);
   const [assDate, setAssDate] = useState(props.mode === "create" ? format(new Date(), 'MMM. dd, yyyy h:mm a') + "" : props.data.Date_created);
   const [assLevel, setAssLevel] = useState(props.data.Level);
   // const [assMasteryLevel, setAssMasteryLevel] = useState(props.data.Mastery_level);
   const [assSubject, setAssSubject] = useState(props.data.Subject);
   const [questionTemplate, setQuestionTemplate] = useState(1);
   const [createType, setCreateType] = useState('manual');
   const [topic, setTopic] = useState(null);
   const [noi, setNOI] = useState(1);
   const [toq, setTOQ] = useState(['questions with short answer (should be one to two words only)']);
   const [lod, setLOD] = useState('easy');
   const [language, setLanguage] = useState('english');
   const [generating, setGenerating] = React.useState(false);
   const [visibleAI, setVisibleAI] = useState(false);
   const [instruction, setInstruction] = useState(props.data.Instruction !== undefined ? props.data.Instruction : null);
   const [visibleInstruction, setVisibleInstruction] = useState(false);

   // const [lookUps, setLookUps] = useState({
   //    GradeLevels: null,
   //    SubjectList: null,
   // });

   const [subjectList, setSubjectList] = useState([]);
   const [assTerm, setAssTerm] = useState(props.data.Term);

   // const dateFormat = 'MMM. D, YYYY h:mm a';

   const configuration = new Configuration({
      apiKey: process.env.REACT_APP_OPENAI_API_KEY,
   });

   const openai = new OpenAIApi(configuration);
   useEffect(() => {
      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);
      // getLookUps();
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${props.data.Level}&school_year=16`).then((response) => {
         if (response.data) {
            const data = response.data;
            var slist = [];
            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               slist.push(obj);
            });

            setSubjectList(slist);
            setAssSubject(props.data.Subject);
         }
      }, (error) => {
         console.log(error.message);
      });
   }, []);

   const handleLevelChange = (val) => {
      setAssLevel(val);
      setAssSubject(null);

      var slist = [];
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/subjectlist?level=${val}&school_year=16`).then((response) => {
         if (response.data) {
            const data = response.data;

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               slist.push(obj);
            });

            setSubjectList(slist);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   // const getLookUps = () => {
   //    axios.get(`${process.env.REACT_APP_API_LOOKUP}/standardlevellist`).then((response) => {
   //       if (response.data) {
   //          const data = response.data;
   //          var tmp_levels = [];
   //          data.map((item) => {
   //             let obj = { 'value': item.value, 'name': item.name }
   //             tmp_levels.push(obj);
   //          });

   //          axios.get(`${process.env.REACT_APP_API_LOOKUP}/getdiagnosticsubjects`).then((response) => {
   //             if (response.data) {
   //                const data = response.data;
   //                var tmp_subjects = [];
   //                data.map((item) => {
   //                   let obj = { 'value': item.value, 'name': item.name }
   //                   tmp_subjects.push(obj);
   //                });

   //                setLookUps({
   //                   GradeLevels: tmp_levels,
   //                   SubjectList: tmp_subjects,
   //                });
   //             }
   //          }, (error) => {
   //             console.log(error.message);
   //          });


   //       }
   //    }, (error) => {
   //       console.log(error.message);
   //    });
   // }

   const handleCardRequest = (method, type, data, idx) => {
      handleSelections();
      props.cardRequestCallback(method, type, data, idx, questionTemplate);
   }

   const handleSave = () => {
      var assInfo = {
         title: editableTitle,
         term: assTerm,
         date: assDate,
         level: assLevel,
         subject: assSubject,
         instruction
      }

      props.saveCallback(assInfo);
   }

   const handleTemplateChange = (e) => {
      setQuestionTemplate(e.target.value);
   }

   const handleSelections = () => {
      setShowSelections(!showSelections);
      setBtnSelLabel(!showSelections === true ? "Hide Tools" : "Show Tools");
   }

   // const handleCreateType = (val) => {
   //    setCreateType(val);
   // }

   function buildPrompt() {
      let retVal = "";

      retVal = `Create a test on ${assSubject} for ${assLevel} students with the following conditions:
                - The topic is about ${topic}.
                - Number of questions should be exactly ${noi} items.
                - Type of questions are ${toq.join()}.
                - Difficulty level is ${lod}.
                - Keep language level suitable for ${assLevel} students.
                - Translate the language to ${language}.
                - Provide the question and the exact correct answer then convert the result into a valid json string strictly using this format [{ "Question": "", "Type": "", "Choices": [""], "Correct Answer": ""},].
                - Do not include text in the result other than the json string.`;

      return retVal;
   }

   const handleGenerateAssessment = async () => {
      setGenerating(true);

      props.generateCardsFromAI([]);
      var newQuestion = buildPrompt();

      var tmpMessage = [];
      tmpMessage.push({ "role": "user", "content": newQuestion });

      function questionType(val, correct) {
         var retVal = '';

         switch (val.toLowerCase()) {
            case 'short answer':
               retVal = 'short_answer';
               break;

            case 'multiple answer':
            case 'multiple choice':
               retVal = 'multiple_choice';

               if (Array.isArray(correct))
                  if (correct.length > 1)
                     retVal = 'multiple_answer';
               break;

            case 'true or false':
            case 'true/false':
               retVal = 'multiple_choice';
               break;
            default:
               retVal = '';
         }

         return retVal;
      }

      function generateCorrectAnswer(type, correct, choices) {
         var retVal = null;

         switch (type) {
            case 'short_answer':
               retVal = correct.split();
               break;
            case 'multiple_choice':
            case 'multiple_answer':
               var c = [];

               for (var i = 0; i < choices.length; i++) {
                  c.push('0');
               }

               if (Array.isArray(correct)) {
                  correct.forEach(function (itm, idx) {
                     choices.forEach(function (item, index) {
                        if (item === itm)
                           c[index] = '1';
                     });
                  });
               }
               else {
                  choices.forEach(function (item, index) {
                     if (item === correct)
                        c[index] = '1';
                  });
               }


               retVal = c;
               break;
            default:
               retVal = '';
         }

         return retVal;
      }

      try {
         const response = await openai.createChatCompletion({
            // model: "gpt-3.5-turbo-0125",
            model: "gpt-4",
            max_tokens: 3000,
            temperature: 0.5,
            // top_p: 1,
            messages: tmpMessage,
            n: 1,
            stream: false,
         });

         if (response.data.choices) {
            let result = response.data.choices[0].message?.content.trim();

            result = result.replace(/\n/g, '');
            var tmpQuestions = JSON.parse(result);

            var formattedQuestions = [];

            tmpQuestions.forEach((e) => {
               var questionTemplate = {
                  id: '',
                  type: '',
                  correct: [''],
                  points: '1',
                  question: '',
                  option_labels: '',
                  template: '1',
               }

               try {
                  questionTemplate.id = uuid();
                  questionTemplate.type = questionType(e.Type, e['Correct Answer']);
                  questionTemplate.question = e.Question;
                  questionTemplate.correct = generateCorrectAnswer(questionTemplate.type, e['Correct Answer'], e.Type === 'true or false' ? ['True', 'False'] : (e.Choices.length > 0 ? e.Choices : ''));
                  questionTemplate.option_labels = e.Type === 'true or false' || e.Type === 'true/false' ? ['True', 'False'] : (e.Choices.length > 0 ? e.Choices : '');

                  if (questionTemplate.correct.length > 0)
                     formattedQuestions.push(questionTemplate);
               }
               catch (ex) {
                  console.log(ex);
               }

            });

            props.generateCardsFromAI(formattedQuestions);
            // console.log(JSON.stringify(formattedQuestions));
         }

         //--Reset search parameters
         // setTopic(null);
         // setNOI(1);
         // setTOQ(['short answer']);
         // setLOD('easy');
         // setLanguage('english');

         setGenerating(false);
         setVisibleAI(false);
      }
      catch (ex) {
         console.log(ex);
         message.warning("There was a problem generating the assessment. Please try again.");
         setGenerating(false);
      }
   }

   const onFinish = (values) => {
      console.log('Success:', values);
      handleGenerateAssessment();
   };

   const onFinishFailed = (errorInfo) => {
      console.log('Failed:', errorInfo);
   };

   const handleShowAI = () => {
      // formRef.form.reset();
      setVisibleAI(true);
   }

   // const handleShowInstruction = () => {
   //    setVisibleInstruction(true);
   // }

   // const handleModifyInstruction = () => {

   // }

   // const handleLevelChange = (val) => {
   //    setAssLevel(val === undefined ? '' : val);
   // }

   const handleChangeInstruction = (val) => {
      setInstruction(val);
   }

   return (
      <div>
         {
            (!props.mode.includes("assign") && props.mode !== "view") &&
            <>
               {
                  props.showToggle === true && props.type === 'pdf' &&
                  <div className='button-selection-toggle-container'>
                     <Button className='button-shadow' type='primary' block shape='round' onClick={handleSelections}>
                        <Text ellipsis style={{ color: "#fff" }}>{btnSelLabel === "Show Assessment Info" ? <EyeOutlined /> : <EyeInvisibleOutlined />}&nbsp;{btnSelLabel}</Text>
                     </Button>
                  </div>
               }
               {
                  props.mode === "create" && props.type === 'manual' && (props.assessment_type === 'summative' || props.assessment_type === 'formative') &&
                  // <StickyBox style={{ zIndex: 1000 }}>
                  <Space className='button-shadow' style={{ cursor: 'pointer', backgroundColor: '#4ABEC7', padding: '10px', borderRadius: '7px' }} onClick={() => handleShowAI()}>
                     <Image src='../images/brainee_bot.png' preview={false} height="35px" style={{ cursor: 'pointer' }} />
                     <Text style={{ fontSize: '20px', cursor: 'pointer', color: '#fff' }}>Let me create it for you</Text>
                  </Space>
                  // </StickyBox>
               }
               {
                  createType === 'manual'
                     ?
                     <>
                        {
                           (props.type === 'manual') &&
                           <>
                              <Divider orientation="center">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 1) Assessment Info</Title>
                              </Divider>
                              <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4} >
                                 <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                    {/* <Paragraph style={{ textAlign: "left" }} editable={{ onChange: setEditableTitle }}>{editableTitle}</Paragraph> */}
                                    <Input
                                       size='default'
                                       style={{ width: '100%', textAlign: "left", borderRadius: "7px" }}
                                       placeholder="Enter Title"
                                       value={editableTitle}
                                       onChange={(e) => setEditableTitle(e.target.value)}
                                    />
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Level"} span={4} style={{ textAlign: "center" }} >
                                    <Select
                                       showArrow
                                       size='default'
                                       style={{ width: '100%', textAlign: "left" }}
                                       allowClear
                                       placeholder="Select Level"
                                       onChange={(val) => handleLevelChange(val)}
                                       value={assLevel}
                                    >
                                       {Levels.map(({ value, name }, idx) => {
                                          return <Option value={value}>{name}</Option>
                                       })}
                                    </Select>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Subject"} span={4} style={{ textAlign: "center" }} >
                                    <Select
                                       showArrow
                                       size='default'
                                       style={{ width: '100%', textAlign: "left" }}
                                       allowClear
                                       placeholder="Select Subject"
                                       onChange={(val) => setAssSubject(val)}
                                       value={assSubject}
                                    >
                                       {subjectList.map(({ value, name }, idx) => {
                                          return <Option value={value}>{name}</Option>
                                       })}
                                    </Select>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Term"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                    <Select
                                       showArrow
                                       size='default'
                                       style={{ width: '100%', textAlign: "left" }}
                                       allowClear
                                       placeholder="Select Term"
                                       onChange={(val) => setAssTerm(val)}
                                       value={assTerm}
                                    >
                                       {SchoolTerms.map(({ value, name }, idx) => {
                                          return <Option value={value}>{name}</Option>
                                       })}
                                    </Select>
                                 </Descriptions.Item>
                                 <Descriptions.Item label={"Create Date"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                    <Paragraph style={{ textAlign: "center" }}>
                                       {assDate}
                                    </Paragraph>
                                 </Descriptions.Item>
                              </Descriptions>

                              <Row style={{ paddingBottom: 12 }}>
                                 <Col span={24} className="gutter-row">
                                    <Button className='button-shadow' type='primary' block shape='round' style={{ fontSize: 12 }} onClick={() => handleSave()}><Text ellipsis style={{ color: "#fff" }}><SaveOutlined />&nbsp;Save</Text></Button>
                                 </Col>
                              </Row>

                              <Divider orientation="center">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 2) Instruction</Title>
                              </Divider>

                              <Wysiwyg
                                 id={props.id}
                                 changeContentCallback={handleChangeInstruction}
                                 content={instruction}
                                 height={300}
                                 contentHeight={225}
                              />

                              {/* <Button className='button-shadow' type='primary' shape='round' onClick={() => setVisibleInstruction(true)}><PlusOutlined /> Add / <EditOutlined /> Edit</Button> */}


                              <Divider orientation="center">
                                 <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 3) Question Template</Title>
                              </Divider>
                              <Row>
                                 <Col span={24}>
                                    <Radio.Group defaultValue={1} buttonStyle='solid' onChange={(e) => handleTemplateChange(e)}>
                                       <Row gutter={6}>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={1}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/q_text.png' style={{ width: "100px", height: "100px", border: questionTemplate === 1 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Text' />
                                                   <Text>TEXT</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={2}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/q_image.png' style={{ width: "100px", height: "100px", border: questionTemplate === 2 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Image' />
                                                   <Text>IMAGE</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={3}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/q_text_image.png' style={{ width: "100px", height: "100px", border: questionTemplate === 3 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Text & Image' />
                                                   <Text>TEXT & IMAGE</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                       </Row>
                                       <Row gutter={6}>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={4}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/q_audio.png' style={{ width: "100px", height: "100px", border: questionTemplate === 4 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Audio' />
                                                   <Text>AUDIO</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={6}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/text_audio.png' style={{ width: "100px", height: "100px", border: questionTemplate === 5 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Video' />
                                                   <Text>TEXT & AUDIO</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={5}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/q_audio.png' style={{ width: "100px", height: "100px", border: questionTemplate === 4 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Audio' />
                                                   <Text>VIDEO</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                       </Row>
                                       <Row gutter={6}>
                                          <Col sm={24} md={8} lg={8}>
                                             <Radio value={7}>
                                                <Space direction='vertical' size={'small'}>
                                                   <img src='../images/text_video.png' style={{ width: "100px", height: "100px", border: questionTemplate === 5 ? '3px solid #ccc' : '1px solid #ccc' }} alt='Video' />
                                                   <Text>TEXT & VIDEO</Text>
                                                </Space>
                                             </Radio>
                                          </Col>
                                       </Row>
                                    </Radio.Group>
                                 </Col>
                              </Row>
                           </>
                        }
                        {
                           (props.type === 'pdf')
                              ?
                              <Collapse isOpen={showSelections}>
                                 <div className={'answer-sheet-selection-show'} style={{ padding: '20px' }}>
                                    <Divider orientation="center">
                                       <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 1) Assessment Info</Title>
                                    </Divider>
                                    <Descriptions layout='vertical' size={"small"} bordered style={{ marginBottom: "15px", width: "100%" }} column={4} >
                                       <Descriptions.Item label={"Title"} span={4} style={{ textAlign: "center" }} >
                                          {/* <Paragraph style={{ textAlign: "left" }} editable={{ onChange: setEditableTitle }}>{editableTitle}</Paragraph> */}
                                          <Input
                                             size='default'
                                             style={{ width: '100%', textAlign: "left", borderRadius: "7px" }}
                                             placeholder="Enter Title"
                                             value={editableTitle}
                                             onChange={(e) => setEditableTitle(e.target.value)}
                                          />
                                       </Descriptions.Item>
                                       <Descriptions.Item label={"Level"} span={4} style={{ textAlign: "center" }} >
                                          <Select
                                             showArrow
                                             size='default'
                                             style={{ width: '100%', textAlign: "left" }}
                                             allowClear
                                             placeholder="Select Level"
                                             onChange={(val) => handleLevelChange(val)}
                                             value={assLevel}
                                          >
                                             {Levels.map(({ value, name }, idx) => {
                                                return <Option value={value}>{name}</Option>
                                             })}
                                          </Select>
                                       </Descriptions.Item>
                                       <Descriptions.Item label={"Subject"} span={4} style={{ textAlign: "center" }} >
                                          <Select
                                             showArrow
                                             size='default'
                                             style={{ width: '100%', textAlign: "left" }}
                                             allowClear
                                             placeholder="Select Subject"
                                             onChange={(val) => setAssSubject(val)}
                                             value={assSubject}
                                          >
                                             {subjectList.map(({ value, name }, idx) => {
                                                return <Option value={value}>{name}</Option>
                                             })}
                                          </Select>
                                       </Descriptions.Item>
                                       <Descriptions.Item label={"Term"} span={2} style={{ textAlign: "center", width: "50%" }} >
                                          <Select
                                             showArrow
                                             size='default'
                                             style={{ width: '100%', textAlign: "left" }}
                                             allowClear
                                             placeholder="Select Term"
                                             onChange={(val) => setAssTerm(val)}
                                             value={assTerm}
                                          >
                                             {SchoolTerms.map(({ value, name }, idx) => {
                                                return <Option value={value}>{name}</Option>
                                             })}
                                          </Select>
                                       </Descriptions.Item>
                                       <Descriptions.Item label={"Create Date"} span={2} style={{ textAlign: "center", width: "50%" }}>
                                          <Paragraph style={{ textAlign: "center" }}>
                                             {assDate}
                                          </Paragraph>
                                       </Descriptions.Item>
                                    </Descriptions>

                                    <Row>
                                       <Col span={24} className="gutter-row">
                                          <Button className='button-shadow' type='primary' block shape='round' style={{ fontSize: 12 }} onClick={() => handleSave()}><Text ellipsis style={{ color: "#fff" }}><SaveOutlined />&nbsp;Save</Text></Button>
                                       </Col>
                                    </Row>

                                    <Divider orientation="center">
                                       <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 2) Question Type</Title>
                                    </Divider>
                                    <Row>
                                       <Col span={24}>
                                          <Row gutter={6}>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "short_answer", 0)}>
                                                {/* <Tooltip placement="top" title='Short Answer - Identification, Matching Type, Chronological Order, Fill in the Blanks'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px', padding: '0px !important' }}>
                                                   <Text>Short Answer</Text>
                                                   <img src='../images/short_answer.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>

                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "long_answer", 0)}>
                                                {/* <Tooltip placement="top" title='Open-Ended/Essay'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Open-ended/Essay</Text>
                                                   <img src='../images/essay.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                          </Row>
                                          <Row gutter={6}>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_choice", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Choice (True or False, Yes or No, Chronological Order, Matching Type)'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Multiple Choice</Text>
                                                   <img src='../images/multiple_choice.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_choice_image", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Choice Image (Chronological Order, Matching Type)'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Multiple Choice - Image</Text>
                                                   <img src='../images/multiple_choice_image.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                          </Row>
                                          <Row gutter={12}>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_answer", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Answer'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Multiple Answer</Text>
                                                   <img src='../images/multiple_answer.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_answer_image", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Answer - Image'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Multiple Answer - Image</Text>
                                                   <img src='../images/multiple_answer_image.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                          </Row>
                                          <Row gutter={12}>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "matching_type", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Answer'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Matching Type</Text>
                                                   <img src='../images/matching_type.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "matching_type_image", 0)}>
                                                {/* <Tooltip placement="top" title='Multiple Answer - Image'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                   <Text>Matching Type - Image</Text>
                                                   <img src='../images/matching_type_image.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>
                                          </Row>
                                          <Row gutter={12}>
                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "section", 0)}>
                                                {/* <Tooltip placement="top" title='Short Answer - Identification, Matching Type, Chronological Order, Fill in the Blanks'> */}
                                                <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px', padding: '0px !important' }}>
                                                   <Text>Section</Text>
                                                   <img src='../images/section.png'></img>
                                                </Card>
                                                {/* </Tooltip> */}
                                             </Col>

                                             <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }}>
                                                <></>
                                             </Col>
                                          </Row>
                                       </Col>
                                    </Row>

                                 </div>
                              </Collapse>
                              :
                              <div style={{ padding: '20px' }}>
                                 <Divider orientation="center">
                                    <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>(Step 4) Question Type</Title>
                                 </Divider>
                                 <Row>
                                    <Col span={24}>
                                       <Row gutter={12}>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "short_answer", 0)}>
                                             {/* <Tooltip placement="top" title='Short Answer - Identification, Matching Type, Chronological Order, Fill in the Blanks'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px', padding: '0px !important' }}>
                                                <Text>Short Answer</Text>
                                                <img src='../images/short_answer.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>

                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "long_answer", 0)}>
                                             {/* <Tooltip placement="top" title='Open-Ended/Essay'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Open-ended/Essay</Text>
                                                <img src='../images/essay.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                       </Row>
                                       <Row gutter={12}>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_choice", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Choice (True or False, Yes or No, Chronological Order, Matching Type)'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Multiple Choice</Text>
                                                <img src='../images/multiple_choice.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_choice_image", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Choice Image (Chronological Order, Matching Type)'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Multiple Choice - Image</Text>
                                                <img src='../images/multiple_choice_image.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                       </Row>
                                       <Row gutter={12}>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_answer", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Answer'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Multiple Answer</Text>
                                                <img src='../images/multiple_answer.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "multiple_answer_image", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Answer - Image'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Multiple Answer - Image</Text>
                                                <img src='../images/multiple_answer_image.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                       </Row>
                                       <Row gutter={12}>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "matching_type", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Answer'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Matching Type</Text>
                                                <img src='../images/matching_type.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                          <Col sm={24} md={12} lg={12} className="gutter-row" style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center' }} onClick={() => handleCardRequest("add", "matching_type_image", 0)}>
                                             {/* <Tooltip placement="top" title='Multiple Answer - Image'> */}
                                             <Card hoverable={true} style={{ backgroundColor: '#D5F4F9', height: '121px', maxWidth: '202px' }}>
                                                <Text>Matching Type - Image</Text>
                                                <img src='../images/matching_type_image.png'></img>
                                             </Card>
                                             {/* </Tooltip> */}
                                          </Col>
                                       </Row>
                                    </Col>
                                 </Row>
                              </div>
                        }
                     </>
                     :
                     <>
                        <Divider orientation="center">
                           <Title level={5} style={{ textAlign: "Left", color: "#a3a3ac" }}>Enter Parameters</Title>
                        </Divider>

                     </>
               }
            </>
         }

         <Modal
            key="Modal-AI"
            title={<><Image src='../images/brainee_bot.png' preview={false} height="25px" /> Brainee AI Assessment Generator</>}
            // title="Brainee AI Assessment Generator"
            destroyOnClose={true}
            centered
            open={visibleAI}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ shape: 'round', type: 'primary', className: "button-shadow" }}
            onCancel={() => setVisibleAI(false)}
            cancelText="Close"
            width={"35%"}
            style={{ minWidth: "350px", maxWidth: "95%" }}
         // footer={false}
         >
            <Form
               // ref={formRef}
               form={form}
               layout={"vertical"}
               colon={false}
               className='aiform'
               onFinish={onFinish}
               onFinishFailed={onFinishFailed}
            >
               <SimpleBarReact key={'uid'} style={{ maxHeight: "calc(100vh - 217px)", width: '99%' }}>
                  <Form.Item name="subject" label="Subject"
                     rules={[{
                        required: true,
                        message: 'Subject should not be blank'
                     }]}
                     // initialValue={props.data.Subject.toLowerCase()}
                     initialValue={props.data.Subject}
                  >
                     <Select
                        key={'uid'}
                        showArrow
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select subject"
                        // value={props.data.Subject.toLowerCase()}
                        value={props.data.Subject}
                     // defaultValue={'araling panlipunan'}
                     // onChange={(val) => setSubject(val)}
                     >
                        <Option value={'araling panlipunan'}>Araling Panlipunan</Option>
                        <Option value={'english'}>English</Option>
                        <Option value={'filipino'}>Filipino</Option>
                        <Option value={'mathematics'}>Mathematics</Option>
                        <Option value={'science'}>Science</Option>
                        <Option value={'technology and livelihood education'}>Technology and Livelihood Education</Option>
                     </Select>
                  </Form.Item>
                  <Form.Item name="gradelevel" label="Grade Level"
                     rules={[{
                        required: true,
                        message: 'Grade level should not be blank'
                     }]}
                     initialValue={props.data.Level}
                  >
                     <Select
                        key={'uid'}
                        showArrow
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select grade level"
                        value={props.data.Level}
                     >
                        <Option value={'kinder'}>Kinder</Option>
                        <Option value={'grade 1'}>Grade 1</Option>
                        <Option value={'grade 2'}>Grade 2</Option>
                        <Option value={'grade 3'}>Grade 3</Option>
                        <Option value={'grade 4'}>Grade 4</Option>
                        <Option value={'grade 5'}>Grade 5</Option>
                        <Option value={'grade 6'}>Grade 6</Option>
                        <Option value={'grade 7'}>Grade 7</Option>
                        <Option value={'grade 8'}>Grade 8</Option>
                        <Option value={'grade 9'}>Grade 9</Option>
                        <Option value={'grade 10'}>Grade 10</Option>
                        <Option value={'grade 11'}>Grade 11</Option>
                        <Option value={'grade 12'}>Grade 12</Option>
                     </Select>
                  </Form.Item>
                  <Form.Item name="topic" label="Topic"
                     rules={[{
                        required: true,
                        message: 'Topic should not be blank'
                     }]}
                  // initialValue={topic}
                  >
                     <Input
                        key={'uid'}
                        allowClear
                        size='large'
                        style={{ borderRadius: 7 }}
                        placeholder="Enter Topic"
                        onBlur={(event) => setTopic(event.target.value)}
                     // value={topic}
                     />
                  </Form.Item>
                  <Form.Item name="noi" label="Number of Items"
                     rules={[{
                        required: true,
                        message: 'Number of items should not be blank'
                     }]}
                     initialValue={noi}
                  >
                     <InputNumber
                        key={'uid'}
                        size='large'
                        style={{ borderRadius: 7, width: "100%" }}
                        // defaultValue={1}
                        min="1"
                        max='100'
                        onChange={(val) => setNOI(val)}
                     // value={noi}
                     />
                  </Form.Item>
                  <Form.Item name="toq" label="Type of Question/s"
                     rules={[{
                        required: true,
                        message: 'Type of question should not be blank'
                     }]}
                     initialValue={toq}
                  >
                     <Select
                        key={'uid'}
                        showArrow
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select type of question"
                        // defaultValue={'short answer'}
                        onChange={(val) => setTOQ(val)}
                        mode="multiple"
                     // value={toq}
                     >
                        <Option value={'questions with short answer (should be one to two words only)'}>Short Answer</Option>
                        <Option value={'questions with multiple choice'}>Mutiple Choice</Option>
                        <Option value={'questions answerable by true or false'}>True or False</Option>
                        {/* <Option value={'multiple answers'}>Multiple Answers</Option> */}
                        <Option value={'questions with multiple choice and should have more than 1 correct answer'}>Mutiple Answer</Option>
                        {/* <Option value={'open-ended, essay'}>Open-ended/Essay</Option> */}
                        {/* <Option value={'fill in the blank with choices'}>Fill in the Blank</Option> */}
                        {/* <Option value={'matching type'}>Matching Type</Option> */}
                     </Select>
                  </Form.Item>
                  <Form.Item name="lod" label="Level of Difficulty"
                     rules={[{
                        required: true,
                        message: 'Level of difficulty should not be blank'
                     }]}
                     initialValue={lod}
                  >
                     <Select
                        key={'uid'}
                        showArrow
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select difficulty level"
                        // defaultValue={'easy'}
                        onChange={(val) => setLOD(val)}
                     // value={lod}
                     >
                        <Option value={'easy'}>Easy</Option>
                        <Option value={'medium'}>Average</Option>
                        <Option value={'hard'}>Difficult</Option>
                     </Select>
                  </Form.Item>

                  <Form.Item name="translation" label="Translation"
                     rules={[{
                        required: true,
                        message: 'Translation should not be blank'
                     }]}
                     initialValue={language}
                  >
                     <Select
                        key={'uid'}
                        showArrow
                        size='large'
                        style={{ width: '100%' }}
                        allowClear
                        placeholder="Select translation"
                        // defaultValue={'english'}
                        onChange={(val) => setLanguage(val)}
                     // value={language}
                     >
                        <Option value={'english'}>English</Option>
                        <Option value={'tagalog'}>Filipino</Option>
                     </Select>
                  </Form.Item>
               </SimpleBarReact>

               <Row gutter={[10, 10]} style={{ marginTop: "5px" }}>
                  <Col sm={24}>
                     {
                        generating
                           ?
                           <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={generating}
                              htmlType='submit'
                           >
                              Generating...
                           </Button>
                           :
                           <Button className='button-shadow' size='middle' type="primary" shape='round' block loading={generating}
                              htmlType='submit'
                           >
                              <SendOutlined />Generate
                           </Button>
                     }

                  </Col>
               </Row>
            </Form>
         </Modal>

         <Modal
            key="Modal-Instruction"
            title={<>Add / Edit Instruction</>}
            destroyOnClose={true}
            centered
            open={visibleInstruction}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ shape: 'round', type: 'primary', className: "button-shadow" }}
            onCancel={() => setVisibleInstruction(false)}
            onOk={() => setVisibleInstruction(false)}
            okText="Ok"
            cancelText="Close"
            width={"60%"}
            style={{ minWidth: "350px", maxWidth: "95%" }}
         // footer={false}
         >
            {/* <Editor
               id={'brainee' + props.id}
               apiKey={`${process.env.REACT_APP_TINY_MCE_API_KEY}`}
               onFocusOut={(e) => setInstruction(e.target.innerHTML)}
               initialValue={instruction}
               key={'brainee' + props.id}
               init={{
                  // height: props.mode !== "answer" && !props.mode.includes("assign") && !props.mode.includes("view") ? 250 : 200,
                  height: 400,
                  menubar: true,
                  statusbar: true,
                  plugins: [
                     'advlist autolink lists link image charmap print preview anchor',
                     'searchreplace visualblocks code',
                     'insertdatetime media table paste code help wordcount emoticons'
                  ],
                  toolbar: 'undo redo | formatselect | ' +
                     'bold italic backcolor | alignleft aligncenter ' +
                     'alignright alignjustify | bullist numlist outdent indent | ' +
                     'removeformat | emoticons | help',
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
               }}
            /> */}
            <Wysiwyg
               id={props.id}
               changeContentCallback={handleChangeInstruction}
               content={instruction}
               height={400}
               contentHeight={360}
            />
            {/* <div style={{ height: "400px", backgroundColor: "white", }}>
               <ReactQuill
                  id={'brainee' + props.id}
                  key={'brainee' + props.id}
                  style={{
                     width:"100%",
                     backgroundSize: "auto 100%",
                     backgroundRepeat: "no-repeat",
                     maxHeight: "360px",
                     height: "360px",
                     // overflow: "auto",
                     backgroundColor: "white",
                  }}      
                  theme='snow'
                  value={instruction}
                  onChange={handleChangeInstruction}
                  modules={modules}
                  formats={formats}
                  // placeholder='Loading ...'
            />
            </div> */}
         </Modal>
      </div >
   )
}