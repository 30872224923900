/* eslint-disable default-case */
import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
   Empty, Input, Tabs, Table, Typography, Space, Tooltip, Button, Select, Row, Col,
   Popconfirm, Drawer, notification, Modal, Spin, Breadcrumb
} from 'antd';
import {
   SearchOutlined, EditOutlined, DeleteOutlined, UsergroupAddOutlined, EyeOutlined, ProfileOutlined,
   SnippetsOutlined, UndoOutlined, PlusOutlined, RestOutlined, BookOutlined
} from '@ant-design/icons';
// import NoticeBoard from '../../components/notice-board/NoticeBoard';
import NoticeBoardSlide from '../../components/notice-board/NoticeBoardSlide';
import Highlighter from 'react-highlight-words';
import MasteryAssignTool from '../../components/assessment/MasteryAssignTool';
import SimpleBarReact from "simplebar-react";
import axios from 'axios';
import uuid from 'react-uuid';
import Utils from '../../misc/Utils';
import Enums from '../../misc/Enums';
import { useDispatch } from 'react-redux';
import { reset_mastery_data } from '../../actions';
// import RenderPDFViewer from '../../components/iframe-viewer/pdfViewer';
import CreateInstructions from './Components/CreateInstructions';
import EditInstructions from './Components/EditInstructions';
// import { PDFReader } from 'reactjs-pdf-reader';
// import { RenderPDFReader } from '../../components/pdfreader/RenderPDFReader';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

const { TabPane } = Tabs;
const { Text } = Typography;
const { Option } = Select;

let pdfFile = '';
let selectedAssignID = '';


let g_instruction_data = {
   instruction_id: null,
   instruction_desc: null,
   instruction_docs: null,
   instruction_doc_names: null,
}

export function MasteryAssessment() {
   const navigate = useNavigate();
   // const location = useLocation();
   const dispatch = useDispatch();

   const user_id = Utils.getCurrentUserInfo(Enums.UserInfo.id);
   const user_role = Utils.getCurrentUserInfo(Enums.UserInfo.role);

   const [searchText, setSearchText] = React.useState("");
   const [searchedColumn, setSearchedColumn] = React.useState("");
   const [selectedAssessment, setSelectedAssessment] = useState("");

   const [assOptions, setAssOptions] = useState(null);

   const [assignVisible, setAssignVisible] = useState(false);
   const [assTitle, setAssTitle] = useState('');
   const [masterList, setMasterList] = useState([]);
   const [assignedList, setAssignedList] = useState([]);
   const [deleteList, setDeletedList] = useState([]);
   const [listLoading, setListLoading] = useState(false);
   const [fileURL, setFileURL] = useState(null);

   const [createInstructionModalVisible, setCreateInstructionModalVisible] = useState(false);
   const [editInstructionModalVisible, setEditInstructionModalVisible] = useState(false);

   const [creating, setCreating] = useState(false);
   const [updating, setUpdating] = useState(false);

   const [lookUps, setLookUps] = useState({
      SchoolTerms: null,
      MasteryGradeLevels: null,
      MasterySubjects: null,
   });

   const [searchKeys, setSearchKeys] = useState(() => {
      var tmp = JSON.parse(localStorage.getItem("mastery-assessment-search-keys"));
      if (tmp !== null)
         return tmp;
      else {
         return tmp = {
            term: null,
            level: null,
            subject: null,
            activetab: '1',
         };
      }
   });

   const [searchEnabled, setSearchEnabled] = useState(searchKeys.activetab === '1' ? false : true);
   const [pdfLoaded, setPdfLoaded] = useState(false);

   useEffect(() => {
      Utils.verifyJwt();

      if (user_role.toLowerCase() !== 'admin' && user_role.toLowerCase() !== 'teacher' && user_role.toLowerCase() !== 'co-teacher')
         navigate('/home');

      // const unloadCallback = (event) => {
      // //   event.preventDefault();
      // //   event.returnValue = "";
      // // localStorage.clear();
      //   return "";
      // };

      // window.addEventListener("beforeunload", unloadCallback);
      // return () => window.removeEventListener("beforeunload", unloadCallback);

      getLookUps();

      setSearchKeys(() => {
         if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null && searchKeys.activetab !== 1) {
            refreshList(searchKeys.activetab === null ? '1' : searchKeys.activetab);

            return ({
               term: searchKeys.term,
               level: searchKeys.level,
               subject: searchKeys.subject,
               activetab: searchKeys.activetab
            })
         } else
            refreshList('1');

         return ({
            term: null,
            level: null,
            subject: null,
            activetab: '1',
         })

      });

      pdfFile = '';
   }, []);

   const getLookUps = () => {
      axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasteryterms`).then((response) => {
         if (response.data) {
            const data = response.data;

            var school_terms = [];
            var levels = [];
            var subjects = [];

            data.map((item) => {
               let obj = { 'value': item.value, 'name': item.name }
               school_terms.push(obj);
            });

            axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterygradelevels`).then((response) => {
               if (response.data) {
                  const data = response.data;
                  data.map((item) => {
                     let obj = { 'value': item.value, 'name': item.name }
                     levels.push(obj);
                  });

                  axios.get(`${process.env.REACT_APP_API_LOOKUP}/getmasterysubjects`).then((response) => {
                     if (response.data) {
                        const data = response.data;
                        data.map((item) => {
                           let obj = { 'value': item.value, 'name': item.name }
                           subjects.push(obj);
                        });

                        setLookUps({
                           SchoolTerms: school_terms,
                           MasteryGradeLevels: levels,
                           MasterySubjects: subjects,
                        });
                     }
                  }, (error) => {
                     console.log(error.message);
                  });
               }
            }, (error) => {
               console.log(error.message);
            });

         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleSearch = (selectedKeys, confirm, dataIndex) => {
      confirm();
      setSearchText(selectedKeys[0]);
      setSearchedColumn(dataIndex);
   };

   const handleReset = clearFilters => {
      clearFilters();
      setSearchText("");
   };

   const getColumnSearchProps = dataIndex => ({
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
         <div style={{ padding: 8 }}>
            <Input
               ref={node => {
                  //this.searchInput(node);
               }}
               placeholder={`Search ${dataIndex}`}
               value={selectedKeys[0]}
               onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
               onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
               style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
               <Button
                  type="primary"
                  onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                  icon={<SearchOutlined />}
                  size="small"
                  style={{ width: 90 }}>
                  Search
               </Button>
               <Button
                  size="small"
                  style={{ width: 90 }}
                  // onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}
                  onClick={() => {
                     handleReset(clearFilters);
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Reset
               </Button>
               {/* <Button
                  type="link"
                  size="small"
                  onClick={() => {
                     confirm({ closeDropdown: false });
                     setSearchText(selectedKeys[0]);
                     setSearchedColumn(dataIndex);
                  }}
               >
                  Filter
               </Button> */}
            </Space>
         </div>
      ),
      filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
      onFilter: (value, record) =>
         record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
      // onFilterDropdownVisibleChange: visible => {
      //    if (visible) {
      //       setTimeout(() => this.searchInput.select(), 100);
      //    }
      // },
      render: text =>
         searchedColumn === dataIndex ? (
            <Highlighter
               highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
               searchWords={[searchText]}
               autoEscape
               textToHighlight={text ? text.toString() : ''}
            />
         ) : (
            text
         ),
   });

   const masterListColumns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: '',
         dataIndex: 'term',
         key: 'term',
         hidden: true,
      },
      {
         title: 'Code',
         dataIndex: 'competency_desc',
         key: 'competency_desc',
         sorter: (a, b) => { return a.competency_desc.localeCompare(b.competency_desc) },
         ...getColumnSearchProps('competency_desc'),
         width: '8%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '34%'
      },
      {
         title: 'Type',
         key: 'type',
         // dataIndex: 'type',
         // sorter: (a, b) => { return a.type.localeCompare(b.type) },
         // ...getColumnSearchProps('type'),
         render: object => (
            object.summative !== null || object.formative_1 !== null || object.formative_2 !== null || object.formative_3 !== null
               ?
               object.summative !== null
                  ?
                  'Summative'
                  :
                  'Formative'
               :
               'Not Defined'
         ),
         width: '9%'
      },
      {
         title: 'Term',
         key: 'term_desc',
         dataIndex: 'term_desc',
         sorter: (a, b) => { return a.term_desc.localeCompare(b.term_desc) },
         ...getColumnSearchProps('term_desc'),
         width: '8%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '10%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc === null ? '' : a.subject_desc.localeCompare(b.subject_desc === null ? '' : b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: true,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '12%'
      },
      {
         title: 'Question Type',
         key: 'question_type',
         dataIndex: 'question_type',
         hidden: true,
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  {user_role === 'Admin' &&
                     <>
                        <Tooltip placement="top" title='Edit'><EditOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessment(payload.id)} /></Tooltip>
                        <Tooltip placement="top" title='Remove'>
                           <Popconfirm
                              title="Are you sure you want to delete this Mastery Assessment?"
                              onConfirm={() => handleDeleteAssessment(payload.id)}
                              okText="Yes"
                              cancelText="No"
                           >
                              <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                           </Popconfirm>
                        </Tooltip>
                     </>
                  }

                  <Tooltip placement="top" title='Assign'><UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleAssignAssessment(payload.id, payload.title, payload.term, payload.summative)} /></Tooltip>
                  <Tooltip placement="top" title='Preview'><EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewAssessment(payload.id)} /></Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'assessment_id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'subject_name',
         dataIndex: 'subject_name',
         sorter: (a, b) => { return a.subject_name.localeCompare(b.subject_name) },
         width: '12%'
      },
      {
         title: 'Level',
         key: 'level_name',
         dataIndex: 'level_name',
         sorter: (a, b) => { return a.level_name.localeCompare(b.level_name) },
         width: '10%'
      },
      {
         title: 'Code',
         dataIndex: 'competency',
         key: 'competency',
         sorter: (a, b) => { return a.competency.localeCompare(b.competency) },
         ...getColumnSearchProps('competency'),
         width: '10%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Assigned By',
         key: 'assigned_by_name',
         ...getColumnSearchProps('assigned_by_name'),
         ellipsis: {
            showTitle: true,
         },
         render: object => (
            <Tooltip placement="topLeft" title={object.assigned_by_name}>
               <Text>{object.assigned_by_name}</Text>
            </Tooltip>
         ),
         width: '15%'
      },
      {
         title: 'Assigned To',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return a.section_name.localeCompare(b.section_name) },
         width: '14%'
      },
      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
         width: '15%'
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Assigment'><UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.term)} /></Tooltip>

                  {/* {user_role === 'Admin' &&  */}
                  <Tooltip placement="top" title='Remove Assignment'>
                     <Popconfirm
                        title="Are you sure you want to delete this assigned assessment?"
                        onConfirm={() => handleDeleteAssignment(payload.id)}
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No">

                        <DeleteOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  {/* } */}

                  {searchKeys.activetab !== '4' &&
                     <>
                        <Tooltip placement="top" title='View Tracker'><ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, payload.mastery_assessment_id, searchKeys.activetab)} /></Tooltip>
                     </>
                  }

                  <Tooltip placement="left" title='Create/Edit Instruction'><SnippetsOutlined style={{ cursor: 'pointer' }} onClick={() => handleInstruction(payload.id, payload.instruction_id, payload.instruction_desc, payload.instruction_docs, payload.instruction_doc_names)} /></Tooltip>

               </Space>
            </>
         },
         width: '12%'
      },
   ].filter(item => !item.hidden);

   const teacher_assigned_ass_columns = [
      {
         title: 'Id',
         key: 'id',
         dataIndex: 'id',
         hidden: true,
      },
      {
         title: 'assessment_id',
         key: 'assessment_id',
         dataIndex: 'assessment_id',
         hidden: true,
      },
      {
         title: 'Subject',
         key: 'subject_name',
         dataIndex: 'subject_name',
         sorter: (a, b) => { return a.subject_name.localeCompare(b.subject_name) },
         width: '18%'
      },
      {
         title: 'Level',
         key: 'level_name',
         dataIndex: 'level_name',
         sorter: (a, b) => { return a.level_name.localeCompare(b.level_name) },
         width: '12%'
      },
      {
         title: 'Code',
         dataIndex: 'competency',
         key: 'competency',
         sorter: (a, b) => { return a.competency.localeCompare(b.competency) },
         ...getColumnSearchProps('competency'),
         width: '10%'
      },
      {
         title: 'Competency Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '29%'
      },
      {
         title: 'Assigned To',
         key: 'section_name',
         dataIndex: 'section_name',
         sorter: (a, b) => { return a.section_name.localeCompare(b.section_name) },
         width: '14%'
      },

      {
         title: 'Start Date',
         key: 'start_date',
         dataIndex: 'start_date',
         sorter: (a, b) => { return a.start_date.localeCompare(b.start_date) },
         width: '15%'
      },
      {
         title: 'End Date',
         key: 'end_date',
         dataIndex: 'end_date',
         sorter: (a, b) => { return a.end_date.localeCompare(b.end_date) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Edit Assigment'><UsergroupAddOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handleEditAssessmentAssign(payload.id, payload.title, payload.term)} /></Tooltip>
                  {searchKeys.activetab !== '4' &&
                     <>
                        <Tooltip placement="top" title='View Tracker'><ProfileOutlined style={{ cursor: 'pointer' }} onClick={() => handleViewTracker(payload.id, payload.mastery_assessment_id, searchKeys.activetab)} /></Tooltip>
                        <Tooltip placement="top" title='Create/Edit Recommendation'><SnippetsOutlined style={{ cursor: 'pointer' }} onClick={() => handleInstruction(payload.id, payload.instruction_id, payload.instruction_desc, payload.instruction_docs, payload.instruction_doc_names)} /></Tooltip>
                     </>
                  }
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   const recycle_bin_columns = [
      {
         title: '',
         dataIndex: 'id',
         key: 'id',
         hidden: true,
      },
      {
         title: 'Title',
         key: 'title',
         dataIndex: 'title',
         sorter: (a, b) => { return a.title.localeCompare(b.title) },
         ...getColumnSearchProps('title'),
         ellipsis: {
            showTitle: true,
         },
         render: title => (
            <Tooltip placement="topLeft" title={title}>
               <span style={{ cursor: "default" }}>{title}</span>
            </Tooltip>
         ),
         width: '29%'
      },
      {
         title: 'Term',
         key: 'term_desc',
         dataIndex: 'term_desc',
         sorter: (a, b) => { return a.term_desc.localeCompare(b.term_desc) },
         ...getColumnSearchProps('term_desc'),
         width: '8%'
      },
      {
         title: 'Level',
         dataIndex: 'level_desc',
         key: 'level_desc',
         sorter: (a, b) => { return a.level_desc.localeCompare(b.level_desc) },
         ...getColumnSearchProps('level_desc'),
         width: '12%'
      },
      {
         title: 'Subject',
         key: 'subject_desc',
         dataIndex: 'subject_desc',
         sorter: (a, b) => { return a.subject_desc.localeCompare(b.subject_desc) },
         ...getColumnSearchProps('subject_desc'),
         ellipsis: {
            showTitle: true,
         },
         render: subject => (
            <Tooltip placement="topLeft" title={subject}>
               <span style={{ cursor: "default" }}>{subject}</span>
            </Tooltip>
         ),
         width: '20%'
      },
      {
         title: 'Competency',
         dataIndex: 'competency_desc',
         key: 'competency_desc',
         sorter: (a, b) => { return a.competency_desc.localeCompare(b.competency_desc) },
         ...getColumnSearchProps('competency_desc'),
         width: '12%'
      },
      {
         title: 'Date Deleted',
         key: 'date_deleted',
         dataIndex: 'date_created',
         sorter: (a, b) => { return a.date_deleted.localeCompare(b.date_deleted) },
         width: '15%'
      },
      {
         title: 'Action',
         key: 'action',
         render: payload => {
            return <>
               <Space size="small">
                  <Tooltip placement="top" title='Restore'>
                     <Popconfirm
                        title="Do you want to restore this assessment?"
                        onConfirm={() => handleRestoreAssessment(payload.id)}
                        okText="Yes"
                        cancelText="No"
                     >
                        <UndoOutlined color='#4abec7' style={{ cursor: 'pointer' }} />
                     </Popconfirm>
                  </Tooltip>
                  <Tooltip placement="top" title='Preview'><EyeOutlined color='#4abec7' style={{ cursor: 'pointer' }} onClick={() => handlePreviewAssessment(payload.id)} /></Tooltip>
               </Space>
            </>
         },
         width: '10%'
      },
   ].filter(item => !item.hidden);

   // const handleCancelModal = () => {
   //    setQuestionTypeModalVisible(false);
   //    setShowMasterList(false);
   //    setQuestionType(1);
   // }

   const handleDeleteAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/delete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Mastery Assessment',
               content: 'Your mastery assessment has been deleted.',
               centered: true,
               onOk: refreshList(searchKeys.activetab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Mastery assessment delete failed.',
            centered: true
         });
      });
   }

   const handleDeleteAssignment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/deleteassigned?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Mastery Assessment',
               content: 'Your assigned assessment has been deleted.',
               centered: true,
               onOk: refreshList(searchKeys.activetab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Mastery assigned assessment delete failed.',
            centered: true
         });
      });
   }

   const handleRestoreAssessment = (id) => {
      axios.post(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/undelete?id=${id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            Modal.success({
               title: 'Mastery Assessment',
               content: 'Your assessment has been restored.',
               centered: true,
               onOk: refreshList(searchKeys.activetab)
            });
         }
      }, (error) => {
         console.log(error.message);
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Mastery assigned assessment restore failed.',
            centered: true
         });
      });
   }

   const handleCreateMastery = () => {
      var params = { key: ``, value: '' };
      dispatch(reset_mastery_data(params));

      navigate('/masteryassessment/create');
   }

   // const handleCreateAssessment = () => {
   //    setQuestionTypeModalVisible(false);

   //    if (questionType === 1) {
   //       navigate('/masteryassessment/create-pdf', { state: { title: assTitle } });
   //    } else if (questionType === 2) {
   //       navigate('/masteryassessment/create-manual', { state: { title: assTitle } });
   //    }
   // };

   const handleEditAssessment = (id) => {
      var params = { key: ``, value: '' };
      dispatch(reset_mastery_data(params));

      navigate('/masteryassessment/edit', { state: { id } });
   };

   const handlePreviewAssessment = (id) => {
      var params = { key: ``, value: '' };
      dispatch(reset_mastery_data(params));

      navigate('/masteryassessment/preview', { state: { id } });
   }

   // const handleAssessmentView = (id, q_type, origin) => {
   //    if (q_type.toString() === '1') {
   //       navigate('/masteryassessment/view-pdf', { state: { id, origin } });
   //    }
   //    else if (q_type.toString() === '2') {
   //       navigate('/masteryassessment/view-manual', { state: { id, origin } });
   //    }
   // }

   // const handleQuestionType = (e) => {
   //    setQuestionType(e.target.value);
   // };

   // const rowSelection = {
   //    selectedAssessment,
   //    onChange: (selectedRowKeys, selectedRows) => {
   //       if (selectedRows[0].key != undefined) {
   //          console.log(selectedRows[0].key);
   //       }

   //       setSelectedAssessment(selectedRows[0].id);
   //    },
   //    type: 'radio'
   // };

   const handleAssignAssessment = (id, title, term, ass_type) => {
      let ass_options_default = {
         id: '',
         assessment_id: id,
         assigned_by: user_id,
         duration: '1',
         allowed_attempts: '1',
         passing_percentage: '50',
         allow_result_viewing: '0',
         enable_timer: '0',
         start_date: '',
         end_date: '',
         assigned_students: '',
         assigned_students_with_section: '',
         term: term,
         level: null,
         teacher_intervene: '0',
         title: title,
         section: null,
         assessment_type: ass_type !== null ? 'summative' : 'formative',
      }

      setAssOptions(ass_options_default);
      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleEditAssessmentAssign = (ass_id, title, term) => {
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assigninfo?id=${ass_id}`).then((response) => {
         if (response.data) {
            const data = response.data;
            let ass_options = {
               id: data.id,
               assessment_id: data.assessment_id,
               assigned_by: data.assigned_by,
               duration: data.duration.toString(),
               allowed_attempts: data.allowed_attempts.toString(),
               passing_percentage: data.passing_percentage.toString(),
               allow_result_viewing: data.allow_result_viewing.toString(),
               enable_timer: data.enable_timer.toString(),
               start_date: data.start_date,
               end_date: data.end_date,
               assigned_students: data.assigned_students,
               assigned_students_with_section: data.assigned_students_with_section,
               term: data.term,
               level: data.level,
               teacher_intervene: data.teacher_intervene,
               title: title,
               section: data.section,
            };

            setAssOptions(ass_options);
         }
      }, (error) => {
         console.log(error.message);
      });

      setAssTitle(title);
      setAssignVisible(true);
   };

   const handleViewTracker = (assign_id, assessment_id, origin) => {
      // navigate('/masteryassessment/tracker', {replace: true, state: {assign_id, assessment_id, origin}});
      navigate(`/masteryassessment/tracker?assign_id=${assign_id}&assessment_id=${assessment_id}&origin=${origin}`);
   }

   const handleSetOptions = (data) => {
      let tempAssOptions = null;
      tempAssOptions = JSON.parse(JSON.stringify(data));
      setAssOptions(tempAssOptions);
   }

   const onDrawerClose = () => {
      setAssignVisible(false);
   }

   const handleSaveAssignment = (data) => {
      // message.info(JSON.stringify(data));
      if (data.id === '') { //--New assignment
         var o2o_mode = window.location.hostname.match(/localhost|[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}\.[0-9]{2,3}|::1|\.local|^$/gi) ? "offline" : "online";
         var assignmentData = data;

         assignmentData.id = "mastery_assign_" + o2o_mode + "_" + uuid();

         // message.info(JSON.stringify(assignmentData));

         const url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assign`
         axios.post(url, assignmentData).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Mastery Assignment`,
                  description:
                     'Mastery was assigned successfully. You may now create your recommendation.',
               });

               setAssignVisible(false);
               handleInstruction(assignmentData.id, null, null, null, null);
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment failed',
            });
         });
      } else {//-- Edit assignment
         const url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/assignupdate`
         axios.post(url, data).then(response => {
            // console.log(response);
            if (response.data) {
               const data = response.data;
               // message.success('Assessment was created.');
               // Modal.success({
               //    content: 'Assessment was assigned.',
               //    centered: true,
               // });
               notification.success({
                  message: `Assessment Assignment`,
                  description:
                     'Assessment assignment was updated successfully',
               });

               setAssignVisible(false);
            }
         }, (error) => {
            console.log(error.message);
            // message.error('Assessment creation failed.');
            notification.error({
               message: `Assessment Assignment`,
               description:
                  'Assessment assignment update failed',
            });
         });
      }
   }

   const renderDrawer = () => {
      return <>
         <Drawer
            // title={<Text style={{textAlign: "justify", textJustify: "inter-word"}}>{assTitle}</Text>}
            title="Mastery Competency Assignment"
            placement="right"
            size={"default"}
            closable={false}
            onClose={onDrawerClose}
            open={assignVisible}
            zIndex={1001}
            extra={
               <Space>
                  <Button type='primary' shape='round' onClick={onDrawerClose}>Close</Button>
               </Space>
            }
         >
            <SimpleBarReact style={{ height: "calc(100vh - 110px)" }}>
               <MasteryAssignTool
                  mode={"drawer"}
                  options={assOptions}
                  parentCallback={handleSetOptions}
                  saveCallback={handleSaveAssignment}
               />
            </SimpleBarReact>
         </Drawer>
      </>
   }

   const getMasterList = () => {
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listall`;

      if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
         searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {

         url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listfiltered?term=${searchKeys.term === null || searchKeys.term === undefined ? 0 : searchKeys.term}&level=${searchKeys.level === null || searchKeys.level === undefined ? 0 : searchKeys.level}&subject=${searchKeys.subject === null || searchKeys.subject === undefined ? 0 : searchKeys.subject}`;

         setListLoading(true);
         axios.get(url).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               setListLoading(false);
               setMasterList(data);
            }
         }, (error) => {
            console.log(error.message);
         });
      }
   }

   const getAssignedtList = (time_tense) => {
      // if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
      //    searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {

      setListLoading(true);

      // var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listassigned?assigned_by=${user_id}&time_tense=${time_tense}&term=${searchKeys.term}&level=${searchKeys.level}&subject=${searchKeys.subject}`;
      var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listassigned?assigned_by=${user_id}&time_tense=${time_tense}&term=0&level=0&subject=0`;

      if (user_role === 'Admin')
         // url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listallassigned?time_tense=${time_tense}&term=${searchKeys.term}&level=${searchKeys.level}&subject=${searchKeys.subject}`;
         url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listallassigned?time_tense=${time_tense}&term=${0}&level=${0}&subject=${0}`;

      axios.get(url).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setAssignedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
      // }
   }

   const getRecycleBin = () => {
      setListLoading(true);
      axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/listrecyclebin`).then((response) => {
         console.log(response);

         if (response.data) {
            const data = response.data;
            setListLoading(false);
            setDeletedList(data);
         }
      }, (error) => {
         console.log(error.message);
      });
   }

   const handleTabChange = (key) => {
      switch (key) {
         case "1":
            getMasterList();
            // setActiveTab("1");
            setSearchEnabled(false);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "1");
            break;
         case "2":
            // setActiveTab("6");
            setSearchEnabled(false);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "2");
            break;
         case "3":
            getAssignedtList(1);
            // setActiveTab("2");
            setSearchEnabled(true);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "3");
            break;
         case "4":
            getAssignedtList(2);
            // setActiveTab("3");
            setSearchEnabled(true);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "4");
            break;
         case "5":
            getAssignedtList(3);
            // setActiveTab("4");
            setSearchEnabled(true);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "5");
            break;
         case "6":
            getRecycleBin();
            // setActiveTab("5");
            setSearchEnabled(true);
            HandleSetSearchKeys(searchKeys.term, searchKeys.level, searchKeys.subject, "6");
            break;

      }
   }

   const refreshList = (tab) => {
      switch (tab) {
         case "1":
            getMasterList();
            break;
         case "3":
            getAssignedtList(1);
            break;
         case "4":
            getAssignedtList(2);
            break;
         case "5":
            getAssignedtList(3);
            break;
         case "6":
            getRecycleBin();
            break;
      }
   }

   const getMasteryGuide = () => {
      if (searchKeys.term > 0 && searchKeys.level > 0 && searchKeys.subject > 0) {
         axios.get(`${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/masteryguide?term=${searchKeys.term}&level=${searchKeys.level}&subject=${searchKeys.subject}`).then((response) => {
            console.log(response);

            if (response.data) {
               const data = response.data;
               // pdfFile = data.file_url;
               setFileURL(data.file_url);
            }
         }, (error) => {
            console.log(error.message);
         });
      }
      else {
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Please select quarter, level, and subject. "All" does not apply to Assessment Guide.',
            centered: true
         });
      }
   }

   const handleSearchMasteryList = () => {
      if (searchKeys.term !== null && searchKeys.level !== null && searchKeys.subject !== null &&
         searchKeys.term !== undefined && searchKeys.level !== undefined && searchKeys.subject !== undefined) {
         setFileURL(null);

         if (searchKeys.activetab === '2')
            getMasteryGuide();
         else {
            // refreshList(searchKeys.activetab);
            // setActiveTab('1');
            setSearchKeys({
               term: searchKeys.term,
               level: searchKeys.level,
               subject: searchKeys.subject,
               activetab: '1',
            })
            refreshList('1');
         }
      }
      else {
         Modal.error({
            title: 'Mastery Assessment',
            content: 'Please select all the criteria.',
            centered: true
         });
      }
   }

   function sPDFFile() {
      pdfFile = fileURL;
      return true;
   }

   const handleInstruction = (assign_id, instruction_id, instruction_desc, instruction_docs, instruction_doc_names) => {
      selectedAssignID = assign_id;

      g_instruction_data.instruction_id = instruction_id;
      g_instruction_data.instruction_desc = instruction_desc;
      g_instruction_data.instruction_docs = instruction_docs;
      g_instruction_data.instruction_doc_names = instruction_doc_names;

      if (instruction_desc === null)
         setCreateInstructionModalVisible(true);
      else
         setEditInstructionModalVisible(true);
   }

   const handleCancelCreateInstruction = () => {
      setCreateInstructionModalVisible(false);
   }

   const handleCancelEditInstruction = () => {
      setEditInstructionModalVisible(false);
   }

   const handleOnFinishCreateInstruction = (upload_files, instruction_data) => {
      setCreating(true);

      if (upload_files.length > 0) {
         var formData = new FormData();
         var url;

         upload_files.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               instruction_data.instruction_docs = uploadedfiles;
               instruction_data.assignment_id = selectedAssignID;

               var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertinstruction`;
               axios.post(url, instruction_data).then(response => {
                  setCreating(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Mastery Assessment',
                        content: 'Preliminary Instruction created.',
                        centered: true,
                        onOk: () => { setCreateInstructionModalVisible(false) },
                     });

                     selectedAssignID = '';
                     getAssignedtList('1');
                  }
               }, (error) => {
                  selectedAssignID = '';
                  setCreating(false);
                  Modal.error({
                     title: 'Mastery Assessment',
                     content: 'Preliminary Instruction creation failed.',
                     centered: true,
                     onOk: () => { setCreateInstructionModalVisible(false) },
                  });
               });

               setCreating(false);
               setCreateInstructionModalVisible(false);

            }
         }, (error) => {
            // console.log(error.message);
            setCreateInstructionModalVisible(false);
         });
      } else {
         instruction_data.assignment_id = selectedAssignID;

         url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertinstruction`;
         axios.post(url, instruction_data).then(response => {
            setCreating(false);
            if (response.data) {
               Modal.success({
                  title: 'Mastery Assessment',
                  content: 'Preliminary Instruction created.',
                  centered: true,
                  onOk: () => { setCreateInstructionModalVisible(false) },
               });

               selectedAssignID = '';
               getAssignedtList('1');
            }
         }, (error) => {
            selectedAssignID = '';
            setCreating(false);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Preliminary Instruction creation failed.',
               centered: true,
               onOk: () => { setCreateInstructionModalVisible(false) },
            });
         });
      }
   }

   const handleOnFinishEditRecommendation = (upload_files, instruction_data) => {
      setUpdating(true);

      if (upload_files.length > 0) {
         var formData = new FormData();

         upload_files.forEach(file => {
            // the name has to be 'files' so that .NET could properly bind it (Phuck this sheet! Took me the whole day to find this sheet!)
            formData.append('files', file);
         });

         var uploadedfiles = "";
         var url = `${process.env.REACT_APP_API_S3_UPLOAD}/uploadMultiple?s3path=${process.env.REACT_APP_S3_MASTERY_ASSESSMENT_QUESTIONAIRE_PATH}`;
         axios.post(url, formData).then(response => {

            if (response.data) {
               uploadedfiles = response.data;

               // instruction_data.instruction_docs = uploadedfiles;
               instruction_data.assignment_id = selectedAssignID;

               instruction_data.instruction_docs += instruction_data.instruction_docs !== "" ? "," + uploadedfiles : uploadedfiles;

               var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertinstruction`;
               axios.post(url, instruction_data).then(response => {
                  setUpdating(false);
                  if (response.data) {
                     Modal.success({
                        title: 'Mastery Assessment',
                        content: 'Preliminary Instruction updated.',
                        centered: true,
                        onOk: () => { setEditInstructionModalVisible(false) },
                     });

                     selectedAssignID = '';

                     getAssignedtList('1');
                  }
               }, (error) => {
                  selectedAssignID = '';
                  setUpdating(false);
                  Modal.error({
                     title: 'Mastery Assessment',
                     content: 'Preliminary Instruction update failed.',
                     centered: true,
                     onOk: () => { setEditInstructionModalVisible(false) },
                  });
               });

               setUpdating(false);
               setEditInstructionModalVisible(false);

            }
         }, (error) => {
            // console.log(error.message);
            setCreateInstructionModalVisible(false);
         });
      } else {
         instruction_data.assignment_id = selectedAssignID;

         var url = `${process.env.REACT_APP_API_MASTERY_ASSESSMENT}/upsertinstruction`;
         axios.post(url, instruction_data).then(response => {
            setUpdating(false);
            if (response.data) {
               Modal.success({
                  title: 'Mastery Assessment',
                  content: 'Preliminary Instruction updated.',
                  centered: true,
                  onOk: () => { setEditInstructionModalVisible(false) },
               });

               selectedAssignID = '';

               getAssignedtList('1');
            }
         }, (error) => {
            selectedAssignID = '';
            setUpdating(false);
            Modal.error({
               title: 'Mastery Assessment',
               content: 'Preliminary Instruction update failed.',
               centered: true,
               onOk: () => { setEditInstructionModalVisible(false) },
            });
         });
      }
   }

   const HandleSetSearchKeys = (term, level, subject, activetab) => {
      var searchKeys = {
         term,
         level,
         subject,
         activetab,
      }

      localStorage.setItem("mastery-assessment-search-keys", JSON.stringify(searchKeys));

      setSearchKeys({
         term,
         level,
         subject,
         activetab,
      });
   }

   const displayPaneTable = (columns, data) => {
      return (
         <>
            {
               listLoading
                  ?
                  <div className='center-empty-space'>
                     <Spin size='large' tip="Loading, please wait..." />
                  </div>
                  :
                  (data.length > 0 && !listLoading)
                     ?
                     <Table
                        loading={listLoading}
                        size='small'
                        rowKey={'uid'}
                        columns={columns}
                        dataSource={data}
                        pagination={{ position: ['topCenter'] }}
                        scroll={{
                           x: '1000px',
                           y: 'calc(100vh - 21.5rem)',
                        }}
                        footer={() => 'Total Records: ' + data.length}
                     />
                     :
                     <div className='center-empty-space'>
                        <Empty image="../images/brainee_mascot.png" description="No records found" />
                     </div>
            }
         </>
      )
   }

   return (
      <>
         {assOptions && renderDrawer()}
         <NoticeBoardSlide />
         <div className="dashboard-area-all">
            <div className="dashboard-item">
               <div className="dashboard-item-inner">
                  {/* <ul>
                     <li><a href="#" onClick={() => navigate('/home')}>Home</a></li>
                     <li><span>/</span></li>
                     <li className="active">Brainee LMS</li>
                     <li><span>/</span></li>
                     <li className="active">Mastery Assessments</li>
                  </ul> */}
                  <Breadcrumb separator=">" style={{ paddingBottom: '15px' }}>
                     <Breadcrumb.Item>
                        <Link to="/home">Home</Link>
                     </Breadcrumb.Item>
                     <Breadcrumb.Item>Brainee LMS</Breadcrumb.Item>
                     <Breadcrumb.Item>Mastery Assessments</Breadcrumb.Item>
                  </Breadcrumb>
               </div>

               {user_id === '1' &&
                  <Row gutter={12} style={{ paddingBottom: "15px" }}>
                     <Col className="gutter-row" span={24} style={{ textAlign: "left" }}>
                        {/* <Tooltip placement="top" title='Create Mastery Assessment'> */}
                        <Button className='button-shadow' type="primary" size="large" shape='round' onClick={() => handleCreateMastery()}>
                           <Text ellipsis style={{ color: "#fff" }}><PlusOutlined />&nbsp;Create Mastery</Text>
                        </Button>
                        {/* </Tooltip> */}
                     </Col>
                  </Row>
               }

               {lookUps.SchoolTerms !== null
                  ?
                  <>
                     <Row gutter={12}>
                        <Col className="gutter-row" span={24}>
                           <div className='search-columns-4'>
                              <Select
                                 disabled={searchEnabled}
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Quarter"
                                 onChange={(val) => { HandleSetSearchKeys(val, searchKeys.level, searchKeys.subject, searchKeys.activetab); }}
                                 value={searchKeys.term}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.SchoolTerms.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>

                              <Select
                                 disabled={searchEnabled}
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Level"
                                 onChange={(val) => { HandleSetSearchKeys(searchKeys.term, val, searchKeys.subject, searchKeys.activetab); }}
                                 value={searchKeys.level}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.MasteryGradeLevels.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>

                              <Select
                                 disabled={searchEnabled}
                                 showArrow
                                 size='default'
                                 style={{ width: '100%', textAlign: "left" }}
                                 allowClear
                                 placeholder="Select Subject"
                                 onChange={(val) => { HandleSetSearchKeys(searchKeys.term, searchKeys.level, val, searchKeys.activetab); }}
                                 value={searchKeys.subject}
                              >
                                 <Option value={0}>All</Option>
                                 {lookUps.MasterySubjects.map(({ value, name }, idx) => {
                                    return <Option value={value}>{name}</Option>
                                 })}
                              </Select>

                              <Button
                                 disabled={searchEnabled}
                                 className='button-shadow'
                                 type='primary'
                                 shape='round'
                                 block
                                 onClick={() => handleSearchMasteryList()}
                                 icon={<SearchOutlined />}
                              >
                                 Search
                              </Button>
                           </div>
                        </Col>
                     </Row>

                     <div className="dashboard-item-inner3">
                        <Tabs defaultActiveKey={'1'} activeKey={searchKeys.activetab} centered onChange={(key) => handleTabChange(key)}>
                           <TabPane
                              tab={(<Tooltip title="List of all Mastery Assessments">
                                 <span>Core Standards</span>
                              </Tooltip>
                              )}
                              key="1"
                           >
                              {displayPaneTable(masterListColumns, masterList)}
                           </TabPane>

                           <TabPane
                              tab={(
                                 <Tooltip title="List of all Mastery Assessment Keys">
                                    <span><BookOutlined /> Assessment Keys</span>
                                 </Tooltip>
                              )}
                              key="2"
                           >
                              {fileURL !== null
                                 ?
                                 <div style={{ width: "100%", height: "calc(100vh - 225px)" }}>
                                    <Worker workerUrl={process.env.PUBLIC_URL + "/js/pdf.worker.min.js"}>
                                       <Viewer
                                          pageLayout={{
                                             transformSize: ({ size }) => ({
                                                height: size.height + 30,
                                                width: size.width + 30,
                                             }),
                                          }}
                                          fileUrl={`${process.env.REACT_APP_API_S3_URL}/${fileURL}`}
                                          defaultScale={SpecialZoomLevel.PageWidth}
                                          initialPage={0}
                                       />
                                    </Worker>
                                 </div>
                                 // <RenderPDFReader url={`${process.env.REACT_APP_API_S3_URL}/${fileURL}`} height={"calc(100vh - 225px)"} />
                                 // <RenderPDFViewer url={`${process.env.REACT_APP_API_S3_URL}/${fileURL}`} height={"calc(100vh - 225px)"} load={pdfFile !== fileURL ? sPDFFile() : false} showdownload={false} showprint={false} showpagecontrols={true} showfullscreen={true} />
                                 :
                                 <Empty description='To search for Mastery Assessment guide, select Term, Level, and Subject then click Search.' image="../images/brainee_mascot.png" />
                              }
                           </TabPane>

                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Current Mastery Assessments">
                                    <span><UsergroupAddOutlined /> Current</span>
                                 </Tooltip>
                              )}
                              key="3"
                           >
                              {displayPaneTable(user_role === 'Admin' ? assigned_ass_columns : teacher_assigned_ass_columns, assignedList)}
                           </TabPane>
                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Upcoming Mastery Assessments">
                                    <span><UsergroupAddOutlined /> Upcoming</span>
                                 </Tooltip>
                              )}
                              key="4"
                           >
                              {displayPaneTable(user_role === 'Admin' ? assigned_ass_columns : teacher_assigned_ass_columns, assignedList)}
                           </TabPane>
                           <TabPane
                              tab={(
                                 <Tooltip title="List of Assigned Past Mastery Assessments">
                                    <span><UsergroupAddOutlined /> Past</span>
                                 </Tooltip>
                              )}
                              key="5"
                           >
                              {displayPaneTable(user_role === 'Admin' ? assigned_ass_columns : teacher_assigned_ass_columns, assignedList)}
                           </TabPane>
                           {user_role === 'Admin' &&
                              <TabPane
                                 tab={(
                                    <Tooltip title="List of all deleted Mastery Assessments">
                                       <span><RestOutlined /> Recycle Bin</span>
                                    </Tooltip>
                                 )}
                                 key="6"
                              >
                                 {displayPaneTable(recycle_bin_columns, deleteList)}
                              </TabPane>
                           }
                        </Tabs>
                     </div>
                  </>
                  :
                  <>
                     <div className='mastery_assessment_list'>
                        <Spin size='large' tip="Loading, please wait..." />
                     </div>
                  </>
               }
            </div>
         </div>

         {/* <div className="notice-area">
            <div className="notice-item">
               <img src="../images/brainee_ma.png" width="100%" style={{ padding: "5px", maxWidth: '600px' }}></img>
               <NoticeBoard />
            </div>
         </div> */}

         {/* <Modal
            title="Select Questionaire Option"
            centered
            destroyOnClose={true}
            open={questionTypeModalVisible}
            onOk={() => handleCreateAssessment()}
            onCancel={() => handleCancelModal()}
         >
            <Radio.Group onChange={(e) => handleQuestionType(e)} value={questionType}>
               <Space direction="vertical">
                  <Radio value={1} onClick={() => setShowMasterList(false)}>
                     <Tooltip placement="right" title='Upload questionaire in pdf format'>
                        Upload {localStorage.getItem('cp_asd') !== null ? ' (Restore unfinished assessment)' : ''}
                     </Tooltip>
                  </Radio>
                  <Radio value={2} onClick={() => setShowMasterList(false)}>
                     <Tooltip placement="right" title='Enter the questions manually'>
                        Create {localStorage.getItem('cm_asd') !== null ? ' (Restore unfinished assessment)' : ''}
                     </Tooltip>
                  </Radio>
               </Space>
            </Radio.Group>
         </Modal> */}

         <Modal
            title={"Provide preliminary instruction to your students"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelCreateInstruction(); }}
            open={createInstructionModalVisible}
            okText="Send"
            cancelText="Cancel"
            // width={window.innerWidth - 400}
            width='70%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={creating} tip="Creating preliminary instruction, please wait...">
               <CreateInstructions
                  onCancelCallback={handleCancelCreateInstruction}
                  onFinishCallback={handleOnFinishCreateInstruction}
               />
            </Spin>
         </Modal>

         <Modal
            title={"Provide preliminary instruction to your students"}
            centered
            destroyOnClose={true}
            onCancel={() => { handleCancelEditInstruction(); }}
            open={editInstructionModalVisible}
            okText="Send"
            cancelText="Cancel"
            // width={window.innerWidth - 400}
            width='70%'
            // footer={null}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
         >
            <Spin spinning={updating} tip="Updating preliminary instruction, please wait...">
               <EditInstructions
                  data={g_instruction_data}
                  onCancelCallback={handleCancelEditInstruction}
                  onFinishCallback={handleOnFinishEditRecommendation}
               />
            </Spin>
         </Modal>
      </>
   );
}